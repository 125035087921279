import styled from "styled-components";

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    width: 30%;
    img {
      width: 100%;
    }
  }
  .headerCartItem {
    margin-top: 5px;
    color: #000000;
    font-family: "HankenGrotesk-Regular";
    font-size: 1.1em;
    cursor: pointer;
  }
  .menuBox {
    width: 255px;
    height: 0;
    background-color: #e2dac6;
    border-bottom-left-radius: 30pt;
    border-bottom-right-radius: 30pt;
    // padding: 0;
    border-radius: 35px;
    border-radius: 5px 5px 35px 35px;
    position: absolute;
    top: 65px;
    z-index: 10;
    transition: all 0.3s;
    .headerCartItem {
      display: none;
    }
  }
  .openMenuBox {
    min-height: 115px;
    height: auto;
    padding: 4em 1em 2em !important;
    cursor: pointer;
    padding-top: 3.5em;
    .headerCartItem {
      span {
        color: #ffffff !important;
      }
      display: block;
    }
  }
  .cartBackDrop {
    background-color: #000000;
    opacity: 0.75;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
  }
  .cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 256px;
    padding: 1.3em 1.6em;
    background-color: #000000;
    border-radius: 50px;
    color: #ffffff;
    position: relative;
    z-index: 1052;
    cursor: pointer;
    strong {
      font-size: 1.5em;
      font-family: "Helvetica-bold";
    }
    span {
      font-size: 1.5em;
      font-family: "Helvetica-light";
      color: #ffffff;
    }
  }
  @media (max-width: 768px) {
    & {
      .ant-dropdown-trigger {
        width: 180px;
        padding: 0.7em 1em;
      }
      .menuBox {
        width: 180px;
        top: 14px;
      }
      .openMenuBox {
        border-radius: 23px;
        padding: 4em 1em 2em !important;
      }
      .headerCartItem {
        font-size: 1em;
      }

      .cart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 180px;
        padding: 0.8em 1em;
        strong {
          font-size: 1.1em;
        }
        span {
          font-size: 1.1em;
        }
      }
    }
  }
  @media (max-width: 440px) {
    & {
      .ant-dropdown-trigger {
        width: 140px;
        padding: 0.7em 1em;
      }
      .menuBox {
        width: 140px;
        top: 14px;
      }
      .cart {
        width: 140px;
        strong {
          font-size: 1em;
        }
        span {
          font-size: 1em;
        }
      }
      .openMenuBox {
        padding: 3.7em 1em 2em !important;
      }
      .headerCartItem {
        font-size: 0.8em;
      }
    }
  }
`;
export const Menu = styled("div")``;
