import { Modal } from "antd";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background-color: #e2dac6;
    border-radius: 30px;
    text-align: center;
    max-width: 445px;
    margin: auto;
    .ant-modal-body {
      // img {
      //   width: 50%;
      // }
      h6,
      h5 {
        margin-bottom: 0;
        font-size: 1.7em !important;
        line-height: 1.2;
        margin: 0.3em auto;
        max-width: 280px;
        a {
          display: inline-block;
          color: #000000;
          cursor: pointer;
          margin-left: 0.5em;
          svg {
            margin-bottom: -0.2em;
          }
        }
      }
      h6 {
        font-size: 1.2em !important;
        font-family: HankenGrotesk-Regular !important;
      }
    }
  }
  .ant-modal-close {
    display: none;
  }
  @media (max-width: 600px) {
    & {
      .ant-modal-content {
        max-width: 325px;
        .ant-modal-body {
          h5 {
            font-size: 1em !important;
          }
          h6 {
            font-size: 1em !important;
          }
        }
      }
    }
  }
`;

export const CartBtn = styled("div")`
  span {
    cursor: default;
    display: block;
    margin: 1em auto;
    max-width: 280px;
    background: ${props => props?.theme?.colors?.primary};
    color: #ffffff;
    padding: 0.1em;
    border-radius: 30px;
    font-size: 1.2em;
    cursor: pointer;
    font-family: "Context regular";
  }
  @media (max-width: 600px) {
    & {
      span {
        max-width: 230px;
        font-size: 1em;
        margin: 0.5em auto;
      }
    }
  }
`;
export const AddItems = styled("div")`
  max-width: 280px;
  margin: 1em auto;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    & {
      margin: 0em auto;
      max-width: 230px;
    }
  }
`;
export const AddBtn = styled("span")`
  display: inline-block;
  margin: 1em auto;
  width: 100px;
  background: ${props => props?.theme?.colors?.primary};
  color: #ffffff;
  font-size: 1.1em;
  border-radius: 30px;
  line-height: 1.6;
  cursor: pointer;
  @media (max-width: 600px) {
    & {
      margin: 0.7em auto;
      font-size: 1em;
      width: 80px;
    }
  }
`;
export const DeleteBtn = styled("span")`
  display: inline-block;
  margin: 1em auto;
  width: 100px;
  background: #ffffff;
  color: #000000;
  font-size: 1.1em;
  border-radius: 30px;
  line-height: 1.6;
  cursor: pointer;
  @media (max-width: 600px) {
    & {
      margin: 0.7em auto;
      font-size: 1em;
      width: 80px;
    }
  }
`;
export const CountItems = styled("span")`
  margin: 0 1em;
  font-size: 2em;
  font-family: HankenGrotesk-Regular !important;
`;
export const Customize = styled("div")`
  a {
    display: block;
    font-family: "Context regular";
    margin: 1em auto;
    max-width: 280px;
    background: #ffffff;
    color: #000000;
    padding: 0.1em;
    border-radius: 30px;
    font-size: 1.2em;
  }
  @media (max-width: 600px) {
    & {
      a {
        max-width: 230px;
        margin: 0.7em auto;
        font-size: 1em;
      }
    }
  }
`;
export const Avatar = styled("div")`
  width: 73%;
  height: 200px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0 auto 1em;
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 600px) {
    & {
      width: 70%;
      height: 120px;
    }
  }
`;

export const CustomizedLink = styled(Link)`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5; // You can also adjust the appearance of a disabled link
    `}
`;
