
import React, { useEffect, useRef, useCallback } from "react";
import { SaucesContainer } from "./index.style";
import { Cancel } from "common/index.style";
import { Link } from "react-router-dom";
import { QRCode, QRCodeContainer, ScanContainer } from "screens/memberCard/index.style";
import Lottie from "react-lottie";
import checkOptionsData from "./qrcode.json";
import debounce from "lodash/debounce"; // Import debounce function from lodash

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: checkOptionsData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const MemberBarcode = ({ visible, setVisible, setScannedCode }) => {
    const scannerInputFieldRef = useRef(null);

    useEffect(() => {
        if (visible) {
            scannerInputFieldRef.current.focus();
        }
    }, [visible]);

    const handleScannedDebounced = useCallback(
        debounce((value) => {
            setScannedCode(value);
            setVisible(false);
        }, 300), // Adjust debounce delay as needed
        [setScannedCode, setVisible]
    );

    function handleScanned(event) {
        const { value } = event.target;
        handleScannedDebounced(value);
    }

    return (
        <>
            <SaucesContainer centered open={visible} footer={false} onCancel={setVisible}>
                <ScanContainer>
                    <h1>Please scan your</h1>
                    <p>Member Barcode</p>
                    <h2>For Rewards</h2>
                    <QRCode>
                        <QRCodeContainer>
                            <Link to="#">
                                <Lottie options={defaultOptions} />
                            </Link>
                        </QRCodeContainer>
                    </QRCode>
                    <input
                        ref={scannerInputFieldRef}
                        style={{ width: 0, height: 0, zIndex: -1, position: "absolute" }}
                        onChange={handleScanned}
                        type="text"
                        id="hiddenInput"
                    />
                    <Cancel to="#" onClick={setVisible}>
                        Cancel
                    </Cancel>
                </ScanContainer>
            </SaucesContainer>
        </>
    );
};

export default MemberBarcode;
