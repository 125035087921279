import {useContext, useEffect, useState} from "react";

import {v4 as uuidv4} from "uuid";
import {APPROVED, DECLINED, LOGON, PENDING, REPRINT_RECEIPT, SEND_KEY, SETTLEMENT, TXN, TXN_STATUS,} from "constants";
import useApiConfig from "./useApiConfig";
// import {
//   headers,
//   linklyEndpoint,
//   tokenRequestPath,
//   posId,
//   posName,
//   posVersion,
//   posVendorId,
//   pairingPath,
// } from "config";
import axios from "axios";
import {retrieveLinklyData, storeLinklyData,} from "screens/settings/helpers/SecretManager";
import {PosContext} from "context/posContext";
import {clearRecovery, isDifferentRequestType, not, savePurchasingStatus,} from "utils/helper";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {getlinklyStatus} from "services/apis";
import {GlobalStatesContext} from "context/GlobalStatesContext";
// import { useAdminTransection } from "context/features/admin/AdminTransectionsContext";
import {useAdminTransection} from "./admin/useAdminTransection";
import useNavigation from "./useNavigation";

const usePOS = () => {
    const {getCurrentPath} = useNavigation();
    const {
        secret,
        setSecret,
        token,
        setToken,
        tokenExpiry,
        setTokenExpiry,
        txnResponse,
        setTxnResponse,
        setTxnInProgress,
        txnInProgress,
        lastSessionId,
        setLastSessionId,
        transectionSuccessed,
        setTransectionSuccesed,
        setTransactionStarted,
        transactionStarted,
    } = useContext(PosContext);

    const {globalState, setGlobalState} = useContext(GlobalStatesContext);
    const {transections, saveTransectionStatus, setCurrentTransectionID} =
        useAdminTransection();

    const {
        headers,
        linklyEndpoint,
        tokenRequestPath,
        posId,
        posName,
        posVersion,
        posVendorId,
        pairingPath,
    } = useApiConfig();

    const [paired, setPaired] = useState(false);
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const [linklyStatus, setLinklyStatus] = useState("");

    useEffect(() => {
        localStorage.setItem("lastTxn", uuidv4(-13));
    }, []);

    useEffect(() => {
        setSecret(retrieveLinklyData("secret"));
        setToken(retrieveLinklyData("token"));
        setTokenExpiry(retrieveLinklyData("token-expiry"));
    }, []);

    const distruct = () => {
        setLinklyStatus("");
        setTransactionStarted(false);
        clearTimeout(localStorage.getItem("threeMinutTimeoutID"));
        clearTimeout(localStorage.getItem("oneMinuteTimeoutID"));
    };

    const handleStatus200 = (status, timeoutID, intervalID) => {
        /** Check is transection successed.? */
        if (status?.data?.response?.success == true) {
            // toast("Transection Successed.");
            // console.log(status, " Transection Successed.");
            clearRecovery(timeoutID, intervalID);
            setTxnResponse(status?.data?.response);
            saveTransectionStatus({
                status: APPROVED,
                txnRef: status?.data?.response?.TxnRef,
            });
            savePurchasingStatus({
                clearing: true,
                saving: false,
            });
            distruct();
            // debugger;
            if (not(transectionSuccessed)) {
                setTransectionSuccesed(true);
                // setOrderCount(incrementString(orderCount));
            }
        } else if (status?.data?.response?.success == false) {
            clearRecovery(timeoutID, intervalID);
            // toast("Transection Not Successed.");
            savePurchasingStatus({
                clearing: true,
                saving: false,
            });
            distruct();

            navigate("/order_declined");
        }
    };

    const handleStatus202 = () => {
        // toast("Transection in progress,");
        if (not(linklyStatus) && linklyStatus !== "processing") {
            setLinklyStatus("processing");
            console.log(handleStatus202(), linklyStatus)
            setProcessing(true);
        }
    };

    const handleStatus404 = (timeoutID, intervalID) => {
        console.log(404);
        // toast("404");
        /** Stop recovery mode. */
        clearRecovery(timeoutID, intervalID);
        setCurrentTransectionID(null); //
        savePurchasingStatus({
            clearing: true,
            saving: false,
        });

        /** If for this stateus code page client already tried again then not show try again */
        if (not(globalState?.[404]?.count)) {
            setTransactionStarted(false);
            distruct();
            navigate("/order_failed_error", {
                state: {tryAgain: true, statusCode: 404},
            });
        } else {
            setGlobalState((prev) => {
                return {...prev, [404]: {count: null}};
            });
            setTransactionStarted(false);
            distruct();
            navigate("/order_failed_error", {
                state: {tryAgain: false, statusCode: 404},
            });
        }
    };

    const [getAuthCount, setGetAuthCount] = useState(1);
    const handleStatus401 = (callback) => {
        if (getAuthCount === 3) {
            setGetAuthCount(0);
            navigate("/admin/pos-data");
        }

        setGetAuthCount((prev) => prev + 1);
        // toast("401");
        setProcessing(true);

        savePurchasingStatus({
            saving: false,
            clearing: true,
        });

        getToken(secret, (authToken) => {
            setProcessing(true);
            // There is problem some times 401 happen and after calling getAuth it not retuens the authtoken
            // There for same request happen again and again
            if (authToken) {
                setTimeout(() => {
                    callback(authToken);
                }, 3000);
            } else {
                distruct();
                navigate("/order_incomplete");
            }
        }); // Refresh the auth token and again send the request.
    };

    const handleStatus400 = (timeoutID, intervalID) => {
        console.log(400);
        // toast("400");
        clearRecovery(timeoutID, intervalID);
        localStorage.removeItem("currentStatus");
        savePurchasingStatus({clearing: true, saving: false});
        setTransactionStarted(false);
        setCurrentTransectionID(null);
        distruct();
        navigate("/order_failed_error", {state: {tryAgain: false}});
    };

    let threeMinutTimerID = null;
    const handleStatus408 = (intervalTime) => {
        console.log(408);
        // toast("408");
        if (not(threeMinutTimerID)) {
            threeMinutTimerID = setTimeout(() => {
                clearTimeout(threeMinutTimerID);
                threeMinutTimerID = null;
                savePurchasingStatus({
                    clearing: true,
                    saving: false,
                });
                setLinklyStatus("");
                localStorage.removeItem("currentStatus");

                setTransactionStarted(false);
                setCurrentTransectionID(null);
                distruct();

                navigate("/order_declined");
            }, 180000);
            enterRecoveryMode(lastSessionId, token, null, intervalTime);
        }
    };

    const handleState5 = () => {
        setCurrentTransectionID(null);
        savePurchasingStatus({
            saving: false,
            clearing: true,
        });

        // toast("5");
        /** If for this stateus code page client already tried again then not show try again */
        if (not(globalState?.[5]?.count)) {
            setTransactionStarted(false);
            distruct();
            navigate("/order_failed_error", {
                state: {tryAgain: true, statusCode: 5},
            });
        } else {
            setGlobalState((prev) => {
                return {...prev, [5]: {count: null}};
            });
            setTransactionStarted(false);
            distruct();
            navigate("/order_failed_error", {
                state: {tryAgain: false, statusCode: 5},
            });
        }
    };

    /** IF Message notification object not received any message for 3 minutes Then enter to recovery mode.
     * @param {String} lastSessionId; - Session ID which is using for transection.
     * @param {String} token - Authtoken
     * @param {Number} timeoutID - settimeout id which is using for handling the recover.
     * @param {Boolean} navigate - Optional IF available then navigate to the related screen.
     */
    const enterRecoveryMode = (
        lastSessionId,
        token,
        timeoutID,
        intervalTime = 5000
    ) => {
        // toast("Recovery mode start");
        let intervalID = null;
        if (lastSessionId && token) {
            intervalID = setInterval(async () => {
                try {
                    const status = await getlinklyStatus(
                        linklyEndpoint,
                        lastSessionId,
                        token
                    );
                    const statusCode = status?.status;
                    console.log(status, "Linkly Status");
                    if (statusCode == 202) {
                        handleStatus202();
                    } else if (statusCode == 200) {
                        handleStatus200(status, timeoutID, intervalID);
                    } else if (statusCode == 404 || statusCode == 400) {
                        handleStatus404(timeoutID, intervalID);
                    }
                } catch (err) {
                    const status = err?.response?.status;
                    console.log(err?.response?.status);
                    if (status == 400) {
                        handleStatus400(timeoutID, intervalID);
                    } else if (status == 408) {
                        clearRecovery(timeoutID, intervalID);
                        handleStatus408((intervalTime += 500));
                    } else if (status == 404) {
                        handleStatus404(timeoutID, intervalID);
                    } else if (status == 401) {
                        // toast("this one 401");
                        clearRecovery(timeoutID, intervalID);
                        handleStatus401((authToken) =>
                            enterRecoveryMode(lastSessionId, authToken, timeoutID)
                        );
                        // handleAuth((authToken) => {
                        //   enterRecoveryMode(lastSessionId, authToken, timeoutID);
                        // });
                    }
                }
            }, intervalTime);
        }
    };

    const refund = ({txnRef, transectionResponse}) => {
        // navigate("/instructions");
        console.log(transectionResponse);
        if (not(transectionResponse)) {
            // toast("Transection not found.");
        }
        try {
            const {
                Merchant,
                AmtPurchase = txnResponse?.amtPurchase,
                TxnRef = txnRef,
                CurrencyCode = "AUD",
                CutReceipt = "0",
                ReceiptAutoPrint = "0",
                App = "00",
            } = transectionResponse;
            const request = {
                Merchant,
                TxnType: "R",
                AmtPurchase,
                TxnRef,
                CurrencyCode,
                CutReceipt,
                ReceiptAutoPrint,
                App,
            };

            sendRequest({request, type: TXN, authToken: token});
        } catch (error) {
            console.log("error :>> ", error);
        }
    };

    const reprintReceipt = (params) => {
        try {
            const request = {
                Merchant: "99",
                ReceiptAutoPrint: "0",
                ReprintType: "2",
            };

            sendRequest({request, type: REPRINT_RECEIPT, authToken: token});
        } catch (error) {
            console.log("error :>> ", error);
        }
    };

    const logon = () => {
        const request = {
            Request: {
                Merchant: "00",
                LogonType: " ",
                Application: "00",
                ReceiptAutoPrint: "0",
                CutReceipt: "0",
            },
        };
        return sendRequest({request, type: LOGON, authToken: token});
    };

    /** Refresh the authToken */
    const getToken = (secret, callback = () => {
    }) => {

        // If there is not secret token than redirect to admin.
        if (!secret) {
            navigate('/admin/login');
            toast('Please repair the Pin pad.')
            return;
        }
        const maxCallsPerMinute = 2;
        const localStorageKey = "getTokenCallTimestamps";

        // Retrieve previous timestamps from localStorage
        const storedTimestamps =
            JSON.parse(localStorage.getItem(localStorageKey) || "[]");
        const now = Date.now();

// Filter timestamps within the last minute
        const recentCalls = storedTimestamps.filter(
            (timestamp) => now - timestamp < 60 * 1000
        );

        if (recentCalls.length >= maxCallsPerMinute) {
            // If the limit is exceeded, stop and redirect to the root page
            console.log(
                "Exceeded maximum calls per minute. Redirecting to root page."
            );
            navigate("/");
            return;
        }

        // Add the current timestamp to the array and update localStorage
        const updatedTimestamps = [...recentCalls, now];
        localStorage.setItem(localStorageKey, JSON.stringify(updatedTimestamps));

        setTransactionStarted(true);

        axios
            .post(
                tokenRequestPath,
                {
                    secret,
                    posName,
                    posVersion,
                    posId,
                    posVendorId,
                },
                headers
            )
            .then((response) => {
                const token = response?.data?.token;
                const expirySeconds = response?.data?.expirySeconds;
                if (token) {
                    setToken(token);
                    setTokenExpiry(Date.now() + expirySeconds * 1000);
                    storeLinklyData("token", token);
                    storeLinklyData("token-expiry", Date.now() + expirySeconds * 1000);
                }
                setPaired(true);

                // Update localStorage and callback
                localStorage.setItem(
                    localStorageKey,
                    JSON.stringify(updatedTimestamps)
                );

                setTransactionStarted(false);
                debugger
                callback(token);
            })
            .catch((error) => {
                // Update localStorage and handle the error
                localStorage.setItem(
                    localStorageKey,
                    JSON.stringify(updatedTimestamps)
                );
                setTransactionStarted(false);

                if (
                    error?.response?.status === 401 &&
                    !getCurrentPath().includes("/admin")
                ) {
                    setTransactionStarted(true);
                    navigate("/order_incomplete");
                }
            });
    };

    const sendPairRequest = (params) => {
        const {username, password, pairCode} = params;
        setPaired(false);
        return axios
            .post(
                pairingPath,
                {
                    username,
                    password,
                    pairCode,
                },
                headers
            )
            .then((response) => {
                console.log("SETTING SECRET");
                setSecret(response.data.secret);
                return response;
            })
            .catch((error) => {
                console.log("error :>> ", error);
                throw Error("Error sending");
            });
    };

    const purchase = ({amtPurchase = 1000, basket = {}, txnRef}) => {
        // toast("purchase");
        setProcessing(true);
        debugger
        const request = {
            Merchant: "00",
            TxnType: "P",
            AmtPurchase: amtPurchase,
            TxnRef: txnRef,
            CurrencyCode: "AUD",
            CutReceipt: "0",
            ReceiptAutoPrint: "0",
            Application: "00",
            PurchaseAnalysisData: {
                OPR: "00766|olivers",
                AMT: basket?.amt * 1000, // converting price to cents
                PCM: "0000",
            },
            Basket: {...basket},
        };
        debugger
        sendRequest({request, type: TXN, authToken: token});
        debugger
    };

    const sendKey = (key = 0, lastSessionID) => {
        debugger
        const request = {
            key: key.toString(),
        };
        return sendRequest({request, type: SEND_KEY, authToken: token, sessionID: lastSessionId});
    };

    const sendRequest = ({request, type, authToken, sessionID}) => {
        debugger
        if (type !== SEND_KEY) {
            setTransactionStarted(true);
        }

        if (request?.AmtPurchase === 0) {
            window.location = "/";
        }

        // Create instance of axios with Authorization token
        let instance = axios.create({
            headers: {'Authorization': 'Bearer ' + authToken}
        });
        let interceptorId = instance.interceptors.request.use(
            config => {
                // You can perform actions with the request here if needed
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        localStorage.setItem('interceptorId', interceptorId);

        let tempSessionId = sessionID || uuidv4();
        let requestType;

        switch (type) {
            case TXN:
                requestType = "/transaction?async=true";
                break;
            case REPRINT_RECEIPT:
                requestType = "/reprintreceipt?async=false";
                break;
            case TXN_STATUS:
                requestType = "/transaction?async=false";
                tempSessionId = (lastSessionId !== undefined ? lastSessionId : "");
                break;
            case SETTLEMENT:
                requestType = "/settlement?async=false";
                break;
            case SEND_KEY:
                requestType = "/sendkey?async=false";
                tempSessionId = (lastSessionId !== undefined ? lastSessionId : "");
                break;
            case LOGON:
                requestType = "/logon?async=false";
                break;
            default:
                break;
        }

        const uri = linklyEndpoint + tempSessionId + requestType;
        setTxnInProgress(true);

        if (type === TXN_STATUS) {
            return instance
                .get(uri)
                .then((response) => {
                    console.log("response :", response);
                    setTxnInProgress(false);
                    const selectedData = {
                        Status: response.status,
                        Message: response.statusText,
                    };
                    console.log(selectedData, " TXNRESPONSE----");
                    setTxnResponse(selectedData);
                })
                .catch((error) => {
                    console.log("ERROR", error);
                    setTxnInProgress(false);
                    setTxnResponse(error);
                });
        }

        setLastSessionId(tempSessionId);
        const isDifferentFromType = isDifferentRequestType(requestType);
        const isDifferentThanLogon = isDifferentFromType("/logon?async=false");
        const isDifferentThanSignature = isDifferentFromType("/sendkey?async=true");

        return instance
            .post(uri, {
                Request: request,
                Notification: {
                    URI: `${process.env.REACT_APP_BASE_URL}/webhooks/webhook?sessionId=${tempSessionId}&type=transaction&socketId=${globalState?.mySocketId}`,
                    AUTHORIZATIONHEADER: `Bearer ${authToken}`,
                },
            })
            .then((response) => {
                console.log("response :", response);
                setProcessing(false);
                if (isDifferentThanLogon) {
                    saveTransectionStatus({status: PENDING, sessionID: tempSessionId});
                }
                if (isDifferentThanLogon) {
                    setTxnResponse(response.data.response);
                    savePurchasingStatus({
                        saving: true,
                        clearing: false,
                        sessionID: tempSessionId,
                    });
                }
            })
            .catch((error) => {
                const status = error?.response?.status;
                setProcessing(false);
                if (type == LOGON) {
                    if (status == 401) {
                        handleStatus401((newToken) => {
                            // update axios instance header with new token
                            instance.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
                            sendRequest({request, type, authToken: newToken});
                        });
                    }
                    throw new Error("Something wrong with PINPAD.");
                }
                // Save the request status for admin section
                if (isDifferentThanLogon || isDifferentThanSignature) {
                    saveTransectionStatus({status: DECLINED, sessionID: tempSessionId});
                }
                console.log("ERROR", error);
                setTxnInProgress(false);
                setTxnResponse(null);
                setTransactionStarted(false);

                savePurchasingStatus({
                    saving: false,
                    clearing: true,
                    sessionID: tempSessionId,
                });

                if (status == 401) {
                    handleStatus401((newToken) => {
                        // update axios instance header with new token
                        instance.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
                        sendRequest({request, type, authToken: newToken});
                    });
                } else if (status == 408) {
                    handleStatus408(1000);
                } else if (status > 499) {
                    handleState5();
                }
            });
    };

    const sattlement = () => {
        const request = {
            Merchant: "00",
            SettlementType: "S",
            Application: "00",
            ReceiptAutoPrint: "0",
            CutReceipt: "0",
        };
        return sendRequest({request, type: SETTLEMENT, authToken: token});
    };

    return {
        purchase,
        sendPairRequest,
        getToken,
        refund,
        sattlement,
        reprintReceipt,
        paired,
        setPaired,
        secret,
        token,
        tokenExpiry,
        setSecret,
        lastSessionId,
        enterRecoveryMode,
        linklyStatus,
        setLinklyStatus,
        processing,
        setProcessing,
        sendKey,
        logon,
        transactionStarted,
        setTransactionStarted,
    };
};
export default usePOS;
