import React, { createContext, useState } from "react";

export const AdminParingContext = createContext(null);

export const AdminParingContextProvider = ({ children }) => {
  const [shift8, setShift8] = useState({});
  const [paring, setParing] = useState({});

  return (
    <AdminParingContext.Provider value={null}>
      {children}
    </AdminParingContext.Provider>
  );
};
