import {useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import RenderAnimation from "../../components/animation/RenderAnimation";
import {Heading} from "../QrScanner/index.style";
import Logo from "../payOrder/Logo";
import {Wrapper} from "../payOrder/index.style";
import {TxtWraper} from "./index.style";

import {Cancel} from "common/index.style";
import paymentAnimation from "../../assets/animation/payment_enimation.json";

import {selectGetCartItems} from "Redux/cartSlice";
import {PosContext} from "context/posContext";
import usePOS from "hooks/usePOS";
import {useSelector} from "react-redux";

import {
    applyFixed,
    clearRecovery,
    getLinklyResStatus,
    handleStatus,
    isPinpadOnline,
    isTransectionCompleted,
    isTransectionUnderProcess,
    not,
    savePurchasingStatus,
} from "utils/helper";

import axios from "axios";
import {generateBaskit} from "screens/settings/helpers";
import {generateCartData, getServiceChargePercentage} from "screens/yourOrder/helper";
import socket from "services/socketServices";

import {LINKLY_RES_STATUS} from "constants/LINKLY_RESPONSES";
import Signature from "../signature";
import Waiting from "./waiting";
// import { useAdminTransection } from "context/features/admin/AdminTransectionsContext";
import {APPROVED, DECLINED, REFUND} from "constants";
import {GlobalStatesContext} from "context/GlobalStatesContext";
import {useAdminTransection} from "hooks/admin/useAdminTransection";
import {Overlay} from "styles/brandingStyles";
import useClearApp from "../../hooks/useClearApp";
import {LoadedContext} from "../../context/LoadedContext";
import {isExpo} from "../../config";
import useData from "../../hooks/useData";
import moment from "moment/moment";

const Instructions = () => {
    var timeoutID = null;
    const THREE_MINUTS = 180000;
    const {
        SWIPE_CARD,
        PRESENT,
        PROCESSING,
        PINPAD_BUSY,
        PRESS_OKAY_TO_CONTINIE,
        TRANSECTION_DECLINED,
        PINPAD_OFFLINE,
        SIGNATURE,
    } = LINKLY_RES_STATUS;
    const {clearWholeApp} = useClearApp();
    const {cartData} = useSelector((state) => state?.cart);
    const [startSignature, setStartSignature] = useState(false);

    const {setTxnResponse, transectionSuccessed, setTransectionSuccesed} = useContext(PosContext);
    const {saveTransectionStatus, currentTransectionID, updateRefundingStatus} = useAdminTransection();
    const {
        purchase, getToken, enterRecoveryMode, linklyStatus, setLinklyStatus, processing, setProcessing, sendKey,
    } = usePOS();
    const navigate = useNavigate();

    const {
        txnInProgress,
        txnResponse,
        setShift8Response,
        lastSessionId,
        setLastSessionId,
        token,
        secret,
        transactionStarted,
        setTransactionStarted,
    } = useContext(PosContext);
    const lastSessionIdRef = useRef();
    const {globalState} = useContext(GlobalStatesContext);
    const txnRef = uuidv4().slice(-16);

    const items = useSelector(selectGetCartItems);
    const {setLanding} = useContext(LoadedContext);
    useEffect(() => {
        lastSessionIdRef.current = lastSessionId;
    }, [lastSessionId]);

    useEffect(() => {
        if (isTransectionUnderProcess() && token) {
            const sessionID = lastSessionId || localStorage.getItem("sessionID");
            const currentStatus = linklyStatus || localStorage.getItem("currentStatus");

            setTransactionStarted(true);
            setLastSessionId(sessionID);
            enterRecoveryMode(sessionID, token, timeoutID);
            setLinklyStatus(currentStatus);
            localStorage.setItem("currentStatus", currentStatus);
        }
    }, [lastSessionIdRef, token, timeoutID]);

    const pushToShift8 = async (data, name, txn) => {

        const interceptorId = localStorage.getItem("interceptorId");
        axios.interceptors.request.eject(interceptorId);
        const posData = localStorage.getItem("posData") || null;

        let parsedPosData = {};

        try {
            parsedPosData = JSON.parse(posData);
        } catch (error) {
            console.error("Parsing error:", error);
        }

        let config = {
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/shift8/order`, // url: "https://prj-olivers-backend.vercel.app/api/shift8/order",
            // url: "https://sodaclickshift8.IbrahimJan.repl.co/shift8/order",
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify([{
                locationNumber: parsedPosData?.shift8Id1 || 0,
                LoyaltyCardNumber: localStorage.getItem('scannedCode'),
                firstName: name,
                txnRef: txn,
                timeStamp: new Date().toISOString(),
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                surchargePercent: getServiceChargePercentage() / 100,
            }, ...data,]),
        };

        //   method: "post",
        //   maxBodyLength: Infinity,
        //   // url: "https://sodaclickshift8.IbrahimJan.repl.co/shift8/order",
        //   url: "https://prj-olivers-backend.vercel.app/api/shift8/order",
        //   // headers: {
        //   //   "Content-Type": "application/json",
        //   //   Authorization: "Bearer 66fabda93418a5bc218eb26c0e0f1a77",
        //   // },
        //   data: JSON.stringify([
        //     {
        //       firstName: name,
        //       txnRef: txn,
        //     },
        //     ...data,
        //   ]),
        // };
        return axios
            .request(config)
            .then((response) => {
                setShift8Response(response?.data?.RESPONSE?.shift8_data);

                clearRecovery(timeoutID, null);
                savePurchasingStatus({
                    saving: false, clearing: true,
                });
                setLinklyStatus("");
                setTransactionStarted(false);
                navigate("/order_complete");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (txnResponse?.Success && transectionSuccessed) {

            pushToShift8(generateCartData(items), localStorage.getItem("username"), txnResponse?.txnRef || txnResponse?.TxnRef).then((res) => {
                clearTimeout(timeoutID);
                setTransectionSuccesed(null); // Reset the state
                savePurchasingStatus({
                    saving: false, clearing: true,
                });
                setLinklyStatus("");
                setTransactionStarted(false);
                clearRecovery(timeoutID);
                navigate("/order_complete");
            });
        }
    }, [txnResponse, transectionSuccessed]);

    // const [linklyStatus, setLinklyStatus] = useState("");

    const linklyNotificationListener = (data) => {
        clearRecovery(timeoutID, null);
        if (isTransectionCompleted(data)) {
            setTxnResponse(data?.Response);
            if (data?.Response?.TxnType == "R") {
                saveTransectionStatus({
                    status: REFUND,
                    txnRef: data?.Response?.TxnRef,
                    sessionID: data?.SessionId,
                    isRefund: true, ...data?.Response,
                });

                updateRefundingStatus(currentTransectionID || localStorage.getItem("currentRefundingSessionID"));
            } else {
                saveTransectionStatus({
                    status: APPROVED,
                    txnRef: data?.Response?.TxnRef,
                    sessionID: data?.SessionId,
                    isRefund: true, ...data?.Response,
                });
                console.log('else end', {
                    status: APPROVED,
                    txnRef: data?.Response?.TxnRef,
                    sessionID: data?.SessionId,
                    isRefund: true, ...data?.Response,
                })
            }

            if (not(transectionSuccessed)) {
                setTransectionSuccesed(true);
                // setOrderCount(incrementString(orderCount));
            }
            clearTimeout(timeoutID);
        }
        clearTimeout(timeoutID); // clear the previous recovery.
        console.log("Received Linkly notification update:", data);
        if (isPinpadOnline(data)) {
            console.log('if (isPinpadOnline(data)) {', isPinpadOnline(data))
            clearRecovery(timeoutID); // clear the previous recovery
            if (!localStorage.getItem("oneMinuteTimeoutID")) {
                clearTimeout(localStorage.getItem("threeMinutTimeoutID"));
                localStorage.removeItem("threeMinutTimeoutID");
                localStorage.removeItem("oneMinuteTimeoutID");

                startRecoveryAfter(60000, "oneMinuteTimeoutID"); // start recovery after if notification not found
            }
            const message = getLinklyResStatus(data);
            console.log("const message = getLinklyResStatus(data);", message);
            setLinklyStatus(message);
            localStorage.setItem("currentStatus", message);

            handleStatus({status: message, timeoutID, navigate});
        } else {
            console.log("last else");
            clearRecovery(timeoutID);
            setLinklyStatus(PINPAD_OFFLINE);
            localStorage.removeItem("currentStatus");
            enterRecoveryMode(lastSessionIdRef.current, token, timeoutID); // Move to the recovery to check if the request successed or not.
        }
    };

    useEffect(() => {
        if (linklyStatus == SIGNATURE) {
            localStorage.setItem("currentStatus", SIGNATURE);
            setStartSignature(true);
        }
    }, [linklyStatus]);

    useEffect(() => {
        socket.addEventListener("linklyNotification", linklyNotificationListener);

        return () => {
            socket.removeListener("linklyNotification");
        };
    }, []);

    // Start 3 minut timer after trasection starts.
    const startRecoveryAfter = (miliSeconds, timeoutIDName) => {
        // toast(`Start recovery After ${miliSeconds}`);
        clearRecovery(timeoutID);
        const id = setTimeout(() => {
            enterRecoveryMode(lastSessionIdRef.current, token, timeoutID);
            // getToken(secret);
        }, miliSeconds);
        timeoutID = id;
        localStorage.setItem(timeoutIDName, id);
    };

    useEffect(() => {
        if (transactionStarted) {
            clearRecovery(timeoutID);
            clearTimeout(localStorage.getItem("threeMinutTimeoutID"));
            startRecoveryAfter(30000, "threeMinutTimeoutID"); // Start recovery mode after transection starts
        }
        return () => {
            clearTimeout(timeoutID);
            clearTimeout(localStorage.getItem("threeMinutTimeoutID"));
        };
    }, [transactionStarted]);

    useEffect(() => {
        if (linklyStatus && not(transectionSuccessed) && linklyStatus !== PRESS_OKAY_TO_CONTINIE && linklyStatus !== PRESENT && linklyStatus !== SWIPE_CARD) {
            console.log("Set Processing true in useEffect instructions");
            setProcessing(true);
        }
    }, [linklyStatus, transectionSuccessed]);

    const handleCancelRequest = (e) => {
        e.preventDefault();

        const oneMinutTimeoutID = localStorage.getItem('oneMinuteTimeoutID');
        const threeMinutTimeoutID = localStorage.getItem('threeMinutTimeoutID');

        // Extract a new function to handle cleaning operations
        const clearAndReset = () => {
            clearWholeApp({goBack: false});
            savePurchasingStatus({saving: false, clearing: true});
            setLinklyStatus("");
            localStorage.removeItem("currentStatus");
            clearRecovery(oneMinutTimeoutID, '');
            clearRecovery(threeMinutTimeoutID, '');
            setTransactionStarted(false);
            saveTransectionStatus({status: DECLINED, sessionID: lastSessionId});
            setLanding(true);
        };
        try {
            sendKey(0, lastSessionIdRef.current).then(() => {
                clearAndReset();
            }).catch((err) => {
                console.error('Request cannot be canceled: ', err); // use console.error to log errors
                clearAndReset();
                navigate('/');
            });

        } catch (err) {
            console.error('Request cannot be canceled: ', err);
            clearAndReset();
            navigate('/');
        }

    };

    const {formatedMenuData, menuData} = useData();

    // If current mode is expo than bypass the linkly, and directly pushed to shift-8 and generate the Receipt.
    useEffect(() => {
        if (isExpo) {
            setTimeout(() => {
                pushToShift8(generateCartData(items), localStorage.getItem("username"), uuidv4() || txnResponse?.txnRef || txnResponse?.TxnRef).then((res) => {
                    navigate("/order_complete");
                });
            }, 4000);

        }
    }, []);

    useEffect(() => {
        console.log(processing, 'processing....')
    }, [processing]);

    return (<div id="instructions">
        {
            isExpo && <Waiting/> || <>{transactionStarted && <Overlay/>}
                {/* <Link to="/admin/transactions">Admin Transection</Link>  */}
                {(startSignature && (<Signature setStartSignature={setStartSignature}/>)) || (<Wrapper>
                    <Logo/>
                    <Heading>Payment</Heading>

                    {processing ? (<Waiting route="/order_complete"/>) : (<TxtWraper>
                        <div style={{
                            fontFamily: 'Helvetica'
                        }}>
                            <p style={{
                                fontSize: 60,
                                fontWeight: 700,
                                letterSpacing: '0em',
                                marginBottom: 0
                            }}>Please Pay & Wait</p>
                            <p style={{
                                lineHeight: .62,
                                fontWeight: 300,
                                fontSize: 105,

                            }}>for Receipt</p>
                        </div>

                        <div className="payment-cards">
                            <Link
                                to="#"
                                onClick={() => {
                                    purchase({
                                        amtPurchase: Number(applyFixed(cartData?.grandTotal * 100, 0)
                                            // applyFixed(1000, 0)
                                        ), basket: generateBaskit(cartData), txnRef,
                                    });
                                }}
                            >
                                <RenderAnimation animation={paymentAnimation}/>
                            </Link>
                        </div>

                        {
                            linklyStatus === SWIPE_CARD && <Cancel
                                id="cancelBtnTransaction"
                                style={{position: "relative", zIndex: 9991}}
                                to="#"
                                onClick={handleCancelRequest}
                            >
                                Cancel
                            </Cancel>
                        }

                    </TxtWraper>)}
                </Wrapper>)}
            </>
        }
    </div>);
};

export default Instructions;
