import React, {useState} from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";

const format = 'h:mm a';

const MyDateRangePicker = () => {
    const initialStartTime = '09:00';  // replace the string with your initial startTime if it's different
    const initialEndTime = '18:00';  // replace the string with your initial endTime if it's different

    const [startTime, setStartTime] = useState(() => {
        const timeString = localStorage.getItem('startTime');
        return timeString ? moment(timeString, 'HH:mm') : moment(initialStartTime, 'HH:mm');
    });

    const [endTime, setEndTime] = useState(() => {
        const timeString = localStorage.getItem('endTime');
        return timeString ? moment(timeString, 'HH:mm') : moment(initialEndTime, 'HH:mm');
    });

    const handleStartTimeChange = (value) => {
        setStartTime(value);
        const startTimeStr = value.format('HH:mm');
        localStorage.setItem('startTime', startTimeStr);
    };

    const handleEndTimeChange = (value) => {
        setEndTime(value);
        const endTimeStr = value.format('HH:mm');

        localStorage.setItem('endTime', endTimeStr);
    };

    return (
        <div style={{display: 'flex', columnGap: 10, width: '100%'}}>
            <div style={{width: '30%'}}>
                <p style={{color: '#fff', fontSize: 'x-large'}}>Start Time</p>
                <TimePicker
                    style={{width: '100%'}}
                    showSecond={false}
                    value={startTime}
                    className="xxx"
                    format={format}
                    use12Hours
                    inputReadOnly
                    onChange={handleStartTimeChange}
                />
            </div>
            <div style={{width: '30%'}}>
                <p style={{color: '#fff', fontSize: 'x-large'}}>End Time</p>
                <TimePicker
                    style={{width: '100%'}}
                    fontSize={10}
                    showSecond={false}
                    value={endTime}
                    className="xxx"
                    format={format}
                    use12Hours
                    inputReadOnly
                    onChange={handleEndTimeChange}
                />
            </div>
        </div>
    );
}

export default MyDateRangePicker;