import React, {useContext, useState} from "react";
import {ModalContent, SaucesContainer} from "./index.style";
import {Col, Row} from "antd";
import {BackBtn} from "common/index.style";
import {useNavigate} from "react-router-dom";
import useClearApp from "hooks/useClearApp";
import {LoadedContext} from "context/LoadedContext";

const CancelOrderModal = ({visible, setVisible, handleCancelOrder}) => {
    const {clearWholeApp} = useClearApp();
    const {setLanding} = useContext(LoadedContext);
    return (
        <>
            <SaucesContainer
                // title="Basic Modal"
                centered
                open={visible}
                footer={false}
                onCancel={() => setVisible(false)}
            >
                <ModalContent>
                    <h1>Are you sure you want to cancel</h1>
                    <Row justify="start" gutter={10}>
                        <Col span={12}>
                            <BackBtn onClick={() => setVisible(false)}>Don't Cancel</BackBtn>
                        </Col>
                        <Col span={12}>
                            <BackBtn
                                id="cancelBtn"
                                to="/"
                                style={{background: "red"}}
                                onClick={() => {
                                    setLanding(true);

                                    handleCancelOrder();
                                }}
                            >
                                Cancel
                            </BackBtn>
                        </Col>
                    </Row>
                </ModalContent>
            </SaucesContainer>
        </>
    );
};

export default CancelOrderModal;
