import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {BarcodeScanner} from "../../../screens/payOrder/Zxing";
import useData from "../../../hooks/useData";
import {findScannedProdutFromProducts} from "../helper";
import {addToCart, setOpenCart} from "../../../Redux/cartSlice";
import {renderSizes} from "../../../dataService/dataService";
import {not} from "../../modals/helper";
import debounce from "lodash/debounce";

export default function ScanProduct() {
    const dispatch = useDispatch();
    const [isScanning, setIsScanning] = useState(true);
    const [scannedCode, setScannedCode] = useState("");
    const scannerInputFieldRef = useRef(null);

    const {
        menuData, catagories, selectedCatagory, formatedMenuData: formattedMenuData, popularData, productsArr,
    } = useData();
    let selectedExtrasObject = {}; // Initialize the object to store selected extras

    // After scanned turned off turn on after scan an item,
    useEffect(() => {
        if (!isScanning) {
            setScannedCode("");
            setIsScanning(true);
        }
    }, [isScanning]);


    useEffect(() => {
        const handleDocumentClick = () => {
            // check if the current property exists and is a focus method
            scannerInputFieldRef.current?.focus();
        };

        // Adds the click event listener when the component is mounted
        document.addEventListener('click', handleDocumentClick)

        // Removes the event listener when the component is unmounted
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        };
    }, []); // The empty bracket makes it only run on mount and unmount


    useEffect(() => {
        scannerInputFieldRef.current?.focus();
    }, [isScanning, scannedCode, setScannedCode, menuData, popularData, formattedMenuData, selectedCatagory]);


    useEffect(() => {
        console.log(scannedCode, 'scanned Code');
        const item = findScannedProdutFromProducts(scannedCode, menuData)[0];

        if (item) {
            console.log(item, ' scannedItem')
            console.log(menuData, 'menuData');


            const formattedItem = {
                ...item, ...item?.itemSize[0], quantity: 1, editing: false,
            }

            const {
                sizes = [], // Array of available sizes
                defaultSize = {}, // Default size object
                containsSizes, // Boolean indicating if sizes are available
            } = renderSizes(formattedItem);


            // Determine if a size is selected based on selectedProduct and selectedExtras
            const isSizeSelected = formattedItem?.sizeSelected

            const defaultItemCost = isSizeSelected ? formattedItem?.cost : defaultSize?.cost;

            const defaultItemSize = isSizeSelected ? formattedItem?.size : "small";


            let selectedExtrasObject = {}; // Initialize the object to store selected extras

            // Function to get extra details using a default ID
            const getExtraViaDefaultId = (productPlu, extra) => {
                const arr = productArray(extra);
                return (arr.find((el) => {
                    return el?.productPlu == productPlu;
                }) || {
                    displayItemName: "",
                    imageSrc: "",
                    itemCost: "0",
                    modifiers_extras: null,
                    posItemName: "",
                    productCode: "",
                    productPlu: "",
                    size: "NA",
                });
            };

            // Function to handle selecting extras for a section
            const handleSelectExtras = (section, extra) => {
                const displayItemName = extra?.displayItemName;
                const itemCost = extra?.itemCost;

                if (!selectedExtrasObject[section]) {
                    selectedExtrasObject[section] = {};
                }

                if (displayItemName) {
                    selectedExtrasObject[section][displayItemName] = {
                        displayItemName, itemCost,
                    };
                }
                // Use selectedExtrasObject directly in your logic instead of React state
            };

            // Function to get default minimum and maximum values for a section
            const getDefaultMinMax = (section, extra) => {
                if (extra?.default) {
                    const defaultExtra = getExtraViaDefaultId(extra?.default, extra);
                    not(Object.keys(selectedExtrasObject?.[section] || {}).length) && handleSelectExtras(section, defaultExtra);
                }
            };

            const modifiers = defaultSize?.modifiers;

            modifiers?.forEach((modifier) => {
                Object.keys(modifier)?.forEach((extras) => {
                    handleSelectExtras(extras, modifier[extras]);
                    getDefaultMinMax(extras, modifier[extras]);
                });
            });

            // Function to filter out properties with non-empty objects
            const filterNonEmptyObjects = (obj) => {
                const filteredObj = {};
                Object.keys(obj).forEach((key) => {
                    if (Object.keys(obj[key]).length > 0) {
                        filteredObj[key] = obj[key];
                    }
                });
                return filteredObj;
            };

            // Example usage after populating selectedExtrasObject
            const filteredExtrasObject = filterNonEmptyObjects(selectedExtrasObject);

            console.log(filteredExtrasObject, "filteredExtrasObject");

            const selectedProduct = {
                ...formattedItem,
                size: "",
                price: Number(defaultItemCost),
                cost: Number(defaultItemCost),
                itemCost: Number(defaultItemCost),
                actualCost: formattedItem?.actualCost || defaultItemCost,
                quantity: formattedItem?.quantity || 1,
                total: Number(defaultItemCost) * Number(formattedItem?.quantity || 1),

                items: {...filteredExtrasObject}
            };

            if (Object.keys(item)?.length && menuData?.length) {
                console.log(selectedProduct, ' scannedSelectedProduct')
                pushDataToCart(selectedProduct);
            }
            setScannedCode("");
        }
        scannerInputFieldRef.current.value = "";
        scannerInputFieldRef.current.focus();
    }, [scannedCode, popularData, formattedMenuData, menuData]);

    const pushDataToCart = (data) => {

        dispatch(setOpenCart(true));
        dispatch(addToCart({...data}));

        setTimeout(() => {
            dispatch(setOpenCart(false));
            scannerInputFieldRef.current.value = "";
            scannerInputFieldRef.current?.focus();
        }, 2000);
    }

    const productArray = (inputObject) => {
        return Object.keys(inputObject)
            .filter((key) => !isNaN(key)) // Filter out non-numeric keys
            .map((key) => inputObject[key]); // Map to the corresponding product objects
    };


    const handleScannedDebounced = useCallback(
        debounce((value) => {
            setScannedCode(value);
            scannerInputFieldRef.current?.focus();
        }, 300), // Adjust debounce delay as needed
        [setScannedCode]
    );

    function handleScanned(event) {
        const {value} = event.target;
        handleScannedDebounced(value);
    }

    return (<>
        {
            isScanning && (
                <BarcodeScanner
                    isScaning={isScanning}
                    setIsScaning={setIsScanning}
                    setScannedCode={setScannedCode}
                    popup={false}
                />
            )
        }

        <input
            autoComplete="off"
            aria-autocomplete="off"
            ref={scannerInputFieldRef}
            style={{zIndex: -1, position: "absolute"}}
            onChange={handleScanned}
            type="text"
            id="hiddenInput"
        />

    </>)
}