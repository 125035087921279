import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const {
  FormContainer,
  Form,
  WhiteText,
  BackButton,
  YesButton,
  LogoutModal,
} = require("./index.style");

const Logout = ({ open, handleSignout }) => {
  return (
    <LogoutModal
      open={open}
      footer={false}
      closeIcon={false}
      onCancel={() => handleSignout(false)}
    >
      <FormContainer>
        <div></div>
        <Form style={{ position: "relative" }}>
          <WhiteText>Are You Sure You Want To Sign Out</WhiteText>
          <div
            style={{
              display: "flex",
              width: "100%",
              textAlign: "center",
              justifyContent: "space-around",
            }}
          >
            <BackButton onClick={() => handleSignout(false)}>Back</BackButton>
            <YesButton onClick={() => handleSignout(true)}>Yes</YesButton>
          </div>
        </Form>
      </FormContainer>
    </LogoutModal>
  );
};
export default Logout;
