import React, { useState } from "react";
import { ItemDetailsCard } from "./index.style";
import { CustomizedLink } from "../../components/modals/index.style";
import { ImagePlaceholder } from "../../components/cards/ImagePlaceholder";
import { not } from "../../components/modals/helper";
import { getPrice } from "./helpers";

const Product = ({ item, handleOpenDetailCard, index, parentId }) => {
  // debugger;
  const [loaded, setLoaded] = useState(false);
  return (
    <div key={index}>
      {(loaded && (
        <ItemDetailsCard
          key={index}
          onClick={() => {
            // console.log(parentId, index, item);
            // debugger
            handleOpenDetailCard(item);
          }}
        >
          <CustomizedLink
            to={`/customize_order/${item?.category}/${item?.productCode}`}
          >
            <div>
              <img
                loading="lazy"
                draggable={false}
                src={item.imageSrc}
                alt=""
              />
            </div>
            <h5>{item.itemName}</h5>
            <p>${getPrice(item)}</p>
            <p>{loaded}</p>
          </CustomizedLink>
        </ItemDetailsCard>
      )) || <ImagePlaceholder />}
      {not(loaded) && (
        <img
          style={{ display: "none" }}
          ref={(input) => {
            if (!input) {
              return;
            }
            const img = input;

            const updateFunc = () => {
              setLoaded(true);
            };
            img.onload = updateFunc;
            if (img.complete) {
              updateFunc();
            }
          }}
          src={item?.imageSrc}
        />
      )}
    </div>
  );
};

export default Product;
