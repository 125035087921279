// Configrations
// export const pairingPath =
//   "https://auth.sandbox.cloud.pceftpos.com/v1/pairing/cloudpos";
// export const tokenRequestPath =
//   "https://auth.sandbox.cloud.pceftpos.com/v1/tokens/cloudpos";
// export const linklyEndpoint =
//   "https://rest.pos.sandbox.cloud.pceftpos.com/v1/sessions/";

// Read the query parameter from the URL
const queryParams = new URLSearchParams(window.location.search);
const isDev = queryParams.get("dev") === "true";
const isExpo = queryParams.get('demo') === "true"

// Base URLs for both development and production
const baseDevUrl = "https://auth.sandbox.cloud.pceftpos.com";
const baseProdUrl = "https://auth.cloud.pceftpos.com";

// Conditionally append the sandbox part based on the environment
const pairingPath = isDev
    ? `${baseDevUrl}/v1/pairing/cloudpos`
    : `${baseProdUrl}/v1/pairing/cloudpos`;

const tokenRequestPath = isDev
    ? `${baseDevUrl}/v1/tokens/cloudpos`
    : `${baseProdUrl}/v1/tokens/cloudpos`;

const linklyEndpoint = isDev
    ? `${baseDevUrl}/v1/sessions/`
    : `${baseProdUrl}/v1/sessions/`;

export {pairingPath, tokenRequestPath, linklyEndpoint, isExpo};

export const posName = "Oliver's POS";
export const posVersion = "1.0.0";
export const posId = "f818d27b-c98c-4007-97f5-6eb173bb7d9b";
export const posVendorId = "482c12c7-4506-482e-a05a-761c113c9a40";
export const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};
