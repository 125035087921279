import { Modal } from "antd";
import styled from "styled-components";
// export const Wrapper = styled('div')`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 4px;
  padding-left: 7px;
`;

export const Title = styled.div`
  padding-left: 1rem;

  p {
    color: #fff;
    font-size: 2.5em;
    font-weight: bold;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WhiteText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
`;

export const Form = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

export const InputLabel = styled.p`
  color: white;
  font-size: 1.7em;
  font-weight: 600;
`;

export const InputField = styled.input`
  background-color: #fff;
  padding-left: 12px;
  border-radius: 50px;
  font-size: 2em;
  height: 80px;
  width: 100%;
  outline: none;
  margin-bottom: 2em;
`;

export const SubmitButtonContainer = styled.div`
  /* width: 100%; */
  background-color: #3ca3dc;
  overflow: hidden;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3px;
  height: 100px;
  margin-top: 25px;
  gap: 3px;
`;

export const BackButton = styled.button`
  width: 400px;
  height: 70px;
  border-radius: 40px;
  border: none;
  background-color: #fff !important;
  color: #000 !important;
  font-size: 2rem;
`;

export const YesButton = styled.button`
  color: white;
  background-color: #3ca3dc;
  width: 400px;
  height: 70px;
  border-radius: 40px;
  border: none;
  font-size: 2rem;
`;

export const LogoutModal = styled(Modal)`
  margin-top: 50%;
  width: 80% !important;
  .ant-modal-content {
    background-color: #000;
    box-shadow: none;
    border-radius: 30px;
    text-align: center;
    font-weight: 700;
    .ant-modal-body {
      h1 {
        font-weight: 700;
        margin-bottom: 1.5em;
        font-size: 2em !important;
        color: #000000;
      }
    }
  }
  .ant-modal-close {
    display: none;
  }
`;
