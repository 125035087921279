import {useContext} from "react";
import {useDispatch} from "react-redux";
import {clearCart} from "Redux/cartSlice";
import {clearSelectedProducts} from "Redux/productSlice";
import useData from "./useData";
import {GlobalStatesContext} from "context/GlobalStatesContext";

const useClearApp = () => {
    const {setGlobalState, setEnableCountdown} = useContext(GlobalStatesContext);
    const dispatch = useDispatch();
    const {setActive} = useData();
    const clearWholeApp = ({goBack = false}) => {

        // Remove timeout pop registered Timeout.
        const timeoutID = localStorage.getItem('timeoutID');
        clearTimeout(Number(timeoutID));
        setEnableCountdown(false);

        if (!goBack) {
            dispatch(clearCart());
            setActive(0);
            dispatch(clearSelectedProducts());
        }
    };
    return {clearWholeApp};
};

export default useClearApp;
