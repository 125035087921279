import React, { createContext, useState } from "react";

export const GlobalStatesContext = createContext(null);

const GlobalStatesContextProvider = ({ children }) => {
  const [enableCountdown, setEnableCountdown] = useState(false);
  const [orderCount, setOrderCount] = useState("000");
  const [globalState, setGlobalState] = useState({});
  return (
    <GlobalStatesContext.Provider
      value={{
        enableCountdown,
        setEnableCountdown,
        orderCount,
        setOrderCount,
        globalState,
        setGlobalState,
      }}
    >
      {children}
    </GlobalStatesContext.Provider>
  );
};

export default GlobalStatesContextProvider;
