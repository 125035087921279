import styled from "styled-components";
// export const Wrapper = styled('div')`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9991;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 4px;
  padding-left: 7px;
`;

export const Title = styled.div`
  padding-left: 1rem;

  p {
    color: #fff;
    font-size: 2.5em;
    font-weight: bold;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 8px;
  padding-top: 50%;
`;

export const BlueText = styled.p`
  color: #3ca3dc;
  font-size: 2.5em;
  font-weight: bold;
`;

export const Form = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

export const InputLabel = styled.p`
  color: white;
  font-size: 1.7em;
  font-weight: 600;
`;

export const InputField = styled.input`
  background-color: #fff;
  padding-left: 12px;
  border-radius: 50px;
  font-size: 2em;
  height: 80px;
  width: 100%;
  outline: none;
  margin-bottom: 2em;
`;

export const SubmitButtonContainer = styled.div`
  /* width: 100%; */
  background-color: #3ca3dc;
  overflow: hidden;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3px;
  height: 100px;
  margin-top: 25px;
  gap: 3px;
`;

export const SubmitButton = styled.button`
  color: white;
  background-color: #3ca3dc;
  outline: none;
  border: none;
  width: 100%;
  height: 100px;
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
