import React, { useState, useEffect, useContext } from "react";
import { ModalContent, SaucesContainer } from "./index.style";
import { Col, Row } from "antd";
import { BackBtn } from "common/index.style";
import { useNavigate } from "react-router-dom";
import useClearApp from "hooks/useClearApp";
import { LoadedContext } from "context/LoadedContext";
import { GlobalStatesContext } from "context/GlobalStatesContext";

function formatTime(time) {
  const minutes = Math.floor(time / (60 * 1000))
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((time % (60 * 1000)) / 1000)
    .toString()
    .padStart(2, "0");
  const milliseconds = (time % 1000).toString().padStart(2, "0");
  return `00:${seconds}`;
}

const LoginModal = ({ visible, setVisible }) => {
  const [showTimer, setShowTimer] = useState(false);
  const { setOrderCount } = useContext(GlobalStatesContext);
  const { setLanding } = useContext(LoadedContext);
  const { clearWholeApp } = useClearApp();
  const initialTime = 10 * 1000;
  const onTimeout = handleTimeout;
  const navigate = useNavigate();
  const [time, setTime] = useState(initialTime);
  const [formattedTime, setFormattedTime] = useState(formatTime(initialTime));

  useEffect(() => {
    if (time > 0) {
      const intervalId = setInterval(() => {
        setTime((prevTime) => prevTime - 10);
      }, 10);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      // Time has reached 0, notify the parent component
      setLanding(true);
      navigate("/");
      clearWholeApp({goBack: false});
      setOrderCount("000");
      setVisible(false);
      onTimeout();
    }
  }, [time, onTimeout]);

  useEffect(() => {
    setFormattedTime(formatTime(time));
  }, [time]);

  // Function to reset the timer
  const resetTimer = () => {
    setTime(initialTime);
    setFormattedTime(formatTime(initialTime));
  };

  function handleTimeout() {
    setShowTimer(false);
  }

  const handleModalCancel = (e) => {
    setShowTimer(false);
    setVisible(false);
    // Reset the timer when the modal is closed
    resetTimer();
  };

  useEffect(() => {
    // When the modal is opened, start the timer
    if (visible) {
      setShowTimer(true);
    } else {
      // When the modal is closed, clear the timer
      setShowTimer(false);
    }
  }, [visible]);

  return (
    <>
      <SaucesContainer
        centered
        open={visible}
        footer={false}
        onCancel={handleModalCancel}
      >
        <ModalContent>
          <h5>Are you Still Here</h5>
          {showTimer && <h1>{formattedTime}</h1>}
          <Row justify="center" gutter={10}>
            <Col span={12}>
              <BackBtn
                style={{ background: "#518E24" }}
                onClick={handleModalCancel}
              >
                Yes I Am
              </BackBtn>
            </Col>
          </Row>
        </ModalContent>
      </SaucesContainer>
    </>
  );
};

export default LoginModal;
