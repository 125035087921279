import React, { useState, useEffect, useContext } from "react";

import {
  PAIRING_MENU,
  POS_SALE_UI,
  TXN,
  TXN_STATUS,
  SETTLEMENT,
  CANCEL,
} from "constants";
import "../../../App.css";
import { retrieveLinklyData, storeLinklyData } from "../helpers/SecretManager";
import usePOS from "hooks/usePOS";
import { PosContext } from "context/posContext";

const POS = (props) => {
  const [displayPage, setDisplayPage] = useState(PAIRING_MENU);
  const { token, setToken, tokenExpiry, setTokenExpiry, secret, setSecret } =
    useContext(PosContext);

  const { getToken, sendPairRequest, sendRequest, paired, setPaired } =
    usePOS();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pairCode, setPairCode] = useState("");
  const [txnResponse, setTxnResponse] = useState();
  const [amtPurchase, setAmtPurchase] = useState(10000);
  const [amtCash, setAmtCash] = useState(0);

  // console.log("paired :>>", paired);

  useEffect(() => {
    setSecret(retrieveLinklyData("secret"));
    setToken(retrieveLinklyData("token"));
    setTokenExpiry(retrieveLinklyData("token-expiry"));
  }, []);

  useEffect(() => {
    // console.log("DETECTING PAIRED");
    if (paired) setDisplayPage(POS_SALE_UI);
  }, [paired]);

  useEffect(() => {
    // console.log("SECRET NOTED BY EFFECT");
    if (secret) {
      // console.log("SECRET NOT NULL... STORING...");
      storeLinklyData("secret", secret);
      if (!token || tokenExpiry <= Date.now() || !paired) {
        // console.log("GETTING TOKEN");
        getToken(secret);
      } else {
        // console.log("SETTING PAIRED");
        setPaired(true);
      }
    }
  }, [secret]);

  useEffect(() => {
    if (token !== "" && tokenExpiry) {
      storeLinklyData("token", token);
      storeLinklyData("token-expiry", tokenExpiry);
    }
  }, [token, tokenExpiry]);

  const handleMenuChange = (e) => {
    if (e.target.value === "pairing") setDisplayPage(PAIRING_MENU);
    else if (e.target.value === "sale") setDisplayPage(POS_SALE_UI);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePairingCodeChange = (e) => {
    setPairCode(e.target.value);
  };

  const handleAmtPurchaseChange = (e) => {
    setAmtPurchase(e.target.value);
  };

  const handleAmtCashChange = (e) => {
    setAmtCash(e.target.value);
  };

  const oneButton = (params) => {
    const request = {
      txnType: "P",
      amtPurchase: 100,
      txnRef: "96B32M9UNZ421MEI",
      merchant: "99",
      application: "02",
      receiptAutoPrint: "0",
    };

    sendRequest(request, TXN);
  };

  const getTxnStatus = (params) => {
    try {
      const request = {};

      sendRequest(request, TXN_STATUS);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const settlement = (params) => {
    try {
      const request = {
        Merchant: "00",
        SettlementType: "S",
        Application: "00",
        ReceiptAutoPrint: "0",
        CutReceipt: "0",
      };

      sendRequest(request, SETTLEMENT);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const sendCancel = (params) => {
    try {
      const request = {
        Key: "0",
      };

      sendRequest(request, CANCEL);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  let display;
  switch (displayPage) {
    case PAIRING_MENU:
      display = (
        <form>
          <div className="form-group">
            <label htmlFor="clientIdInput">Username: </label>
            <input
              className="form-control w-75"
              id="clientIdInput"
              onChange={handleUsernameChange}
              value={username}
              placeholder="Linkly Cloud Username"
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="passwordInput">Password: </label>
            <input
              className="form-control w-75"
              id="passwordInput"
              onChange={handlePasswordChange}
              value={password}
              placeholder="Password"
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="pairingCodeInput">Pairing Code: </label>
            <input
              className="form-control w-75"
              id="pairingCodeInput"
              onChange={handlePairingCodeChange}
              value={pairCode}
              placeholder="Pairing code from pinpad"
            ></input>
          </div>
          <button
            type="button"
            className="btn btn-outline-info"
            onClick={() => {
              sendPairRequest({ username, password, pairCode });
            }}
          >
            Pair
          </button>
        </form>
      );
      break;
    case POS_SALE_UI:
      display = (
        <div>
          <form className="row">
            <div className="form-group col">
              <label htmlFor="amtPurchaseInput">Purchase Amount: </label>
              <input
                className="form-control"
                id="amtPurchaseInput"
                onChange={handleAmtPurchaseChange}
                value={amtPurchase}
                placeholder="Amount"
              ></input>
            </div>
          </form>
          <br />
        </div>
      );
      break;
    default:
      display = <p>Please refresh page</p>;
  }

  let printResponse;
  if (txnResponse) {
    printResponse = Object.entries(txnResponse).map((field) => {
      return (
        <tr key={field[0]}>
          <th scope="col">{field[0]}: </th>
          <td scope="col">{typeof field[1] !== "object" ? field[1] : null}</td>
        </tr>
      );
    });
  }

  const POSOutput = (
    <table className="table table-sm">
      <thead>
        <tr>
          <th scope="col">Field</th>
          <th scope="col">Response</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Connect Status: </th>
          <td>{paired ? "Connected" : "Offline"}</td>
        </tr>
        {printResponse}
      </tbody>
    </table>
  );

  // TODO: UX friendly way to configure PAD tags and Linkly basket

  return (
    <div className="card-body card-div ">
      <div className="row pos-card">
        <div className="col">
          <div
            className="btn-group btn-group-toggle mb-3"
            data-toggle="buttons"
          >
            <label
              className={
                "btn btn-primary " +
                (displayPage === POS_SALE_UI ? "" : "active")
              }
            >
              <input
                type="radio"
                name="options"
                id="option1"
                autoComplete="off"
                onClick={handleMenuChange}
                value="pairing"
              />{" "}
              Pair PINpad
            </label>
          </div>
          <br />
          <br />
          {display}
        </div>
        {/* <ProgressModal txnInProgress={txnInProgress} sendCancel={sendCancel} /> */}
        <div className="col pos-output">{POSOutput}</div>
      </div>
    </div>
  );
};

export default POS;
