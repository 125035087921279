import React, { useEffect, useRef, useState } from "react";
import {
  BtnContainer,
  Container,
  HeadTxtCont,
  InputContainer,
  Linkly,
  Location,
  Shift8,
  Status,
  SubmitBtn,
  Title,
  Wrapper,
} from "./index.style";
import AdminHeader from "../components/AdminHeader";
import { Col, Form, Row } from "antd";
import usePOS from "hooks/usePOS";
import { isLive } from "../helpers";
import { storeLinklyData } from "screens/settings/helpers/SecretManager";
import { toast } from "react-toastify";

import KioskBoard from "kioskboard";
import LocationStatus from "./LocationStatus";

const PosData = () => {
  const storeLocationRef = useRef(null);
  const addressRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const shift8Id1Ref = useRef(null);
  const shift8Id2Ref = useRef(null);
  const shift8Id3Ref = useRef(null);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const pairCodeRef = useRef(null);
  const [posLocations, setPosLocations] = useState({});
  // Send Pair Request.
  const {
    sendPairRequest,
    paired,
    setPaired,
    secret,
    token,
    tokenExpiry,
    getToken,
  } = usePOS();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const values = localStorage.getItem("posData");
    const parsedValues = (values && JSON.parse(values)) || null;
    if (parsedValues) {
      const { storeLocation, username, password, pairCode, ...rest } =
        parsedValues;
      setPosLocations(rest);
      setInitialValues(parsedValues);
      form.setFieldsValue({
        ...parsedValues,
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetLocationsStatus(posLocations);
    })();
  }, [posLocations]);

  /**
   * The `onFinish` function extracts values from an object, checks if the values are live, and returns
   * the name.
   * @param values - The `values` parameter is an object that contains various properties. The
   * properties include `storeLocation`, `userName`, `password`, `pairingCode`, and other properties
   * that are not explicitly mentioned (represented by the `...rest` syntax).
   */
  const [posLocationStatus, setPosLocationStatus] = useState({
    shift8Id1: false,
    shift8Id2: false,
    shift8Id3: false,
  });

  const fetLocationsStatus = async (locations) => {
    const posLocations = Object.keys(locations).map((key) => ({
      [key]: locations[key],
    }));

    const locationPromises = posLocations.map(
      (location) =>
        new Promise(async (resolve, reject) => {
          try {
            const key = Object.keys(location)[0];
            const res = await isLive([location[key]]);
            if (res) {
              setPosLocationStatus((prev) => ({ ...prev, [key]: true }));
            }
            resolve(true);
          } catch (error) {
            // Handle errors if needed
            reject(error);
          }
        })
    );

    try {
      await Promise.all(locationPromises);
    } catch (error) {}
  };

  const onFinish = async (values) => {
    const { storeLocation, username, password, pairCode, ...rest } = values;

    // First, get the existing data from localStorage
    const existingPosData = JSON.parse(localStorage.getItem("posData") || "{}");

    // Next, merge existing data with the new data
    const updatedPosData = {
      ...existingPosData,
      ...values
    };

    // Finally, save the updated data back to localStorage
    localStorage.setItem("posData", JSON.stringify(updatedPosData));

    try {
      await fetLocationsStatus(rest);
    } catch (e) {}

    try {
      const pairedRes = await sendPairRequest({
        username,
        password,
        pairCode,
      });
    } catch (error) {
      console.error("Error in example usage:", error);
    }
  };

  const onFinishFailed = (values) => {};

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    form.setFieldsValue({ [name]: value });
  };

  useEffect(() => {
    // console.log("SECRET NOTED BY EFFECT");
    if (secret) {
      // console.log("SECRET NOT NULL... STORING...");
      storeLinklyData("secret", secret);
      if (!token || tokenExpiry <= Date.now()) {
        // console.log("GETTING TOKEN");
        getToken(secret);
      } else {
        // console.log("SETTING PAIRED");
        setPaired(true);
      }
    }
  }, [secret]);

  const setupKioskBoard = (ref) => {
    if (ref.current) {
      KioskBoard.run(ref.current, {
        language: "en",
        theme: "dark",
        allowRealKeyboard: true,
        allowMobileKeyboard: true,
        // The Enter key can close and remove the keyboard. Prevented when "false"
        keysEnterCanClose: true,
        keysArrayOfObjects: [
          {
            0: "1",
            1: "2",
            2: "3",
            3: "4",
            4: "5",
            5: "6",
            6: "7",
            7: "8",
            8: "9",
            9: "0",
          },
          {
            0: "Q",
            1: "W",
            2: "E",
            3: "R",
            4: "T",
            5: "Y",
            6: "U",
            7: "I",
            8: "O",
            9: "P",
          },
          {
            0: "A",
            1: "S",
            2: "D",
            3: "F",
            4: "G",
            5: "H",
            6: "J",
            7: "K",
            8: "L",
          },
          {
            0: "Z",
            1: "X",
            2: "C",
            3: "V",
            4: "B",
            5: "N",
            6: "M",
          },
        ],
      });
    }
  };

  useEffect(() => {
    setupKioskBoard(storeLocationRef);
    setupKioskBoard(addressRef);
    setupKioskBoard(phoneNumberRef);
    setupKioskBoard(shift8Id1Ref);
    setupKioskBoard(shift8Id2Ref);
    setupKioskBoard(shift8Id3Ref);
    setupKioskBoard(usernameRef);
    setupKioskBoard(passwordRef);
    setupKioskBoard(pairCodeRef);
  }, [
    storeLocationRef,
    addressRef,
    phoneNumberRef,
    shift8Id1Ref,
    shift8Id2Ref,
    shift8Id3Ref,
    usernameRef,
    passwordRef,
    pairCodeRef,
  ]);

  const addEventListener = (ref, event, handler) => {
    if (ref.current) {
      ref.current.addEventListener(event, handler);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (ref.current) {
        ref.current.removeEventListener(event, handler);
      }
    };
  };

  useEffect(() => {
    // Add event listeners for other refs
    const cleanupStoreLocationListener = addEventListener(
      storeLocationRef,
      "change",
      handleChange
    );

    const cleanupStoreAddressListener = addEventListener(
      storeLocationRef,
      "change",
      handleChange
    );

    const cleanupStorePhoneNumberListener = addEventListener(
      storeLocationRef,
      "change",
      handleChange
    );

    const cleanupShift8Id1Listener = addEventListener(
      shift8Id1Ref,
      "change",
      handleChange
    );

    const cleanupShift8Id2Listener = addEventListener(
      shift8Id2Ref,
      "change",
      handleChange
    );

    const cleanupShift8Id3Listener = addEventListener(
      shift8Id3Ref,
      "change",
      handleChange
    );

    const cleanupUsernameListener = addEventListener(
      usernameRef,
      "change",
      handleChange
    );

    const cleanupPasswordListener = addEventListener(
      passwordRef,
      "change",
      handleChange
    );

    const cleanupPairCodeListener = addEventListener(
      pairCodeRef,
      "change",
      handleChange
    );

    return () => {
      cleanupStoreLocationListener();
      cleanupStoreAddressListener();
      cleanupStorePhoneNumberListener();
      cleanupShift8Id1Listener();
      cleanupShift8Id2Listener();
      cleanupShift8Id3Listener();
      cleanupUsernameListener();
      cleanupPasswordListener();
      cleanupPairCodeListener();
    };
  }, [
    storeLocationRef,
    addressRef,
    phoneNumberRef,
    shift8Id1Ref,
    shift8Id2Ref,
    shift8Id3Ref,
    usernameRef,
    passwordRef,
    pairCodeRef,
  ]);

  return (
    <>
      <Form
        form={form}
        name="paring"
        initialValues={{
          remember: true,
          ...initialValues,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="on"
      >
        <Wrapper>
          <Container>
            <HeadTxtCont>
              <p>POS & FPOS Paring</p>
              <Status status={paired}>
                <span></span>
                <p>{(paired && "Online") || "Offline"}</p>
              </Status>
            </HeadTxtCont>

            <Shift8>
              <Title>Shift 8</Title>
              <Row gutter={36}>
                <Col span={12}>
                  <InputContainer>
                    <div
                      style={{
                        display: "flex",
                        columnGap: 50,
                      }}
                    >
                      <p>Shift 8 POS ID: 1</p>
                      <LocationStatus status={posLocationStatus?.shift8Id1} />
                    </div>
                    <Form.Item
                      name="shift8Id1"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Shift 8 POST ID : 1!",
                        },
                      ]}
                    >
                      <input
                        id="shift8Id1"
                        data-kioskboard-type="keyboard"
                        ref={shift8Id1Ref}
                        name="shift8Id1"
                        type="text"
                      />
                    </Form.Item>
                  </InputContainer>
                </Col>
                <Col span={12}>
                  <InputContainer>
                    <div
                      style={{
                        display: "flex",
                        columnGap: 50,
                      }}
                    >
                      <p>Shift 8 POS ID: 2</p>
                      <LocationStatus status={posLocationStatus?.shift8Id2} />
                    </div>
                    <Form.Item
                      name="shift8Id2"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Shift 8 ID : 2!",
                        },
                      ]}
                    >
                      <input
                        data-kioskboard-type="keyboard"
                        ref={shift8Id2Ref}
                        name="shift8Id2"
                        type="text"
                      />
                    </Form.Item>
                  </InputContainer>
                </Col>
              </Row>

              <InputContainer>
                <div
                  style={{
                    display: "flex",
                    columnGap: 50,
                  }}
                >
                  <p>Shift 8 POS ID: 3</p>
                  <LocationStatus status={posLocationStatus?.shift8Id3} />
                </div>
                <Form.Item
                  name="shift8Id3"
                  rules={[
                    {
                      required: false,
                      message: "Please input your Shift 8 ID 3!",
                    },
                  ]}
                >
                  <input
                    data-kioskboard-type="keyboard"
                    ref={shift8Id3Ref}
                    name="shift8Id3"
                    type="text"
                  />
                </Form.Item>
              </InputContainer>
            </Shift8>
            <Linkly>
              <Title>Linkly</Title>

              <InputContainer>
                <p>User Name</p>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your User Name!",
                    },
                  ]}
                >
                  <input
                    data-kioskboard-type="keyboard"
                    ref={usernameRef}
                    name="username"
                    type="text"
                  />
                </Form.Item>
              </InputContainer>
              <InputContainer>
                <p>Password</p>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <input
                    data-kioskboard-type="keyboard"
                    ref={passwordRef}
                    name="password"
                    type="password"
                  />
                </Form.Item>
              </InputContainer>
              <InputContainer>
                <p>Paring Code</p>
                <Form.Item
                  name="pairCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Paring Code!",
                    },
                  ]}
                >
                  <input
                    data-kioskboard-type="keyboard"
                    ref={pairCodeRef}
                    name="pairCode"
                    type="text"
                  />
                </Form.Item>
              </InputContainer>
            </Linkly>
            <BtnContainer>
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </BtnContainer>
          </Container>
        </Wrapper>
      </Form>
    </>
  );
};

export default PosData;
