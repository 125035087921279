import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9991;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 4px;
  padding-left: 7px;
`;
export const HeadTxtCont = styled.div`
  padding: 1rem;
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: center;
  p {
    color: white;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0px !important;
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    width: 28px;
    height: 28px;
    background-color: red !important;
    border-radius: 50%;
  }
  p {
    color: white;
    font-size: 2em;
    font-weight: 600;
    margin: 0px !important;
  }
`;

export const Location = styled.div`
  padding: 1rem;
`;
export const Title = styled.div`
  color: #3ca3dc !important;
  font-size: 2.5em;
  font-weight: 600;
  margin-top: 2em;
`;
export const UserText = styled.div`
  padding-top: 0.8em;
  color: white;
  font-size: 1.7em;
  font-weight: 500;
`;

export const InputContainer = styled.div`
  padding-top: 1rem;
  p {
    color: white !important;
  }

  input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    padding-left: 10px;
  }
`;
export const Shift8 = styled.div`
  padding: 1rem;
  padding-top: 7rem;
  p {
    color: white !important;
  }

  input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    padding-left: 10px;
  }
`;
export const Linkly = styled.div`
  padding: 1rem;
  padding-top: 7rem;
  p {
    color: white !important;
  }

  input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    padding-left: 10px;
  }
`;

export const BtnContainer = styled.div`
  padding: 1rem;
`;

export const SubmitBtn = styled.button`
  padding: 0.25rem;
  border-radius: 50px;
  width: 250px;
  height: 80px;
  background-color: #3ca3dc;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2em;
  margin-top: 2em;
  border: none;
  font-weight: 400;
  color: white !important;
`;
