import React from "react";
import { Wrapper } from "../../common/index.style";
import SelectTab from "../../components/tabs";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useState } from "react";

const Home = () => {
  const [listItems, setListItems] = useState([]);
  const [count, setCount] = useState(1);

  return (
    <Wrapper>
      <Header listItems={listItems} />
      <SelectTab
        count={count}
        setCount={setCount}
        listItems={listItems}
        setListItems={setListItems}
      />
      <Footer />
    </Wrapper>
  );
};

export default Home;
