import { Tabs } from "antd";
import styled from "styled-components";

export const TabContainer = styled(Tabs)`
  margin-top: 2em;
  position: relative;

  /* Fade at the end of the scroll bar from Right side. */

  &::after {
    content: "";
    position: absolute;
    top: 5px;
    right: -1px;
    width: 6%; /* Adjust the width to control the fade length */
    height: 80%;
    background: linear-gradient(
      to right,
      #fbf8ee07,
      #fbf8ee50,
      #fbf8ee90,
      #fbf8ee
    );
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 6%; /* Adjust the width to control the fade length */
    height: 82%;
    left: -1px;
    background: ${(props) =>
      (props.scrolling &&
        "linear-gradient(to left, #fbf8ee07, #fbf8ee50, #fbf8ee)") ||
      ""};
  }
  .ant-tabs-nav-list {
    // flex-wrap: wrap;
    // justify-content: space-between;
    // column-count: 10;
    // .ant-tabs-tab {
    //   margin: 0 !important;
    // }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav {
    &::before {
      display: none;
    }
  }
  .ant-tabs-nav-wrap {
    &::after,
    &::before {
      display: none;
    }
  }
  .ant-tabs-tab {
    padding: 8px 0;

    .cardItems {
      width: 223px;
      height: 190px;
      border-radius: 40px;
      margin-bottom: 1.5em;
      h6 {
        font-size: 1.2em;
      }
      @media (max-width: 992px) {
        margin-bottom: 0.5em;
        & {
          width: 165px;
          height: 160px;
          div {
            height: 76px;
          }
          h6 {
            font-size: 1em;
          }
        }
      }
      @media (max-width: 768px) {
        & {
          width: 155px;
          height: 144px;
          h6 {
            margin-top: 0.5em;
            font-size: 1em;
          }
        }
      }
      @media (max-width: 670px) {
        & {
          width: 127px;
          height: 117px;
          border-radius: 28px;
          div {
            width: 70px;
          }
          h6 {
            margin-top: 0.2em;
            font-size: 0.7em;
            font-weight: 700;
          }
        }
      }
      @media (max-width: 585px) {
        & {
          width: 113px;
          height: 105px;
          div {
            width: 70px;
            height: 62px;
          }
          h6 {
            font-size: 0.6em;
          }
        }
      }
      @media (max-width: 500px) {
        & {
          width: 100px;
          height: 95px;
          border-radius: 25px;
        }
      }
      @media (max-width: 470px) {
        & {
          width: 80px;
          height: 80px;
          div {
            width: 50px;
            height: 42px;
          }
          h6 {
            font-size: 0.5em;
          }
        }
      }
    }
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      div {
        background-color: ${(props) => props?.theme?.colors?.primary};
        border-radius: 40px;
        h6 {
          color: #ffffff !important;
        }
      }
    }
  }
  @media (max-width: 992px) {
    & {
      margin-top: 3em;
    }
  }
  @media (max-width: 670px) {
    & {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            border-radius: 25px;
          }
        }
      }
    }
  }
  @media (max-width: 470px) {
    & {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          div {
            border-radius: 20px;
          }
        }
      }
    }
  }
`;
export const CardStyle = styled("div")``;
export const ScrollItems = styled("div")`
  position: relative;
  margin-top: 2em;
`;
export const ScrollArrow = styled("span")`
  position: absolute;
  right: -5%;
  top: 55px;
  font-size: 3.5em;
`;
export const LeftArrow = styled("span")`
  position: absolute;
  left: -5%;
  top: 55px;
  font-size: 3.5em;
`;
export const CancelBtn = styled("span")`
  max-width: 200px;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  margin: 1em 0em 1em auto;
  background: transparent;
  color: #000000;
  border: 1px solid #000000;
  font-size: 1.4em;
  padding: 0.5em 1.5em;
  border-radius: 30px;
  transition: 200ms;
  &:hover {
    background: #000000;
    color: #ffffff;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    & {
      font-size: 0.8em;
      padding: 0.2em 0.5em;
      max-width: 180px;
    }
  }
`;
