import {setSelectedProduct} from "Redux/productSlice";
import useData from "hooks/useData";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import CustomizeOrder from ".";
import {not} from "utils/helper";
import {GlobalStatesContext} from "context/GlobalStatesContext";
import {prepareProductData} from "./helper";

const CustomizeOrderWrapper = () => {
    const childs = [
        "MP-883803",
        "MP-883810",
        "MP-883797",
        "MP-883780",
        "MP-883819",
        "MP-883783",
    ];
    const {setGlobalState, globalState} = useContext(GlobalStatesContext);
    const {category, productId} = useParams();
    const {formatedMenuData, menuData} = useData();
    let location = useLocation();

    // get the search parameters from the location
    let searchParams = new URLSearchParams(location.search);

    // get your desired parameter
    let isDealItem = searchParams.get('isDealItem');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {selectedProduct} = useSelector((state) => state?.products);
    const [selectedProductLocal, setSelectedProductLocal] = useState(null);
    console.log(selectedProduct, ' toppp')

    /** Check this current product is going to edit. */
    const isEditing = () => {
        return selectedProduct?.editing;
    };


    useEffect(() => {
        console.log(category, " currentCategory");
    });


    useEffect(() => {
        let item = formatedMenuData?.find((el) => el?.productCode == productId);
        if (not(item)) {
            // debugger;
            item = menuData?.find((el) => el?.productCode == productId);
        }
        // debugger;
        if (isEditing() && !childs?.includes(item?.productCode) || (!isDealItem && category !== 'Breakfast' && category !== 'Kids')) {

            dispatch(
                setSelectedProduct({
                    ...selectedProduct,
                })
            );
        } else {
            debugger;
            dispatch(
                setSelectedProduct({...item, ...item?.itemSize[0], quantity: 1})
            );
        }
    }, [category, productId, formatedMenuData, menuData]);

    // Does deal contains the child item in the childs object.
    const isChildEditing = (selectedProduct, globalState, productCode) => {
        return (
            selectedProduct?.childs?.[productCode] ??
            globalState?.customOrder?.childs?.[productCode] ??
            false
        );
    };

    /** HANDLE HOT CHOCOLATE FOR KIDS MEAL */
    const handleSelectHotChocolate = ({
                                          section,
                                          extra,
                                          productId,
                                          childId,
                                          currentSelectedExtras,
                                          currentSelectedModifires,
                                          actualCost,
                                          parentSize,
                                          selectedSize,
                                          parentProductId,
                                          ...rest
                                      }) => {

        const childEditing = isChildEditing(
            selectedProduct,
            globalState,
            extra?.productCode
        );
        const dealItemCost = childEditing
            ? selectedProduct?.childs?.[extra?.productCode]?.cost ??
            globalState?.customOrder?.childs?.[extra?.productCode]?.cost
            : extra?.itemCost;

        /* The code block is updating the global state by adding a new property called `customOrder`. The
          `customOrder` property stores information about the current item being selected, such as the
          category, item, productId, and an `updatedItem` object.
          * In globalState there are lot of other states may be stored, there for I am seperading the previous
           And for store the current item state I am using the property customOrder.*/
        debugger
        // setGlobalState((prev) => {
        //     return {
        //         ...prev,
        //         customOrder: {
        //             ...prev?.customOrder,
        //             category: section,
        //             item: extra,
        //             productId,
        //             updatedItem: {},
        //             currentSelectedExtras,
        //             currentSelectedModifires,
        //             actualCost: actualCost,
        //             editing: {
        //                 [productId]: selectedProduct?.editing || false,
        //             },
        //             selectedSize,
        //             ...rest,
        //         },
        //     };
        // });


        setGlobalState((prev) => {
            return {
                ...prev,
                parentProductId,
                [productId]: {
                    ...prev[productId],
                    customOrder: {
                        ...prev[productId]?.customOrder,
                        category: section,
                        item: extra,
                        updatedItem: {},
                        currentSelectedExtras,
                        currentSelectedModifires,
                        actualCost: actualCost,
                        editing: selectedProduct?.editing || false,
                        selectedSize,
                        ...rest,
                    },
                },
            };
        });


        /** IF current opening item is the part of the deal then i'm saving the satate in the URL */
        navigate(
            `/customize_order/Coffee & Hot Drinks/${childId}?isDealItem=true&dealItemCost=${dealItemCost}&parentSize=${parentSize}`
        );
    };


    console.log(selectedProduct, ' selectedProduct... ');

    return (
        <>
            {(selectedProduct?.productCode == productId && (
                <CustomizeOrder
                    handleSelectHotChocolate={handleSelectHotChocolate}
                    selectedProduct={selectedProduct}
                    currentCategory={category}
                />
            )) || <h1>Saregama</h1>}
        </>
    );
};

export default CustomizeOrderWrapper;
