import React from "react";
import { AdminParingContextProvider } from "./AdminParingContext";
import { AdminTransectionsContextProvider } from "./AdminTransectionsContext";

export const AdminProvider = ({ children }) => {
  return (
    <AdminParingContextProvider>
      <AdminTransectionsContextProvider>
        {children}
      </AdminTransectionsContextProvider>
    </AdminParingContextProvider>
  );
};
