import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "../screens/home";
import PayOrder from "../screens/payOrder";
import CompleteOrders from "../screens/completeOrders";
import QrScanner from "../screens/QrScanner";
import Instructions from "../screens/instructions";
import YourOrder from "../screens/yourOrder";
import ProductDetails from "../screens/Details";
import MemberCard from "../screens/memberCard";
import ConnectPinpad from "screens/settings/ConnectPinPad";
import CustomizeOrderWrapper from "screens/customizeOrder/CustomizeOrderWrapper";
import Declined from "screens/declined";
import FailedError from "screens/instructions/failedError";
import Signature from "screens/signature";
import Login from "screens/admin/login";
import Transactions from "screens/admin/transactions";
import PosData from "screens/admin/posData";
import PairData from "screens/admin/pairData";
import InCompleteOrders from "screens/inCompleteOrder";
import Settings from "screens/admin/settings";
import Admin from "../screens/admin";

const AllRoutes = () => {
    return (
        <Routes gestureHandling="none">
            <Route path="/" element={<Home/>}/>
            <Route
                path="/customize_order/:category/:productId"
                element={<CustomizeOrderWrapper/>}
            />
            <Route path="/your_order" element={<YourOrder/>}/>
            <Route path="/pay_order" element={<PayOrder/>}/>
            <Route path="/order_complete" element={<CompleteOrders/>}/>
            <Route path="/order_incomplete" element={<InCompleteOrders/>}/>
            <Route path="/order_declined" element={<Declined/>}/>
            <Route path="/order_failed_error" element={<FailedError/>}/>
            <Route path="/order_signature" element={<Signature/>}/>
            <Route path="/instructions" element={<Instructions/>}/>
            <Route path="/scan-code" element={<QrScanner/>}/>
            <Route path="/member_card" element={<MemberCard/>}/>
            <Route path="/product_details/:productId" element={<ProductDetails/>}/>
            <Route path="/settings/connect-pinpad" element={<ConnectPinpad/>}/>

            {/* <Route path="/product_details" element={<ProductDetails />} /> */}
            <Route path="/admin" element={<Admin/>}>
                <Route path="login" element={<Login/>}/>
                <Route path="transactions" element={<Transactions/>}/>
                <Route path="pair-data" element={<PairData/>}/>
                <Route path="pos-data" element={<PosData/>}/>
                <Route path="settings" element={<Settings/>}/>
            </Route>
        </Routes>
    );
};

export default AllRoutes;
