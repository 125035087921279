import React, { useState } from "react";
import {
  AddBtn,
  ModalContent,
  SaucesCategory,
  SaucesContainer,
} from "./index.style";
import { Col, Row } from "antd";

const SaltItemModal = ({ visible, setVisible, setSelectedOption }) => {
  const handleClick = (item) => {
    setSelectedOption(item)
  }
  return (
    <>
      <SaucesContainer
        // title="Basic Modal"
        centered
        open={visible}
        footer={false}
        onCancel={setVisible}
      >
        <ModalContent>
          <h1>Would you like salt</h1>
          <Row justify="start" gutter={10}>
            <Col span={12}>
              <SaucesCategory>
                <h1 onClick={() => handleClick('With Salt')}>With Salt</h1>
              </SaucesCategory>
            </Col>
            <Col span={12}>
              <SaucesCategory>
                <h1 onClick={() => handleClick("Without Salt")}>Without Salt</h1>
              </SaucesCategory>
            </Col>
          </Row>
          <AddBtn onClick={setVisible}>Done</AddBtn>
        </ModalContent>
      </SaucesContainer>
    </>
  );
};

export default SaltItemModal;
