import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled("div")`
  background-color: #fbf8ee;
  padding: 2em 3em 1em;
  max-width: 1080px;
  margin: 0 auto;
  .boxContainer {
    margin-top: 10px;
    // height: 500px;
    overflow: auto;
  }
  .boxContainer::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    & {
      padding: 1em 1.5em 0em;
    }
  }
`;
export const CardContainer = styled("div")`
  width: 223px;
  height: 190px;
  margin: 0 auto 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 40px;
  background-color: #e0d4bb;
  div {
    padding: 0.5em 0.5em 0 0.5em;
    width: 89%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 115px;
  }
  img {
    max-width: 80%;
    height: auto;
  }
  h6 {
    margin-top: 1em;
    font-size: 1.2em;
    font-weight: 800;
    text-transform: capitalize;
    font-family: "Foundry Context W03 Md" !important;
  }
  @media (max-width: 992px) {
    & {
      width: 165px;
      height: 160px;
      margin: 0 auto 0.5em;
      div {
        margin-top: 0.5em;
        height: 76px;
      }
      h6 {
        font-size: 1em;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      width: 150px;
      height: 120px;
      h6 {
        margin-top: 0.5em;
        font-size: 0.85em;
      }
    }
  }

  @media (max-width: 670px) {
    & {
      width: 127px;
      height: 95px;
      border-radius: 25px;
      div {
        width: 70px;
      }
      h6 {
        margin-top: 0.2em;
        font-size: 0.7em;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 585px) {
    & {
      width: 113px;
      height: 85px;
      div {
        width: 70px;
        height: 62px;
        margin-top: 0em;
      }
      h6 {
        font-size: 0.6em;
      }
    }
  }
  @media (max-width: 500px) {
    & {
      width: 100px;
      height: 80px;
    }
  }
  @media (max-width: 470px) {
    & {
      width: 85px;
      height: 70px;
      border-radius: 20px;
      div {
        width: 50px;
        height: 42px;
      }
      h6 {
        font-size: 0.5em;
      }
    }
  }
  @media (max-width: 400px) {
    & {
      width: 95px;
      height: 60px;
    }
  }
  @media (max-width: 390px) {
    & {
      width: 80px;
    }
  }
`;
export const TopHeading = styled("h2")`
  font-size: 2.5em;
  font-weight: 800;
  margin: 0.5em 1em;
  font-family: "Montserrat", sans-serif !important;
  @media (max-width: 992px) {
    & {
      font-size: 2em;
    }
  }
  @media (max-width: 786px) {
    & {
      font-size: 1.5em;
    }
  }
`;

export const BackBtn = styled(Link)`
  display: block;
  text-align: center;
  margin: 0.5em 0 1em;
  width: 160px;
  background: #000000;
  font-size: 1.3em;
  color: #ffffff;
  font-family: "Helvetica-light";
  padding: 0.7em 0;
  border-radius: 40px;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 786px) {
    & {
      width: 90px;
      font-size: 0.9em;
      padding: 0.3em 0.3em;
      margin: 0.5em 0;
    }
  }
`;
export const NextBtn = styled(Link)`
  display: block;
  text-align: center;
  margin: 0.5em 0 0 auto;
  width: 160px;
  background: ${(props) => props?.theme?.colors?.primary};
  font-size: 1.3em;
  color: #ffffff;
  font-family: "Helvetica-light";
  padding: 0.7em 0;
  border-radius: 40px;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 786px) {
    & {
      width: 90px;
      font-size: 0.9em;
      padding: 0.3em;
      margin: 0.5em 0;
    }
  }
`;

export const Cancel = styled(NextBtn)`
  margin: 0;
  align-self: center;
  margin-top: 1rem;
  background-color: #000;
  width: 270px;
  padding: 0.3em 1em;
  font-size: 1.7em;
  margin-top: 3em;
`;
