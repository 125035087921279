const { is } = require("utils/helper");

/** Display small item name instead of the default price.
 * @param {Object} item
 * @return {Number} - price.
 */
export function getPrice(item) {
  const selectedSize = item?.itemSize?.find((itemSize) => {
    return is(itemSize?.size) && itemSize?.default === "true" && itemSize?.cost;
  });
  return selectedSize?.cost || item?.cost; // Return the cost if selectedSize exists, otherwise return the item cost.
}
