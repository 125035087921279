import { theme } from "antd";
import styled from "styled-components";

export const OrderContainer = styled("div")`
  background-color: #e2dac6;
  border-radius: 30px;
  padding: 2.5em 1.6em;
  // height: 240px;
  margin: 1em 0;
  position: relative;
  h2 {
    font-size: 1.7em;
    margin-bottom: 0;
    margin-top: 0.6em;
    align-items: center;
    line-height: 1;
    position: relative;
    a {
      position: absolute;
      top: -2px;
      right: 0;
      margin-left: 0.6em;
      color: #000000;
      svg {
        margin-bottom: -0.2em;
      }
    }
  }
  .totalCost {
    font-family: "Helvetica-light" !important;
  }
  p {
    margin-top: 0.1em;
    font-size: 0.8em;
  }
  img {
    max-width: 100%;
    height: auto;
    margin: 0.5em 0;
  }
  @media (max-width: 992px) {
    & {
      padding: 1.5em 1.6em;
      h2 {
        font-size: 1.5em;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 1em 1.6em;
      h2 {
        font-size: 1.1em;
        margin-top: 1em;
      }
    }
  }
  @media (max-width: 500px) {
    & {
      padding: 0.7em 1.3em 1em;
    }
  }
`;
export const CartBtn = styled("div")`
  .disabled {
    cursor: not-allowed;
    background-color: gray;
  }
  span {
    cursor: pointer;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0em auto 1em;
    background: ${(props) => props?.theme?.colors?.primary};
    font-family: Helvetica-light !important;
    color: #ffffff;
    font-size: 1em;
    padding: 0.5em 0.5em;
    border-radius: 30px;
  }
  @media (max-width: 992px) {
    & {
      span {
        padding: 0.2em 0.5em;
        width: 130px;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      span {
        padding: 0.2em 0.5em;
        width: 90px;
        font-size: 0.8em;
      }
    }
  }
  @media (max-width: 440px) {
    & {
      span {
        padding: 0.1em 0.5em;
        width: 70px;
        font-size: 0.7em;
      }
    }
  }
`;
export const AddItems = styled("div")`
  width: 185px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  right: -3px;
  bottom: -30px;
  @media (max-width: 992px) {
    & {
      width: 150px;
      bottom: -15px;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 100px;
    }
  }
  @media (max-width: 500px) {
    & {
      width: 90px;
    }
  }
`;
export const AddBtn = styled("span")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
  width: 65px;
  height: 25px;
  background: #ffffff;
  color: #000000;
  font-size: 1.1em;
  border-radius: 30px;
  line-height: 1.6;
  cursor: pointer;
  @media (max-width: 992px) {
    & {
      margin: 0.2em auto;
      font-size: 1em;
      width: 50px;
      height: 20px;
    }
    @media (max-width: 768px) {
      & {
        margin: 0.5em auto;
        font-size: 0.8em;
        width: 30px;
        height: 15px;
      }
    }
    @media (max-width: 440px) {
      & {
        margin: 0.3em auto;
        font-size: 0.6em;
        width: 30px;
        height: 14px;
      }
    }
  }
`;
export const DeleteBtn = styled("span")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
  width: 65px;
  height: 25px;
  background: #ffffff;
  color: #000000;
  font-size: 1.1em;
  border-radius: 30px;
  line-height: 1.6;
  cursor: pointer;
  @media (max-width: 992px) {
    & {
      margin: 0.2em auto;
      width: 50px;
      font-size: 1em;
      height: 20px;
    }
  }
  @media (max-width: 768px) {
    & {
      margin: 0.5em auto;
      font-size: 0.8em;
      width: 30px;
      height: 15px;
    }
  }
  @media (max-width: 440px) {
    & {
      margin: 0.3em auto;
      font-size: 0.6em;
      width: 30px;
      height: 14px;
    }
  }
`;
export const CountItems = styled("p")`
  margin: 0 0.3em;
  font-size: 2.3em !important;
  font-family: "HankenGrotesk-Regular";
  @media (max-width: 768px) {
    & {
      font-size: 1.3em !important;
    }
  }
  @media (max-width: 600px) {
    & {
      font-size: 1.2em !important;
    }
  }
`;
export const AddMore = styled("div")`
  display: flex;
  align-items: center;
`;
export const SelectImg = styled("div")`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    & {
      height: 90px;
      padding: 0 0.5em;
    }
  }
`;

export const SelectedItems = styled("div")`
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  // column-gap: 40px;
  p {
    margin-top: 0.1em;
    font-size: 1em;
    line-height: 1 !important;
    margin-bottom: 0em;
    font-family: "HankenGrotesk-Regular";
  }
  @media (max-width: 600px) {
    & {
      p {
        font-size: 0.8em;
        line-height: 1 !important;
      }
    }
  }
`;
export const CloseIcon = styled("span")`
  position: absolute;
  right: 15px;
  top: 10px;
  height: 40px;
  svg {
    font-size: 2.5em;
  }
  @media (max-width: 600px) {
    & {
    }
  }
`;

// Message on cart items.
export const InfoMessage = styled.p`
    color: green;
    font-weight: bold;
    font-size: 1.4rem !important;

    ::before {
        content: "*";
    }
`;

export const WarningMessage = styled.p`
  color: red;
  font-weight: bold;

  ::before {
    content: "*";
  }
`
