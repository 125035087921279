import React, {useContext, useEffect, useState} from "react";
import {Cancel, Wrapper} from "../../common/index.style";
import Logo from "../payOrder/Logo";
import {Heading, OrderNum, OrderNumContainer, QRCode, QRCodeContainer, Receipt,} from "./index.style";
import {Link, useNavigate} from "react-router-dom";
import checkOptionsData from "./checkmark.json";
import Lottie from "react-lottie";
import {useDispatch, useSelector} from "react-redux";
import {makeCartEmpty, selectGetCartItems,} from "../../Redux/cartSlice";
import {clearSelectedProducts} from "../../Redux/productSlice";
import {LoadedContext} from "context/LoadedContext";
import usePOS from "hooks/usePOS";
import {extractItems, formatCardNumber, getChildsArray, getCurrentDateTime,} from "utils/helper";
import {CATAGORIES} from "constants/CATAGORIES";
import {GlobalStatesContext} from "context/GlobalStatesContext";
import {PosContext} from "context/posContext";
import ReceiptComponent from "components/QR-recipt/ReceiptComponent";
import useClearApp from "hooks/useClearApp";
import {v4 as uuidv4} from "uuid";
import {receiptLogo} from "constants";

const defaultOptions = {
    loop: true, // Set to true if you want the animation to loop
    autoplay: true, // Set to false if you don't want the animation to autoplay on render
    animationData: checkOptionsData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const CompleteOrders = () => {
    const {setLanding} = useContext(LoadedContext);
    const {txnResponse, shift8Response} = useContext(PosContext);
    const {selectedProduct} = useSelector((state) => state?.products);
    const {globalState} = useContext(GlobalStatesContext);
    const [childItems, setChildItems] = useState([]);
    const {orderCount} = useContext(GlobalStatesContext);
    const [itemDisplay, setItemDisplay] = useState([]);
    const navigate = useNavigate();
    const {purchase, refund} = usePOS();
    const [showFeedBack, setShowFeedBack] = useState(false);
    const dispatch = useDispatch();
    const cartData = useSelector(selectGetCartItems);

    const [items, setItems] = useState([]);
    const [dynamicValues, setDynamicValues] = useState({});

    // console.log(cartData, " cartData");
    const {clearWholeApp} = useClearApp();
    const handleClick = (e) => {
        e.preventDefault();
        dispatch(makeCartEmpty());
        setLanding(true);
        clearWholeApp({goBack: false});
        navigate("/");
    };
    const handleCloseTryAgain = () => {
        setShowFeedBack(false);
    };

    useEffect(() => {
        cartData?.forEach((item) => {
            if (item?.category === CATAGORIES.SNACKS) {
                setItemDisplay((prev) => {
                    return [...prev, CATAGORIES.SNACKS];
                });
            } else if (item?.category === CATAGORIES.COLD_DRINKS) {
                setItemDisplay((prev) => {
                    return [...prev, CATAGORIES.COLD_DRINKS];
                });
            } else if (item?.category === CATAGORIES.GRAB_AND_GO) {
                setItemDisplay((prev) => {
                    return [...prev, CATAGORIES.GRAB_AND_GO];
                });
            }
        });
    }, [cartData]);

    // Define a function to handle the inner logic
    const processChildItems = (mainItem, childsArr, quantity, price) => {
        const modifiresArr = childsArr
            ?.map((childItem) => {
                return extractItems(childItem);
            })
            ?.reduce((acc, modifiresObj) => {
                const modifires = Object?.keys(modifiresObj || {})?.map((key) => {
                    const extra = {...modifiresObj[key]};

                    return {
                        name: extra?.displayItemName,
                        price: extra?.itemCost,
                        quantity: 1,
                    };
                });
                return [...acc, ...modifires];
            }, []);

        return {mainItem, quantity, price, modifiers: modifiresArr};
    };

    useEffect(() => {
        const cartItems = cartData || [];
        let detail = {};
        // Use the function in the forEach loop
        cartItems.forEach((item) => {
            const childs = item?.childs;
            // const childsArr = getChildsArray(item || {});
            const mainItem = item?.displayName;
            const quantity = item?.quantity;
            const price = item?.total;
            const result = processChildItems(mainItem, [item], quantity, price);
            detail = {...result};
            if (Object.keys(childs || {}).length) {
                const childsArr = getChildsArray(childs || {});
                childsArr.map((child) => {
                    const mainItem = child?.displayName;
                    const result = processChildItems(mainItem, childsArr);
                    detail = {
                        ...detail,
                        childs: [...(detail?.childs || []), {...result}],
                    };
                });
            }
            setItems((prev) => {
                return [...prev, {...detail}];
            });
        });
        // setChildItems(list);
    }, [selectedProduct, cartData]);

    function generate_items(inputData) {
        const shift8Data = inputData;

        let surcharge = 0; // Initialize surcharge

        // Filter out the surcharge item and transform the rest
        const transformedItems = shift8Data.Items.filter((item) => {
            if (item.ItemName === "Surcharge") {
                surcharge = item.ItemIncTax; // Capture the surcharge value
                return false; // Exclude this item from the transformed list
            }
            return true;
        }).map((item) => {
            return {
                mainItem: item.ItemName,
                modifiers: item.ItemMods.map((mod) => ({
                    name: mod.ModName,
                    price: `$${mod.ModIncTax.toFixed(2)}`,
                    quantity: 1,
                })),
                quantity: 1,
                price: `$${item.ItemIncTax.toFixed(2)}`,
            };
        });

        return {
            SaleTotalIncTax: shift8Data.SaleTotalIncTax,
            SaleTotalTax: shift8Data.SaleTotalTax,
            SaleTotalExTax: shift8Data.SaleTotalExTax,
            items: transformedItems,
            surcharge: surcharge, // Surcharge value is separated
        };
    }

    useEffect(() => {
        // items to update from shift8 Basket
        if (shift8Response) {
            const receiptID = uuidv4().slice(-9);
            const result = generate_items(shift8Response);
            const SaleTotalIncTax = result.SaleTotalIncTax;
            const SaleTotalTax = result.SaleTotalTax;
            const SaleTotalExTax = result.SaleTotalIncTax;
            const surcharge = result.surcharge;
            const items = result.items;
            const posData = JSON.parse(localStorage.getItem("posData") || "{}");
            const {address, phoneNumber} = posData || {};

            setDynamicValues(() => {
                return {
                    cardNumber: formatCardNumber(txnResponse?.Pan || txnResponse?.pan),
                    receiptID: receiptID,
                    cardType: txnResponse?.CardType || txnResponse?.cardType,
                    approved: txnResponse?.ResponseCode || txnResponse?.responseCode,
                    authNo: `${txnResponse?.AuthCode || txnResponse?.authCode}`,
                    saleDate: getCurrentDateTime(),
                    aid: txnResponse?.Caid || txnResponse?.caid,

                    subTotal: SaleTotalExTax, //get this value from shift8
                    tax: SaleTotalTax, //get this value from shift8
                    kiosk_payment: SaleTotalIncTax, //get this value from shift8
                    address: address || "Hume Motorway, Pheasants Nest, NSW, 2574", //we need to get this from settings page
                    tel: phoneNumber || "0243538055",
                    surcharge,
                    items,
                    logoBase64: receiptLogo,
                };
            });
        }
    }, [txnResponse, shift8Response]);

    useEffect(() => {
        console.log(childItems, " childItems");
        // debugger;
    }, [childItems]);

    useEffect(() => {
        console.log(items, "...items...");
        // debugger;
    }, [items]);

    const renderMessage = () => {
        if (
            itemDisplay.includes(CATAGORIES.SNACKS) &&
            (itemDisplay.includes(CATAGORIES.COLD_DRINKS) ||
                itemDisplay.includes(CATAGORIES.GRAB_AND_GO))
        ) {
            return (
                <Receipt>
                    <h4>Please Collect</h4>
                    <h3 style={{fontSize: "3.6em"}}>From Retail && </h3>
                    <h1 style={{marginBottom: "1em"}}>Fridge Display</h1>
                </Receipt>
            );
        } else if (
            itemDisplay.includes(CATAGORIES.COLD_DRINKS) ||
            itemDisplay.includes(CATAGORIES.GRAB_AND_GO)
        ) {
            return (
                <Receipt>
                    <h4 style={{fontSize: "3em"}}>Please Collect</h4>
                    <h3 style={{fontSize: "3.4em"}}>From Display</h3>
                    <h1 style={{fontSize: "6.7em"}}>Fridge</h1>
                </Receipt>
            );
        } else if (itemDisplay.includes(CATAGORIES.SNACKS)) {
            return (
                <Receipt>
                    <h4>PLease Collect</h4>
                    <h3 style={{fontSize: "3.6em"}}>From Retail</h3>
                    <h1 style={{marginBottom: "1em"}}>Display</h1>
                </Receipt>
            );
        }
    };

    const displayOrderNumber = (orderCount) => {
        return orderCount.toString();
    };

    const [receiptText, setReciptText] = useState([]);
    useEffect(() => {
        setReciptText(
            txnResponse?.Receipts?.[0]?.receiptText ||
            txnResponse?.receipts?.[0]?.receiptText ||
            txnResponse?.receipts?.[0]?.ReceiptText ||
            txnResponse?.Receipts?.[0]?.ReceiptText
        );
    }, [txnResponse]);

    console.log(txnResponse, "...txnResponse...");
    console.log(receiptText, "...receiptText...");

    const moveToLandingPage = () => {
        clearWholeApp({goBack: false});
        setLanding(true);
        navigate("/");
    };

    const handleDone = (e) => {
        e.preventDefault();
        moveToLandingPage();
    };

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            moveToLandingPage();
        }, 1000 * 15);
        return () => {
            clearTimeout(timeoutID);
        };
    }, []);

    return (
        <>
            <Wrapper>
                <Logo/>
                <Heading>Order Complete</Heading>
                <div>
                    {showFeedBack ? (
                        <>
                            <OrderNumContainer>
                                <Receipt style={{marginBottom: "6em"}}>
                                    <h4 style={{fontSize: "4.2em"}}>Please Give Us</h4>
                                    <h3 style={{fontSize: "4.4em"}}>Your Feedback</h3>
                                </Receipt>
                                <QRCode style={{marginBottom: "6em"}}>
                                    <QRCodeContainer>
                                        {/* <ReceiptQRCode purchaseReceipt={{ love: "life" }} /> */}
                                        <Link onClick={handleClick}>
                                            <img
                                                style={{width: 250, height: 250}}
                                                className="imgstyle"
                                                draggable={false}
                                                src="images/feedback-qr.svg"
                                                alt=""
                                            />
                                        </Link>
                                    </QRCodeContainer>
                                </QRCode>
                                <Cancel onClick={handleDone}>Done</Cancel>
                            </OrderNumContainer>
                        </>
                    ) : (
                        <>
                            {/*  Retail */}
                            <OrderNumContainer>
                                <OrderNum>
                                    <Lottie options={defaultOptions} height={350} width={350}/>
                                    {/* <h1>Order Number</h1>
                  <h2>{displayOrderNumber(orderCount)}</h2> */}
                                </OrderNum>

                                {renderMessage()}
                                <Receipt>
                                    <h4>Please Scan The</h4>
                                    <h3>QR Code For Your</h3>
                                    <h1>Receipt</h1>
                                </Receipt>

                                <QRCode>
                                    <QRCodeContainer>
                                        <Link to="#" onClick={handleClick}>
                                            <ReceiptComponent
                                                dynamicValues={dynamicValues}
                                                receipt={txnResponse}
                                            />
                                        </Link>
                                    </QRCodeContainer>
                                </QRCode>
                                <Cancel to="#" onClick={() => setShowFeedBack(true)}>
                                    Feedback
                                </Cancel>
                            </OrderNumContainer>
                        </>
                    )}
                </div>
            </Wrapper>
            {/* <Footer /> */}
        </>
    );
};

export default CompleteOrders;
