import React, {useEffect} from "react";

import {HeaderContainer} from "../payOrder/index.style";
import {useDispatch} from "react-redux";
import {setCartData} from "../../Redux/cartSlice";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useClearApp from "../../hooks/useClearApp";

const Logo = () => {
    const dispatch = useDispatch();
    const {clearWholeApp} = useClearApp();
    const location = useLocation();
    const navigate = useNavigate();
    console.log(location.pathname)
    useEffect(() => {
        dispatch(setCartData());
    }, []);

    const handleClearWholeApp = () => {
        if (location.pathname !== '/your_order') {
            clearWholeApp({goBack: false})
        } else {
            navigate("/")
        }
    }

    return (
        <HeaderContainer>
            <Link to="/" onClick={handleClearWholeApp}>
                <img draggable={false} src="/images/oliver-logo.PNG" alt=""/>{" "}
            </Link>
        </HeaderContainer>
    );
};

export default Logo;
