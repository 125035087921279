import React from "react";
import Logo from "../payOrder/Logo";
import { Wrapper } from "../payOrder/index.style";
import { Heading, QRCode, QRCodeContainer, ScanContainer } from "./index.style";
import { Link } from "react-router-dom";
import checkOptionsData from "./qrcode.json";
import Lottie from "react-lottie";
import { Cancel, NextBtn } from "common/index.style";
import styled from "styled-components";

const defaultOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: checkOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const MemberCard = () => {
  return (
    <>
      <Wrapper>
        <Logo />
        <Heading>Payment</Heading>
        <ScanContainer>
          <h1>Please scan your</h1>
          <h2>Member card to pay</h2>

          <QRCode>
            <QRCodeContainer>
              <Link to="/order_complete">
                <Lottie options={defaultOptions} />
              </Link>
            </QRCodeContainer>
          </QRCode>
          <Cancel to="/your_order">Cancel</Cancel>
        </ScanContainer>
      </Wrapper>
    </>
  );
};

export default MemberCard;
