import useData from "./useData";

const useDetailsData = () => {
    const {foodsArray} = useData()
    const haveDetails = (pluCode) => {
        return foodsArray?.some(food => {
            return food?.[
                'PLU#'] === Number(pluCode);
        }) || null;
    };


    const getCurrentItemIngredients = (pluCode) => {
        return foodsArray?.find(food => {
            return food?.[
                'PLU#'] === Number(pluCode);
        }) || {};
    };

    return {foodsArray, haveDetails, getCurrentItemIngredients};
};

export default useDetailsData;
