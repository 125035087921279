import React from "react";

// Function to extract unit from ingredinatsToShow using a regular expression
const extractUnit = (ingrediantToShow) => {
    let matches = ingrediantToShow?.match(/\b(g|grams|mg|cal|kj|ml|l)\b/gi);
    return matches ? matches[0].trim() : '';
};

// Function to check if the unit is one of the common units
const isCommonUnit = (unit) => {
    const commonUnits = ["g", "mg", "cal", "kj", "ml", "l"];
    return commonUnits.includes(unit.toLowerCase());
};

// Function to map full unit names to their abbreviations
const mapUnitAbbreviations = (unit) => {
    const unitAbbreviations = {
        grams: 'g',
        milligrams: 'mg',
        calories: 'cal',
        kilojoules: 'kj',
        milliliters: 'ml',
        liters: 'l',
    };
    return unitAbbreviations[unit.toLowerCase()] || unit;
};

// Function to check if the value has units and format it accordingly
const formatValueWithUnits = (value, unit, ingrediant) => {
    if (!value || value.toString().toLowerCase() === "n/a" || value.toString().trim() === "") {
        // If value is null, undefined, false, "N/A", or an empty string, remove both ingrediant and value
        return null;
    }
    if (value?.toString().toLowerCase().match(/g|mg|cal|kj|ml/gi)) {
        return (
            <p style={{width: "max-content"}}>
                <span>{ingrediant}: </span>
                <span>{value || ""}</span>
            </p>
        );
    }

    // Check if the unit is in the abbreviation map and append it to the value
    const formattedUnit = mapUnitAbbreviations(unit);

    // If the unit isn't in the value, add it
    if (!value?.toString()?.toLowerCase()?.includes(formattedUnit.toLowerCase())) {
        value += formattedUnit;
    }

    // Check if the defined unit is one of the common units and isn't in the value, add it
    if (isCommonUnit(formattedUnit) && !value?.toString()?.toLowerCase()?.includes(formattedUnit.toLowerCase())) {
        value += formattedUnit;
    }

    return (
        <p style={{width: "max-content"}}>
            <span>{ingrediant}: </span>
            <span>{value || ""}</span>
        </p>
    );
};

export default function Ingrediant({
                                       detail,
                                       productId,
                                       ingrediant,
                                       ingredinatsToShow,
                                   }) {
    let value = detail?.[ingredinatsToShow?.[ingrediant]];

    // If value is "N/A", make it an empty string
    if (value === "N/A") {
        value = "";
    }

    // Extract the unit from ingredinatsToShow using a regular expression
    const unit = extractUnit(ingredinatsToShow?.[ingrediant]);

    return formatValueWithUnits(value, unit, ingrediant);
}