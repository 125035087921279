import React from "react";
import {Container, Logo, Menu, MenuContainer} from "./AdminHeader.style";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import useNavigation from "hooks/useNavigation";
import AppVersionDisplay from "./AppVersionDisplay";

const AdminHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {handleLeaveAdmin} = useNavigation();
    // console.log(location.pathname);

    return (
        <>
            <Container>
                <Logo onClick={() => handleLeaveAdmin()}>
                    <img src="/images/oliver-logo.PNG" alt=""/>
                </Logo>
                <MenuContainer style={{marginTop: 10, padding: 28}}>
                    <Menu>
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                            <NavLink style={{flexBasis: '80%'}} className={
                                ["/admin/setting"].includes(location.pathname) ? "active" : ""
                            } to="/admin/settings">Settings</NavLink>
                            <span></span>
                            <NavLink
                                to="/admin/transactions"
                                className={
                                    ["/admin/transactions"].includes(location.pathname) ? "active" : ""
                                }
                            >
                                Transactions
                            </NavLink>
                            <span></span>
                            <NavLink className={
                                ["/admin/pos-data"].includes(location.pathname) ? "active" : ""
                            } to="/admin/pos-data">Pairing Status</NavLink>
                        </div>
                    </Menu>
                    <Menu>
                        <AppVersionDisplay/>
                    </Menu>
                </MenuContainer>
            </Container>
        </>
    )
        ;
};

export default AdminHeader;
