import { keysOf } from "../../modals/helper";
import {renderSizes} from "../../../dataService/dataService";

export const cacheImages = async (srcArray = []) => {
  if (srcArray?.length) {
    const loadingOverlay = document.getElementById("loading-overlay");
    const loadingBar = document.getElementById("loading-bar");
    let loadedCount = 0;

    const promises = srcArray.map((src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedCount++;
          const progress = (loadedCount / srcArray.length) * 100;
          loadingBar.style.width = `${progress}%`;
          resolve();
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${src}`);
          loadedCount++;
          const progress = (loadedCount / srcArray.length) * 100;
          loadingBar.style.width = `${progress}%`;
          resolve();
        };
      });
    });

    await Promise.all(promises); // Wait for all promises to resolve.

    // Hide the loading overlay once all images are loaded.
    loadingOverlay.style.display = "none";
  }
};

export const collectWholeAppImagse = (obj) => {
  const imagesArr = [];
  keysOf(obj).forEach((key) => {
    return obj[key].forEach((item) => {
      imagesArr.push(item?.imageSrc);
    });
  });
  return imagesArr;
};


/**
 * Return the scanned product data from the products data
 * @param {string} scanCode
 * @param {array[{}]} products
 * @return object
 * */
export const findScannedProdutFromProducts = (scanCode, products = []) => {

  return products.filter(product => {

    return product?.barcode_id === scanCode;
  });
}



