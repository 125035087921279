import React, {useEffect, useState} from "react";
import parse from "html-react-parser";

import Logo from "../payOrder/Logo";
import {Heading} from "../QrScanner/index.style";
import {Wrapper} from "../payOrder/index.style";
import {
    Body,
    DisclamerContext,
    Fill,
    Footer,
    FooterContext,
    Header,
    Left,
    Right,
    Scale,
    ScalePoint,
    TopHeader,
} from "./index.style";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {getCurrentTimeFormat, roundThis} from "./helper";
import {BackBtn} from "../../common/index.style";
import {keysOf} from "utils/helper";
import useDetailsData from "hooks/useDetailsData";
import Ingrediant from "./components/Ingrediant";

const data = {
    servingPkg: "1",
    Servingsize: " 310g",
    Energy: "586Cal",
    Protein: "28.7g",
    FatTotal: "32.5g",
    Saturated: "9.4g",
    Carbohydrate: "41.7g",
    Sugars: "6.3g",
    Sodium: "889mg",
};

const ProductDetails = () => {
    const {productId} = useParams();
    const {foodsArray, getCurrentItemIngredients} = useDetailsData();
    const [roundedPercentage, setRoundedPercentage] = useState(0);
    const [actualPercentage, setActualPercentage] = useState(0);
    const {selectedProduct} = useSelector((state) => state?.products);

    const {imageSrc, itemName} = selectedProduct;
    const navigate = useNavigate();

    const getPercentage = (detail) => {
        const percentage = detail?.["Made in Australia %"];

        if (percentage === "error") {
            return 0;
        }

        const roundBy = roundThis(percentage);
        if (percentage > 10) {
            return roundBy(10);
        } else if (percentage < 10) {
            return roundBy(5);
        } else if (percentage > 95) {
            return roundBy(5);
        }

        return percentage;

        // return (
        //   (detail?.["Made in Australia %"] &&
        //     detail?.["Made in Australia %"] !== "error" &&
        //     detail?.["Made in Australia %"]) ||
        //   0
        // );
    };

    const getActualPercentage = (detail) => {
        return detail?.["Made in Australia %"];
    };

    const getName = (productId) => {
        const name = getCurrentItemIngredients(productId)?.Name;
        if (name) {
            return <Heading>{name}</Heading>;
        }
    };

    const getIngredients = (productId) => {
        const {Ingredients = "", Allergies = ""} = getCurrentItemIngredients(productId);
        let containsArray = Allergies.replace('Contains:', '').replace(/\s/g, '').split(',');

        if (Ingredients) {
            let formattedIngredients = Ingredients;
            containsArray.forEach((item) => {
                const re = new RegExp(item, "gi");
                formattedIngredients = formattedIngredients.replace(re, `<strong>${item}</strong>`);
            });
            return <p dangerouslySetInnerHTML={{__html: formattedIngredients}}></p>;
        }
    };

    const getAllergies = (productId) => {
        const detail = getCurrentItemIngredients(productId);
        const ingredinatsToShow = {
            "Serving per package": "Serving per package",
            "Serving size in grams": "Serving size in grams",
            Energy: "Energy (per serving) in KJ",
            "Fat Total": "Fat Total (per serving) in grams",
            Saturated: "Saturated Fat (per serving) in grams",
            Carbohydrate: "Carbohydrate (per serving) in grams",
            Sugars: "Sugar (per serving) in grams",
            Protein: "Protein (per serving) in grams",
            Fiber: "Fiber (per serving) in grams",
            Sodium: "Salt (per serving) in mg",
        };

        return (
            <tr>
                <td>
                    {keysOf(ingredinatsToShow).map((ingrediant) => (
                        <Ingrediant
                            {...{detail, productId, ingrediant, ingredinatsToShow}}
                        />
                    ))}
                </td>
            </tr>
        );
    };

    const getAlergies = (productId) => {
        // const alergies = detail?.[productId]?.Allergies;
        const alergies = getCurrentItemIngredients(productId)?.Allergies;

        if (alergies) {
            return <h3>{parse(alergies)}</h3>;
        }
    };


    useEffect(() => {
        setRoundedPercentage(getPercentage(getCurrentItemIngredients(productId)));
        setActualPercentage(getActualPercentage(getCurrentItemIngredients(productId)));
    }, [foodsArray]);

    /** Storing the state is info page opened */
    useEffect(() => {
        localStorage.setItem("fromInfo", true);
    }, []);

    const {formattedDateTime} = getCurrentTimeFormat();

    return (
        <>
            <Wrapper style={{padding: "2em 3em"}}>
                <TopHeader>
                    <Logo/>
                    <span onClick={() => navigate(-1)}>
            <BackBtn>Back</BackBtn>
          </span>
                </TopHeader>
                {getName(productId)}
                <Header>
                    <Left>
                        <table>{getAllergies(productId)}</table>
                    </Left>
                    <Right>
                        <img
                            draggable={false}
                            className="proImgSty"
                            src={imageSrc}
                            alt=""
                        />
                    </Right>
                </Header>
                <Body>
                    <h3>Ingredients</h3>
                    {getIngredients(productId)}
                </Body>
                <Footer>
                    {getAlergies(productId)}
                    <FooterContext>
                        <div className="Left">
                            <img
                                draggable={false}
                                className="mapimgstyle"
                                src="/images/full-i.png"
                                alt=""
                            />
                            <Scale>
                                <Fill percentage={roundedPercentage || 0}/>
                                <ScalePoint distance="20"/>
                                <ScalePoint distance="40"/>
                                <ScalePoint distance="60"/>
                                <ScalePoint distance="80"/>
                            </Scale>
                        </div>

                        <div className="Right">
                            <h1>Made in Australia from at least</h1>
                            <h1>{actualPercentage}% Australian ingredients</h1>
                        </div>
                    </FooterContext>
                    <DisclamerContext>
                        <p>
                            {`DISCLAIMER: The allergen information is based on our supplier's ingredients list as at ${formattedDateTime}. These allergen details reflect allergens contained in the product only.
                                Other allergens may be present in the facilities where we produce, prepare and sell food.
                                Although we take precautions to prevent cross contamination, we cannot guarantee that these
                                products are free from allergens. Please be aware that there is always a risk that traces of
                                allergens may be transferred to ingredients during storage or preparation of our products.
                                Also be aware that our suppliers may change their ingredients without notice. We cannot
                                assume any liability for adverse reactions to foods consumed, or items you may come into
                                contact with. The above nutritional information is based on average figures and standard
                                product formulations. Actual serving sizes and nutrient values may vary due to individual
                                preparation of each recipe and regional and seasonal differences in ingredients. If you have
                                any questions or require further information, please contact us.`}
                        </p>
                    </DisclamerContext>
                </Footer>
            </Wrapper>
        </>
    );
};

export default ProductDetails;
