import React from "react";
import { Status } from "./index.style";

export default function LocationStatus({ status }) {
  return (
    <div>
      <Status status={status}>
        <span />
        <p>{(status && "Online") || "Offline"}</p>
      </Status>
    </div>
  );
}
