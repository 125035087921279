import React, { createContext, useState } from "react";

// Create a context to manage the loaded state
export const LoadedContext = createContext();

function LoadedProvider({ children }) {
  const [loaded, setLoaded] = useState(true);
  const [landing, setLanding] = useState(false);

  return (
    <LoadedContext.Provider value={{ loaded, setLoaded, landing, setLanding }}>
      {children}
    </LoadedContext.Provider>
  );
}

export default LoadedProvider;
