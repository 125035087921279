import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 1em 2em 0;
`;
export const HeadTxtCont = styled.div`
  padding: 1rem;
  margin-top: 1em;
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: center;
  p {
    color: white;
    font-size: 2.5em;
    font-weight: 600;
    margin: 0px !important;
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    width: 28px;
    height: 28px;
    background-color: ${(props) => (props.status ? "green" : "red")} !important;
    border-radius: 50%;
  }
  p {
    color: white;
    font-size: 2em;
    font-weight: 600;
    margin: 0px !important;
  }
`;

export const Location = styled.div`
  padding: 1rem;
`;
export const Title = styled.div`
  color: #3ca3dc !important;
  margin-top: 0.52em;
  font-size: 3em;
  font-weight: 600;
`;


export const ToggleDiv = styled.div`
    position: relative;
    padding: 10px;
    width: 150px;
    height: 75px;
    border-radius: 50px;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, .5), 0px 1px 1px 0 rgba(255, 255, 255, 1);
    background: rgb(189, 191, 193);
    background: linear-gradient(to bottom, rgba(189, 191, 193, 1) 0%, rgba(249, 251, 254, 1) 100%);
`

export const ToggleInput = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  top: -3px;
  left: 17px;
  width: 130px;
  height: 55px;
  z-index: 5;

  ~ div {
    width: 130px;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #979797;
    box-shadow: inset 5px 0 0 0 rgba(0, 0, 0, .1), inset -5px 0 0 0 rgba(0, 0, 0, .1), inset -6px 0 0 0 rgba(255, 255, 255, .5), inset -4px 6px 11px -5px rgba(0, 0, 0, .2), inset 1px 1px 0 0 rgba(255, 255, 255, 1), -6px 4px 11px -7px rgba(0, 0, 0, .5);
    background: rgb(253, 252, 251);
    background: linear-gradient(to right, rgba(253, 252, 251, 1) 37%, rgba(229, 229, 229, 1) 61%);

    :before {
      content: attr(data-unchecked);
      display: block;
      position: absolute;
      border-radius: 10px;
      top: 27px;
      left: 30px;
    }

    :after {
      content: attr(data-checked);
      color: #df0000;
      text-shadow: 0 1px 0 #fff, 0px 0 7px #df0000;
      filter: dropshadow(color=#df0000, offx=0, offy=0);
      display: block;
      position: absolute;
      border-radius: 10px;
      top: 27px;
      left: 100px;
    }
  }

  &:checked {
    ~ div {
      box-shadow: inset -5px 0 0 0 rgba(0, 0, 0, .1), inset 5px 0 0 0 rgba(0, 0, 0, .1), inset 6px 0 0 0 rgba(255, 255, 255, .5), inset -4px 6px 11px -5px rgba(0, 0, 0, .2), inset 1px 1px 0 0 rgba(255, 255, 255, 1), -6px 4px 11px -7px rgba(0, 0, 0, .5);
      background: rgb(229, 229, 229);
      background: linear-gradient(to right, rgba(229, 229, 229, 1) 39%, rgba(253, 252, 251, 1) 63%);

      :before {
        color: #119400;
        text-shadow: 0 1px 0 #fff, 0px 0 7px #007a08;
        filter: dropshadow(color=#007a08, offx=0, offy=0);
      }
  
      :after {
        color: #000;
        text-shadow: none;
        filter: dropshadow(none);
      }
    }
  }
`


export const InputContainer = styled.div`
  padding-top: 1rem;
  p {
    font-size: 2em;
    color: white !important;
  }

  input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    font-size: 2em;
    height: 80px;
    padding-left: 10px;
  }
`;
export const Shift8 = styled.div`
  padding: 1rem;
  p {
    color: white !important;
  }

  input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    padding-left: 10px;
  }
`;
export const Linkly = styled.div`
  padding: 1rem;
  p {
    color: white !important;
  }

  input {
    width: 100%;
    border-radius: 50px;
    outline: none;
    padding-left: 10px;
  }
`;

export const BtnContainer = styled.div`
  padding: 1rem;
`;

export const SubmitBtn = styled.button`
  padding: 0.25rem;
  border-radius: 50px;
  width: 100%;
  background-color: #3ca3dc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
  font-weight: 600;
  color: white !important;
  margin-top: 2em;
  height: 100px;
`;
