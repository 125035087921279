import axios from "axios";

/** Download WHOLE App data via api instead of using JSON file. */
export const fetchJsonData = () => {
    // TODO: Used the API for fetching data instead of Local File.
    return axios.get(process.env.REACT_APP_JSON_DATA_API);
    // return new Promise((resolve) => {
    //     resolve({data: oliversData})
    // });
};
export const fetchIngrediantsJsonData = () => {
    return axios.get(process.env.REACT_APP_INGREDIANT_DATA_API)
}

/** Linkly Error recovery */
export const getlinklyStatus = (linklyEndpoint, sessionId, authToken) => {
    const apiUrl = `${linklyEndpoint}${sessionId}/transaction?async=false`;
    return axios.get(apiUrl, {
        headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
        },
    });
};

/** Generate QRCODE
 * @param{String} base64;
 * @returns {Image};
 */

export const generateQRCodeImage = (base64) => {
    return axios.post(
        // "https://QR-CODE-receipt-1.IbrahimJan.repl.co/upload-receipt",
        `${process.env.REACT_APP_BASE_URL}/receipt/upload`,
        {
            imageData: base64,
        },
        {
            headers: {
                "Content-Type": "application/json",
            },
        }
    );
};

/** Check the current POS status via location ID
 * @param {Array} locations
 * @returns {Promise}
 */
export const checkPosStatus = async (locations) => {
    try {
        // Use await to wait for the asynchronous axios.post to complete
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/shift8/check-pos`,
            {
                locationNumbers: locations,
            }
        );

        // Return the response data (or any other part of the response you need)
        return response.data;
    } catch (error) {
        // Handle errors here or propagate them by rethrowing
        console.error("Error in checkPosStatus:", error);
        throw error; // Rethrow the error to propagate it to the caller
    }
};
