import React, {createContext, useEffect, useState} from "react";
import {selectGetSelectedCatagory} from "Redux/productSlice";
import {useSelector} from "react-redux";
import {is, keysOf} from "utils/helper";

import Products from "assets/data/products";
// import productsArr from "assets/data/olivers_data";
import {fetchIngrediantsJsonData, fetchJsonData} from "services/apis";

export const InitialDataContext = createContext(null);

const InitialDataContextDataProvider = ({children}) => {
    const [productsArr, setProductsArr] = useState([]);
    const [active, setActive] = useState(0);
    const [selectedCatagory, setSelectedCatagory] = useState("");
    const [catagorisData, setCatagoriesData] = useState({});
    const [menuData, setManuData] = useState([]);
    const [productsByCatagory, setProductsByCatagory] = useState([]);
    const [formatedMenuData, setFormatMenuData] = useState([]);
    const [popularData, setPopularData] = useState([]);

    // Ingredients
    const [foodsArray, setFoodsArray] = useState([]);

    const {name = "", key = null} = useSelector(selectGetSelectedCatagory);

    useEffect(() => {
        (async () => {
            const data = await fetchJsonData();
            setProductsArr(() => data?.data);
        })();
    }, []);

    // Ingredients array
    useEffect(() => {
        if (!foodsArray.length) {
            (async () => {
                const oliversFoodIngrediants = await fetchIngrediantsJsonData();
                setFoodsArray(oliversFoodIngrediants.data);
            })();
        }
    }, []);



    useEffect(() => {
        setSelectedCatagory(name);
        if (key) {
            setActive(key);
        }
    }, [name, key]);

    useEffect(() => {
        setCatagoriesData(getCatagories());
    }, [menuData]);

    useEffect(() => {
        setManuData(productsArr);
    }, [productsArr]);

    useEffect(() => {
        setProductsByCatagory(Products?.menu_data);
        console.log(Products, "ppp")
    }, []);

    useEffect(() => {
        setFormatMenuData(catagorisData[selectedCatagory]);
    }, [catagorisData, selectedCatagory]);
    //   Top list of catagories where we select catagory like: cofee & hot drink or Smoothess Juices.
    const catagories = () => {
        return productsByCatagory?.reduce((acc, current) => {
            const {name, image_url} = current;
            if (name) {
                acc.push({name, image_url});
            }
            return acc;
        }, []);
    };

    function getCatagories() {
        const categoryData = menuData.reduce((acc, current, index) => {
            // debugger
            const category = current?.category;
            // debugger
            if (category) {
                // debugger
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push({
                    ...current,
                    _id: index + 20 * 20,
                    cost: current?.itemSize[0]?.cost,
                });
            }
            // debugger
            return acc;
        }, {});
        // debugger
        return categoryData;
    }

    /** Seperate data from the list in which there is propert popular true
     * @param {Object} - catagories
     * @returns {Array}
     */
    const getPopularItems = (catagories = {}) => {
        let items = [];
        keysOf(catagories).forEach((key) => {
            const res = catagories[key].filter((item) => {
                return item?.productType === "popular";
            });
            items = [...items, ...res];
        });
        return items;
    };

    useEffect(() => {
        if (is(catagorisData)) {
            setPopularData(() => getPopularItems(catagorisData));
        }
    }, [catagorisData]);
    // console.log(catagories(), "AllCatagories...");
    console.log(popularData, " popular data...");
    console.log(formatedMenuData, "formatedMenudata");
    console.log(catagorisData, " catagorisData ")
    console.log(catagories(), "cccc")

    return (
        <InitialDataContext.Provider
            value={{
                catagories,
                getCatagories,
                setActive,
                active,
                formatedMenuData,
                menuData,
                catagorisData,
                popularData,
                selectedCatagory,
                foodsArray,
            }}
        >
            {children}
        </InitialDataContext.Provider>
    );
};

export default InitialDataContextDataProvider;
