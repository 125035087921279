import styled from "styled-components";

export const TxtWraper = styled("div")`
  display: flex;
  height: 70vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 4em;
    margin-bottom: 0;
    font-family: "Helvetica-bold";
    line-height: 1;
  }
  h2 {
    font-size: 4em;
    margin-bottom: 0em;
    font-family: "Helvetica-light";
    line-height: 1;
  }
  h3 {
    font-family: "Helvetica-bold";
    margin-bottom: 1em;
    font-size: 4em;
    font-family: "Helvetica-bold";
    line-height: 1;
  }

  .Cardimgstyle {
    width: 320px;
    height: 320px;
    margin-left: 45px;
    margin-top: 30px;
  }
  .payment-cards {
    text-align: center;
    svg {
      width: 600px !important;
      margin-left: 2em !important;
    }
  }
  .loadingIcon {
    max-width: 400px;
  }
  @media (max-width: 768px) {
    & {
      height: 75vh;
      h1 {
        font-size: 1.6em;
      }
      h2 {
        font-size: 1.6em;
      }
      h3 {
        font-size: 1.6em;
        margin-bottom: 0.5em;
      }
      .loadingIcon {
        max-width: 150px;
      }
      .payment-cards {
        div {
          svg {
            width: 250px !important;
            height: 300px !important;
            margin-left: 2em !important;
          }
        }
      }
    }
  }
`;
