import React, { useState } from "react";
import { CardContainer } from "../../common/index.style";

const MenuItem = ({ headingName, imageUrl, clickTab, activeKey }) => {
  return (
    <>
      <CardContainer
        className={clickTab == true || activeKey > 0 ? `` : `cardItems`}
      >
        <div>
          <img draggable={false} src={imageUrl} alt="" />
        </div>
        <h6>{headingName}</h6>
      </CardContainer>
    </>
  );
};

export default MenuItem;
