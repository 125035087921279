import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProduct: {},
  isFromOrderedList: false,
  indexOfEditedProduct: null,
  selectedCatagory: { name: "", key: null },
  innerMeal: {},
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setSelectedProduct(state, { payload }) {
      state.selectedProduct = { ...payload };
    },
    saveSelectedCatagory(state, { payload }) {
      const { catagory = "", key = null } = payload;
      state.selectedCatagory = { name: catagory, key };
    },
    saveInnerMeal(state, { payload }) {
      const { name, editing } = payload;
      state.innerMeal = { name, editing };
    },
    clearInnerMeal(state) {
      state.innerMeal = {};
    },
    setEditedProductFromOrderList(state, { payload }) {
      const { index } = payload;
      state.isFromOrderedList = true;
      state.indexOfEditedProduct = index;
    },
    updateSelectedProduct(state, { payload }) {
      state.selectedProduct = {
        ...payload,
        total: Number(payload?.cost) * payload?.quantity
      };
    },
    removeSelectedProduct(state) {
      state.selectedProduct = {};
    },
    clearSelectedProducts(state) {
      state.selectedProduct = {};
      state.isFromOrderedList = false;
      state.indexOfEditedProduct = null;
    },
  }
});

export const selectGetSelectedProduct = (state) => state.products.selectedProduct;
export const selectIsItemFromOrderedList = (state) => state.products.isFromOrderedList;
export const selectEditedProductIndex = (state) => state.products.indexOfEditedProduct;
export const selectGetSelectedCatagory = (state) => state.products.selectedCatagory;
export const selectGetInnerMeal = (state) => state.products.innerMeal;

export default productSlice.reducer;
export const {
  setSelectedProduct,
  updateSelectedProduct,
  removeSelectedProduct,
  setEditedProductFromOrderList,
  clearSelectedProducts,
  saveSelectedCatagory,
  saveInnerMeal,
  clearInnerMeal,
} = productSlice.actions;