import React, { useContext, useEffect, useState } from "react";
import { Cancel, Wrapper } from "../../common/index.style";
import Logo from "../payOrder/Logo";
import {
  Heading,
  OrderNum,
  OrderNumContainer,
  QRCode,
  QRCodeContainer,
  Receipt,
} from "./index.style";
import checkOptionsData from "./cancel.json";
import Lottie from "react-lottie";
// import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { makeCartEmpty, selectGetCartItems } from "../../Redux/cartSlice";
// import { clearSelectedProducts } from "../../Redux/productSlice";
// import { LoadedContext } from "context/LoadedContext";
// import { CATAGORIES } from "constants/CATAGORIES";
// import { generateCartData } from "screens/yourOrder/helper";
// import axios from "axios";
// import { Col, Row } from "antd";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: checkOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const InCompleteOrders = () => {
  return (
    <>
      <Wrapper id="orderIncomplete">
        <Logo />
        <Heading>Order Incomplete</Heading>
        <div>
          <>
            <OrderNumContainer>
              <OrderNum>
                <Lottie options={defaultOptions} height={350} width={350} />
              </OrderNum>

              <Receipt style={{ marginBottom: "2em" }}>
                <h4>The Kitchen Can't</h4>
                <h3>Take Orders At</h3>
                <h1>The Moment</h1>
              </Receipt>
            </OrderNumContainer>
          </>
        </div>
      </Wrapper>
    </>
  );
};

export default InCompleteOrders;
