import React from "react";
import {
  BtnContainer,
  Container,
  HeadTxtCont,
  InputContainer,
  Linkly,
  Location,
  Shift8,
  Status,
  SubmitBtn,
  Title,
  UserText,
  Wrapper,
} from "./index.style";
import AdminHeader from "../components/AdminHeader";

const PairData = () => {
  return (
    <>
      <Wrapper>
        <Container>
          <HeadTxtCont>
            <p>POS & FPOS Paring</p>
            <Status>
              <span></span>
              <p>Offline</p>
            </Status>
          </HeadTxtCont>
          <Location>
            <Title>Location</Title>
            <UserText>Hexham - Online</UserText>
          </Location>
          <Shift8>
            <Title>Shift 8</Title>
            <UserText>Shift 8 POS ID: 1 - Online</UserText>
            <UserText>Shift 8 POS ID: 2 - Online</UserText>
          </Shift8>
          <Linkly>
            <Title>Linkly</Title>
            <UserText>Pinpad - Connected</UserText>
          </Linkly>
          <BtnContainer>
            <SubmitBtn>Unpair</SubmitBtn>
          </BtnContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default PairData;
