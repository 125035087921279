import React from "react";
import {
  OrderNumContainer,
  QRCode,
  QRCodeContainer,
  Receipt,
} from "../completeOrders/index.style";
import { Link } from "react-router-dom";
import { Cancel } from "common/index.style";
import { Col, Row } from "antd";
import cancelOptionsData from "./cancel.json";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: cancelOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Declined = ({ showPayment, cancelOrder }) => {
  return (
    <>
      <OrderNumContainer>
        <Receipt>
          <h4>Sorry Order</h4>
          <h3 style={{ marginBottom: "2em", fontSize: "5.1em" }}>Declined</h3>
        </Receipt>
        <QRCode>
          {/* <img
            className="imgstyle"
            draggable={false}
            src="images/close.PNG"
            alt=""
          /> */}
          <Lottie options={defaultOptions} height={350} width={350} />
        </QRCode>
        <Row gutter={16}>
          <Col span={12}>
            <Cancel to="/" onClick={cancelOrder}>
              Cancel
            </Cancel>
          </Col>
          <Col span={12}>
            <Cancel
              to="/instructions"
              onClick={showPayment}
              style={{ background: "#518E24", color: "#ffffff" }}
            >
              Try Again
            </Cancel>
          </Col>
        </Row>
      </OrderNumContainer>
    </>
  );
};

export default Declined;
