import React, {useEffect, useState} from "react";
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";
import MenuItem from "../cards/MenuItem";
import {CancelBtn, CardStyle, LeftArrow, ScrollArrow, ScrollItems, TabContainer,} from "./index.style";
import CardItems from "../../common/products";
import PopularItems from "../../components/popular";

import {saveSelectedCatagory} from "../../Redux/productSlice";
import useData from "hooks/useData";
import {useDispatch} from "react-redux";
import CancelOrderModal from "components/modals/orderCancel";
import useClearApp from "hooks/useClearApp";
import {not} from "utils/helper";
import ScanProduct from "./components/ScanProduct";
// import ScanProduct from "./components/ScanProduct";

const SelectTab = ({count, setCount, listItems, setListItems}) => {
    const dispatch = useDispatch();
    const {clearWholeApp} = useClearApp();
    const [clickTab, setClickTab] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [scrolling, setScrolling] = useState(false);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [hideRightArrow, setHideRightArrow] = useState(false);

    const {
        catagories,
        setActive,
        active,
        selectedCatagory,
        formatedMenuData,
        popularData,
    } = useData();
    console.log(catagories(), "catagoriescatagories")
    const onChange = (key) => {
        setActive(key);
    };

    const showCancelOrder = () => {
        setCancelOrder(true);
    };
    const handleCancelOrder = () => {
        clearWholeApp({goBack: false});
        setCancelOrder(false);
    };

    const transform = (container, unit = 0, viaScroll) => {
        if (not(viaScroll)) {
            container.style.transform = `translateX(${unit}px)`;
        }
    };

    function scrollToLeft(viaScroll) {
        const container = document.querySelector(".ant-tabs-nav-list");
        if (container) {
            const currentTransform = getComputedStyle(container).transform;
            const currentX = parseInt(currentTransform.split(",")[4]);
            const newX = currentX + 400; // Adjust the scroll amount as needed
            if (newX <= 0) {
                if (newX > -200) {
                    transform(container, 0, viaScroll);
                    setHideLeftArrow(true);
                    setScrolling(false);
                } else {
                    transform(container, newX, viaScroll);
                }
                setHideRightArrow(false);
            } else {
                setHideLeftArrow(true);
                setScrolling(false);
            }
        }
    }

    function scrollToRight(viaScroll) {
        const container = document.querySelector(".ant-tabs-nav-list");
        if (container) {
            const currentTransform = getComputedStyle(container).transform;
            const currentX = parseInt(currentTransform.split(",")[4]);
            const newX = currentX - 400; // Adjust the scroll amount as needed
            if (newX > -1335) {
                if (-1335 - newX > -200) {
                    transform(container, -1335, viaScroll);
                    setHideRightArrow(true);
                } else {
                    transform(container, newX, viaScroll);
                    setScrolling(true);
                }
                setHideLeftArrow(false);
            } else {
                setHideRightArrow(true);
            }
        }
    }

    const handleOnScroll = ({direction, scroll}) => {
        if (direction === "left") {
            scrollToLeft(scroll);
        } else {
            scrollToRight(scroll);
        }
        // setScrolling(direction);
    };
    useEffect(() => {
        console.log(selectedCatagory, "selectedCatagory");
        console.log(popularData, 'popularData');
        console.log(formatedMenuData, 'formatedMenuData');
    });
    return (
        <>
            {/* Enable scanner on the main page of the app to scan the product and add to cart */}
            <ScanProduct />
            <CancelOrderModal
                visible={cancelOrder}
                setVisible={setCancelOrder}
                handleCancelOrder={handleCancelOrder}
            />
            <CancelBtn onClick={showCancelOrder}>Cancel Order</CancelBtn>
            <div>
                <ScrollItems>
                    {not(hideRightArrow) && (
                        <ScrollArrow
                            onClick={() =>
                                handleOnScroll({direction: "right", scrolle: false})
                            }
                        >
                            <HiOutlineChevronRight/>
                        </ScrollArrow>
                    )}
                    {not(hideLeftArrow) && (
                        <LeftArrow
                            onClick={() =>
                                handleOnScroll({direction: "left", scroll: false})
                            }
                        >
                            <HiOutlineChevronLeft/>
                        </LeftArrow>
                    )}
                </ScrollItems>
                <TabContainer
                    scrolling={scrolling}
                    onTabScroll={(e) => {
                        handleOnScroll({...e, scroll: true});
                    }}
                    activeKey={active}
                    items={catagories()?.map((item, i) => {

                        return {
                            label: (
                                <CardStyle
                                    onClick={() => {
                                        dispatch(
                                            saveSelectedCatagory({
                                                catagory: item?.name,
                                                key: i + 1,
                                            })
                                        );
                                        setClickTab(true);
                                    }}
                                >
                                    <MenuItem
                                        clickTab={clickTab}
                                        activeKey={active}
                                        headingName={item.name}
                                        imageUrl={item.image_url}
                                    />
                                </CardStyle>
                            ),
                            key: i + 1,
                        };
                    })}
                    onChange={onChange}
                />
            </div>
            {active === 0 ? (
                <PopularItems
                    setAllCount={setCount}
                    listItems={listItems}
                    setListItems={setListItems}
                    popularData={popularData}
                />
            ) : (
                <>
                    <CardItems
                        titleName={selectedCatagory}
                        setAllCount={setCount}
                        listItems={listItems}
                        formatedMenuData={formatedMenuData}
                        setListItems={setListItems}
                        parentId={selectedCatagory}
                    />
                </>
            )}
        </>
    );
};

export default SelectTab;
