import React, { useEffect, useState } from "react";
import {
  BackBtn,
  NextBtn,
  TopHeading,
  Wrapper,
} from "../../common/index.style";
import Footer from "../../components/footer";
import SelectOrder from "../../components/allItems/selectOrder";
import { HeaderContainer } from "../../components/header/index.style";
import { Col, Row } from "antd";
import {
  BtnsContent,
  CenteredContainer,
  CenteredText,
  NoOrderYet,
  SelectOrderItems,
  ServiceFee,
  ShowPayment,
} from "./index.style";
import { useSelector } from "react-redux";
import Logo from "../payOrder/Logo";
import {
  selectCalculateTotal,
  selectGetCartItems,
  selectGetTotal,
} from "../../Redux/cartSlice";
import {
  calGrandTotal,
  calServiceFeeByPercent,
  calSubTotal,
  generateCartData,
  getServiceChargePercentage,
} from "./helper";
import { applyFixed, getChildsArray } from "utils/helper";
import { CancelBtn } from "components/tabs/index.style";
import CancelOrderModal from "components/modals/orderCancel";
import { useNavigate } from "react-router-dom";
import useClearApp from "hooks/useClearApp";
import { selectGetSelectedProduct } from "Redux/productSlice";

const YourOrder = () => {
  const items = useSelector(selectGetCartItems);
  const total = useSelector(selectCalculateTotal);
  const { selectedProduct } = useSelector((state) => state?.products);
  const [childItemsList, setChildItemsList] = useState([]);
  useEffect(() => {
    if (selectedProduct?.childs) {
      setChildItemsList(getChildsArray(selectedProduct?.childs));
    }
  }, [selectedProduct]);
  const [cancelOrder, setCancelOrder] = useState(false);
  const { clearWholeApp } = useClearApp();
  // console.log(items, "CARTITEMS....");
  const showCancelOrder = () => {
    setCancelOrder(true);
  };
  const handleCancelOrder = () => {
    clearWholeApp({goBack: false});
    setCancelOrder(false);
  };
  console.log(items, "items....");

  return (
    <Wrapper>
      <Logo />
      <CancelOrderModal
        visible={cancelOrder}
        handleCancelOrder={handleCancelOrder}
        setVisible={setCancelOrder}
      />

      <div style={{ marginTop: "2em", display: "flex" }}>
        <TopHeading style={{ marginLeft: "0" }}>Your Order</TopHeading>
        <CancelBtn onClick={showCancelOrder}>Cancel Order</CancelBtn>
      </div>
      {items?.length === 0 ? (
        <CenteredContainer>
          <CenteredText>
            <h2>No Orders Yet</h2>
          </CenteredText>
        </CenteredContainer>
      ) : (
        <SelectOrderItems>
          {items?.map((item, index) => (
            <SelectOrder
              childItemsList={childItemsList}
              item={item}
              index={index}
            />
          ))}
        </SelectOrderItems>
      )}

      <ShowPayment>
        <ServiceFee>
          <div>
            <h4>Sub Total</h4>
            <div>${applyFixed(calSubTotal(total), 2)}</div>
          </div>
          <div>
            <h4>Surcharge</h4>
            <div>
              $
              {applyFixed(
                calServiceFeeByPercent(total, getServiceChargePercentage()),
                2
              )}
            </div>
          </div>
        </ServiceFee>
        <BtnsContent>
          <Row align="middle">
            <Col xs={{ span: 10 }}>
              <Row gutter={16}>
                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                  <strong>Total</strong>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }}>
                  <span className="totalCost">
                    $
                    {applyFixed(
                      calGrandTotal(
                        total,
                        calServiceFeeByPercent(
                          total,
                          getServiceChargePercentage()
                        )
                      ),
                      2
                    )}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: 14 }}>
              <Row justify="end" gutter={16}>
                <Col xs={{ span: 12 }} md={{ span: 8 }}>
                  <BackBtn style={{ marginLeft: "auto" }} to="/">
                    Back
                  </BackBtn>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 8 }}>
                  {!items?.length == 0 ? (
                    <NextBtn
                      // disabled={!items?.length}
                      style={{
                        marginLeft: "auto",
                        // pointerEvents: !items?.length ? "none" : "clickable",
                      }}
                      to="/pay_order"
                    >
                      Next
                    </NextBtn>
                  ) : (
                    <NextBtn
                      disabled={!items?.length}
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "#AAAAAA",
                        color: "#ffffff",
                        pointerEvents: !items?.length ? "none" : "clickable",
                      }}
                    >
                      Checkout
                    </NextBtn>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </BtnsContent>
      </ShowPayment>
      <Footer />
    </Wrapper>
  );
};

export default YourOrder;
