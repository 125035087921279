import React, { useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Cancel } from "common/index.style";
import { Col, Row } from "antd";
import cancelOptionsData from "screens/instructions/loading.json";
import Lottie from "react-lottie";
import {
  OrderNumContainer,
  QRCode,
  Receipt,
} from "screens/completeOrders/index.style";

import usePOS from "hooks/usePOS";

const defaultOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: cancelOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Signature = ({ setStartSignature }) => {
  const { sendKey, token, lastSessionId, setProcessing } = usePOS();

  const handleOk = (e) => {
    e.preventDefault();
    const authToken = token || localStorage.getItem("token");
    const sessionID = lastSessionId || localStorage.getItem("sessionID");
    setProcessing(true);
    sendKey(1, authToken, sessionID).then((res) => {
      setStartSignature(false);
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    const authToken = token || localStorage.getItem("token");
    const sessionID = lastSessionId || localStorage.getItem("sessionID");
    setProcessing(true);
    sendKey(2, authToken, sessionID).then((res) => {
      setStartSignature(false);
    });
  };

  return (
    <>
      <OrderNumContainer>
        <Receipt>
          <h4 style={{ fontSize: "4.5em" }}>Your Card</h4>
          <h3 style={{ fontSize: "4.4em" }}>Requires a</h3>
          <h4 style={{ marginBottom: "2em", fontSize: "4.5em" }}>Signature</h4>
        </Receipt>
        <QRCode>
          {/* <img
            className="imgstyle"
            draggable={false}
            src="images/close.PNG"
            alt=""
          /> */}
          <Lottie options={defaultOptions} height={350} width={350} />
        </QRCode>
        <p>Please press Ok to sign</p>
        <Row gutter={20}>
          <Col span={12}>
            <Cancel onClick={handleCancel} style={{ fontSize: "1.9em" }} to="/">
              Cancel
            </Cancel>
          </Col>
          <Col span={12}>
            <Cancel
              to="#"
              onClick={handleOk}
              style={{
                fontSize: "1.9em",
                background: "#518E24",
                color: "#ffffff",
              }}
            >
              Ok
            </Cancel>
          </Col>
        </Row>
      </OrderNumContainer>
    </>
  );
};

export default Signature;
