import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useShortcuts = () => {
  const navigate = useNavigate();
  const handleKeyPress = (key) => {
    const { shiftKey, ctrlKey, code } = key;
    if (shiftKey && ctrlKey && code == "KeyZ") {
      navigate("/admin/login");
    }
  };
  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
  }, []);
};

export default useShortcuts;
