import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SaucesContainer = styled(Modal)`
  width: 1000px !important;
  .ant-modal-content {
    background-color: #3b3b3b;
    width: 1000px;
    padding: 1em 1em 0;
    box-shadow: none;
    border-radius: 30px;
    text-align: center;
    font-weight: 700;
    .ant-modal-body {
      h1 {
        font-weight: 700;
        width: 100% !important;
      }
    }
  }
  .ant-modal-close {
    display: none;
  }

  @media (max-width: 768px) {
    & {
      .ant-modal-content {
        max-width: 350px;
        margin: auto;
        .ant-modal-body {
          h1 {
            font-size: 1.6em !important;
          }
        }
      }
    }
  }
`;

export const ModalContent = styled("div")`
  margin: 0em auto 1em;
  background-color: #3b3b3b;
  text-align: center;
  h1 {
    text-transform: capitalize;
    margin: 1em auto 0;
    font-size: 3.5em;
    color: #ffff !important;
  }
  p {
    margin: 1em auto 2em;
    color: #ffff;
    font-size: 1.7em;
    text-transform: capitalize;
  }
  a {
    margin: auto;
    width: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-family: "Helvetica-light";
    font-weight: 400;
    border-radius: 50px;
    font-size: 2.5em;
  }
  @media (max-width: 768px) {
    & {
      font-size: 0.8em;
      padding: 0.1em 0.5em;
    }
  }
`;
