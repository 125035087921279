import productData from "../../../assets/data/products";
import moment from "moment";

export const getChildObject = (child_id, isChild) => {
    const parent_id = Math.floor(Number(child_id));
    if (!isChild) {
        const parent = productData?.menu_data.find(
            (item) => item._id === parent_id
        );
        return parent;
    } else {
        const parent = productData?.menu_data.find(
            (item) => item._id === parent_id
        );
        if (parent) {
            const child = parent.key.find((item) => item._id === Number(child_id));
            return child;
        }
    }
    return null; // Parent not found
};

/**
 * Generate a function to round a value to the nearest multiple of a specified number.
 * @param {Number} value - The original value to be rounded.
 * @returns {Function} - A rounding function.
 * @example:
 *   const roundBy = roundThis(58);
 *   const roundedValue = roundBy(10); // Output: 50
 */
export function roundThis(value) {
    return function (roundTo) {
        return Math.floor(value / roundTo) * roundTo;
    };
}

// Get Current Time in spastic format like: Mar 15, 2024, 10:53 PM
export const getCurrentTimeFormat = () => {
    // Create a Moment.js object with the desired date and time
    const currentDateTime = moment();

    // Format the date and time object in the "Mar 15, 2024, 10:53 PM" format
    const formattedDateTime = currentDateTime.format('MMM D, YYYY, h:mm A');

    return {formattedDateTime};
}

