import { ItemDetailsCard } from "common/products/index.style";
import React, { memo } from "react";
import PropTypes from "prop-types";
import { formatPrice } from "utils/helper";

const ItemSize = ({ item, index, selectedSize }) => {
  return (
    <ItemDetailsCard
      key={index}
      className={item?.size === selectedSize?.size ? "active" : ""}
    >
      {/* {console.log(item, "imte...")} */}
      <div>
        <img draggable={false} src={item.imageSrc} alt="" />
      </div>
      <h5>{item?.size}</h5>
      <span>{formatPrice(item.cost)}</span>
    </ItemDetailsCard>
  );
};

ItemSize.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selectedSize: PropTypes.object,
};

export default memo(ItemSize);
