const productData = {
    menu_data: [
        {
            _id: 1,
            name: "Coffee & Hot Drinks",
            title: "Coffee & Hot Drinks",
            description: "A blend of fresh tropical fruits.",
            price: 4.99,
            image_url: "/images/coffee.PNG",
            key: [
                {
                    _id: 1.1,
                    name: "Babycino",
                    price: 4.99,
                    image_url: "/images/coffee.PNG",
                },
                {
                    _id: 1.2,
                    name: "Cappuccino",

                    price: 7.99,
                    image_url: "/images/chai.png",
                },
                {
                    _id: 1.3,
                    name: "Chai Latte",
                    price: 5.99,
                    image_url: "/images/earl-t.png",
                },
                {
                    _id: 1.4,
                    name: "Chai Tea",

                    price: 5.99,
                    image_url: "/images/coffee.png",
                },
                {
                    _id: 1.5,
                    name: "Dirty Chai",

                    price: 5.99,
                    image_url: "/images/earl-t.png",
                },
                {
                    _id: 1.6,
                    name: "Earl Grey Tea",
                    price: 5.99,
                    image_url: "/images/chai.png",
                },
                {
                    id: 1.7,
                    name: "Macchiato",
                    image_url: "https://example.com/images/macchiato.jpg",
                    price: 2.89,
                    description:
                        "An espresso with a small amount of milk or milk foam, often served as a 'stained' or 'marked' coffee.",
                },
                {
                    id: 1.8,
                    name: "Caramel Frappuccino",
                    image_url: "https://example.com/images/caramel_frappuccino.jpg",
                    price: 4.49,
                    description:
                        "A blended coffee beverage with espresso, milk, caramel syrup, and ice, often topped with whipped cream.",
                },
                {
                    id: 1.9,
                    name: "Turkish Coffee",
                    image_url: "https://example.com/images/turkish_coffee.jpg",
                    price: 3.79,
                    description:
                        "A traditional method of preparing coffee where finely ground coffee is simmered with water and sugar.",
                },
                {
                    id: 1.1,
                    name: "Iced Coffee",
                    image_url: "https://example.com/images/iced_coffee.jpg",
                    price: 2.99,
                    description:
                        "Regular coffee brewed hot and then chilled, often served over ice.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 2,
            name: "Smoothies & Juices",
            title: "Smoothies & Juices",
            description: "Melted cheese on a crispy thin crust.",
            price: 7.99,
            image_url: "/images/Smoothies-Juices.png",
            key: [
                {
                    _id: 2.1,
                    name: "Smoothies & Juices",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 2.2,
                    name: "Smoothies & Juices",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 2.3,
                    name: "Smoothies & Juices",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 2.4,
                    name: "Smoothies & Juices",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 2.5,
                    name: "Smoothies & Juices",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 2.6,
                    name: "Smoothies & Juices",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
                {
                    id: 2.7,
                    name: "Watermelon Cooler",
                    image_url: "https://example.com/images/watermelon_cooler.jpg",
                    price: 3.29,
                    description:
                        "Juice extracted from ripe watermelons, perfect for staying refreshed on a hot day.",
                },
                {
                    id: 2.8,
                    name: "Carrot-Orange Mix",
                    image_url: "https://example.com/images/carrot_orange_mix.jpg",
                    price: 3.59,
                    description:
                        "A combination of carrot and orange juices, offering a balance of sweetness and earthiness.",
                },
                {
                    id: 2.9,
                    name: "Cranberry Splash",
                    image_url: "https://example.com/images/cranberry_splash.jpg",
                    price: 2.79,
                    description:
                        "Tart and vibrant juice made from cranberries, popular for its unique taste.",
                },
                {
                    id: 2.1,
                    name: "Tropical Paradise",
                    image_url: "https://example.com/images/tropical_paradise.jpg",
                    price: 4.49,
                    description:
                        "A medley of tropical fruits like pineapple, mango, and papaya, evoking a paradise in every sip.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 3,
            name: "Breakfast",
            title: "Breakfast",
            description: "A hearty vegetable patty with fresh lettuce and tomato.",
            price: 5.99,
            image_url:
                "/images/breakfast.png",
            key: [
                {
                    _id: 3.1,
                    name: "Classic Pancakes",
                    image_url: "/images/burger.png",
                    price: 7.99,
                    description:
                        "A stack of fluffy pancakes served with maple syrup and butter, a breakfast favorite.",
                },
                {
                    _id: 3.2,
                    name: "Eggs Benedict",
                    image_url: "https://example.com/images/eggs_benedict.jpg",
                    price: 9.49,
                    description:
                        "Poached eggs on English muffins, topped with hollandaise sauce and ham.",
                },
                {
                    _id: 3.3,
                    name: "Vegetable Omelette",
                    image_url: "https://example.com/images/vegetable_omelette.jpg",
                    price: 8.29,
                    description:
                        "Fluffy omelette filled with a variety of sautéed vegetables, a nutritious option.",
                },
                {
                    _id: 3.4,
                    name: "Breakfast Burrito",
                    image_url: "https://example.com/images/breakfast_burrito.jpg",
                    price: 6.99,
                    description:
                        "Scrambled eggs, cheese, and your choice of fillings wrapped in a tortilla.",
                },
                {
                    _id: 3.5,
                    name: "Granola Parfait",
                    image_url: "https://example.com/images/granola_parfait.jpg",
                    price: 5.79,
                    description:
                        "Layers of yogurt, granola, and fresh fruits, a light and healthy breakfast option.",
                },
                {
                    _id: 3.6,
                    name: "Belgian Waffles",
                    image_url: "https://example.com/images/belgian_waffles.jpg",
                    price: 8.99,
                    description:
                        "Thick and fluffy waffles topped with whipped cream and your choice of toppings.",
                },
                {
                    _id: 3.7,
                    name: "Sausage and Biscuits",
                    image_url: "https://example.com/images/sausage_biscuits.jpg",
                    price: 7.49,
                    description:
                        "Savory sausage patties sandwiched between warm biscuits, a hearty morning meal.",
                },
                {
                    _id: 3.8,
                    name: "Greek Yogurt Bowl",
                    image_url: "https://example.com/images/greek_yogurt_bowl.jpg",
                    price: 6.29,
                    description:
                        "Creamy Greek yogurt topped with honey, nuts, and fresh berries.",
                },
                {
                    _id: 3.9,
                    name: "Breakfast Wrap",
                    image_url: "https://example.com/images/breakfast_wrap.jpg",
                    price: 5.99,
                    description:
                        "Scrambled eggs, cheese, and vegetables wrapped in a tortilla, perfect for on-the-go.",
                },
                {
                    _id: 3.1,
                    name: "Smoked Salmon Bagel",
                    image_url: "https://example.com/images/smoked_salmon_bagel.jpg",
                    price: 9.79,
                    description:
                        "Toasted bagel topped with cream cheese, smoked salmon, capers, and red onion slices.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 4,
            name: "Cold Drinks",
            title: "Drinks",
            description: "Rich chocolate ice cream topped with fudge sauce.",
            price: 3.49,
            image_url: "/images/Cold-Drinks.png",
            key: [
                {
                    _id: 4.1,
                    name: "Classic Lemonade",
                    image_url: "/images/can-drink.png",
                    price: 2.99,
                    description:
                        "Refreshing lemonade made from freshly squeezed lemons and a touch of sweetness.",
                },
                {
                    _id: 4.2,
                    name: "Iced Tea",
                    image_url: "/images/can-drink.png",
                    price: 1.99,
                    description:
                        "Chilled black tea served with ice and your choice of sweetener or lemon.",
                },
                {
                    _id: 4.3,
                    name: "Fruit Punch",
                    image_url: "/images/can-drink.png",
                    price: 3.49,
                    description:
                        "A vibrant blend of various fruit juices, a burst of fruity flavors.",
                },
                {
                    _id: 4.4,
                    name: "Mango Smoothie",
                    image_url: "https://example.com/images/mango_smoothie.jpg",
                    price: 4.29,
                    description:
                        "Creamy mango smoothie made with ripe mangoes, yogurt, and a touch of honey.",
                },
                {
                    _id: 4.5,
                    name: "Sparkling Water",
                    image_url: "https://example.com/images/sparkling_water.jpg",
                    price: 1.49,
                    description:
                        "Bubbly and refreshing sparkling water, perfect for quenching your thirst.",
                },
                {
                    _id: 4.6,
                    name: "Coconut Water",
                    image_url: "https://example.com/images/coconut_water.jpg",
                    price: 2.79,
                    description:
                        "Natural coconut water, packed with electrolytes and a hint of tropical flavor.",
                },
                {
                    _id: 4.7,
                    name: "Cucumber Mint Cooler",
                    image_url: "https://example.com/images/cucumber_mint_cooler.jpg",
                    price: 3.99,
                    description:
                        "Refreshing drink made with cucumber, mint, and a splash of lime, a cooling choice.",
                },
                {
                    _id: 4.8,
                    name: "Hot Chocolate",
                    image_url: "https://example.com/images/hot_chocolate.jpg",
                    price: 3.29,
                    description:
                        "Rich and velvety hot chocolate topped with whipped cream and chocolate shavings.",
                },
                {
                    _id: 4.9,
                    name: "Pomegranate Juice",
                    image_url: "https://example.com/images/pomegranate_juice.jpg",
                    price: 3.79,
                    description:
                        "Tangy and antioxidant-rich pomegranate juice, a delightful and healthy option.",
                },
                {
                    _id: 4.1,
                    name: "Café Latte",
                    image_url: "https://example.com/images/cafe_latte.jpg",
                    price: 4.49,
                    description:
                        "Espresso combined with steamed milk, a creamy and comforting coffee drink.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 5,
            name: "Lunch & Evening",
            title: "Lunch & Evening",
            description: "Perfectly grilled salmon with a lemon butter sauce.",
            price: 14.99,
            image_url: "/images/Lunch-Dinner.png",
            key: [
                {
                    _id: 5.1,
                    name: "Beef Burger",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/beef-b.png",
                },
                {
                    _id: 5.2,
                    name: "Peri Peri Chicken Burger",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/peri-b.png",
                },
                {
                    _id: 5.3,
                    name: "Oli Burger",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/oli-b.png",
                },
                {
                    _id: 5.4,
                    name: "Lamb Pocket",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/lamb-p.png",
                },
                {
                    _id: 5.5,
                    name: "Mexican Chicken Pocket",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/mexican-p.png",
                },
                {
                    _id: 5.6,
                    name: "Moroccan Veg Pocket",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/veg-p.png",
                },
                {
                    _id: 5.7,
                    name: "Margherita Pizza",
                    image_url: "/images/m-pizza.png",
                    price: 10.99,
                    description:
                        "Classic pizza topped with tomato sauce, mozzarella cheese, and fresh basil leaves.",
                },
                {
                    _id: 5.8,
                    name: "Pasta Primavera",
                    image_url: "https://example.com/images/pasta_primavera.jpg",
                    price: 10.29,
                    description:
                        "Colorful pasta dish with seasonal vegetables in a light olive oil and herb sauce.",
                },
                {
                    _id: 5.9,
                    name: "Veggie Burger",
                    image_url: "/images/breakfast.png",
                    price: 9.79,
                    description:
                        "Plant-based burger patty topped with lettuce, tomato, and your choice of condiments.",
                },
                {
                    _id: 5.1,
                    name: "Teriyaki Salmon",
                    image_url: "images/t-salmon.png",
                    price: 13.99,
                    description:
                        "Grilled salmon fillet glazed with teriyaki sauce, served with steamed rice and vegetables.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 6,
            name: "Grab and Go",
            title: "Grab & Go",
            description: "Perfectly grilled salmon with a lemon butter sauce.",
            price: 14.99,
            image_url: "/images/Grab-&-Go.png",
            key: [
                {
                    _id: 5.1,
                    name: "Beef Burger",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/beef-b.png",
                },
                {
                    _id: 5.2,
                    name: "Peri Peri Chicken Burger",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/peri-b.png",
                },
                {
                    _id: 5.3,
                    name: "Oli Burger",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/oli-b.png",
                },
                {
                    _id: 5.4,
                    name: "Lamb Pocket",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/lamb-p.png",
                },
                {
                    _id: 5.5,
                    name: "Mexican Chicken Pocket",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/mexican-p.png",
                },
                {
                    _id: 5.6,
                    name: "Moroccan Veg Pocket",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/veg-p.png",
                },
                {
                    _id: 5.7,
                    name: "Margherita Pizza",
                    image_url: "/images/m-pizza.png",
                    price: 10.99,
                    description:
                        "Classic pizza topped with tomato sauce, mozzarella cheese, and fresh basil leaves.",
                },
                {
                    _id: 5.8,
                    name: "Pasta Primavera",
                    image_url: "https://example.com/images/pasta_primavera.jpg",
                    price: 10.29,
                    description:
                        "Colorful pasta dish with seasonal vegetables in a light olive oil and herb sauce.",
                },
                {
                    _id: 5.9,
                    name: "Veggie Burger",
                    image_url: "/images/breakfast.png",
                    price: 9.79,
                    description:
                        "Plant-based burger patty topped with lettuce, tomato, and your choice of condiments.",
                },
                {
                    _id: 5.1,
                    name: "Teriyaki Salmon",
                    image_url: "images/t-salmon.png",
                    price: 13.99,
                    description:
                        "Grilled salmon fillet glazed with teriyaki sauce, served with steamed rice and vegetables.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 7,
            name: "Kids",
            title: "Kids",
            description: "Pasta with fresh seasonal vegetables.",
            price: 9.99,
            image_url: "/images/kids.png",
            key: [
                {
                    _id: 6.1,
                    name: "Mini Cheeseburgers",
                    image_url: "/images/kids.png",
                    price: 5.99,
                    description:
                        "Small cheeseburgers with melted cheese, lettuce, and ketchup, perfect for little appetites.",
                },
                {
                    _id: 6.2,
                    name: "Chicken Tenders",
                    image_url: "https://example.com/images/chicken_tenders.jpg",
                    price: 6.49,
                    description:
                        "Breaded and tender chicken strips, served with a dipping sauce.",
                },
                {
                    _id: 6.3,
                    name: "Mini Veggie Pizza",
                    image_url: "https://example.com/images/mini_veggie_pizza.jpg",
                    price: 4.79,
                    description:
                        "Small pizza topped with a variety of kid-friendly vegetables and cheese.",
                },
                {
                    _id: 6.4,
                    name: "Butterfly Pasta",
                    image_url: "https://example.com/images/butterfly_pasta.jpg",
                    price: 3.99,
                    description:
                        "Colorful butterfly-shaped pasta tossed in a light and tasty sauce.",
                },
                {
                    _id: 6.5,
                    name: "Ham and Cheese Roll-Ups",
                    image_url: "https://example.com/images/ham_cheese_rollups.jpg",
                    price: 4.29,
                    description:
                        "Slices of ham and cheese rolled up in tortillas, a fun and portable option.",
                },
                {
                    _id: 6.6,
                    name: "Cheesy Broccoli Bites",
                    image_url: "https://example.com/images/cheesy_broccoli_bites.jpg",
                    price: 3.79,
                    description:
                        "Bite-sized broccoli and cheese fritters, a delicious way to sneak in veggies.",
                },
                {
                    _id: 6.7,
                    name: "Fish Sticks",
                    image_url: "https://example.com/images/fish_sticks.jpg",
                    price: 5.49,
                    description:
                        "Crispy breaded fish sticks, served with tartar sauce or ketchup.",
                },
                {
                    _id: 6.8,
                    name: "Fruit Smoothie",
                    image_url: "https://example.com/images/fruit_smoothie.jpg",
                    price: 3.99,
                    description:
                        "Blended fruit smoothie made with yogurt and a variety of fruits, a nutritious choice.",
                },
                {
                    _id: 6.9,
                    name: "Mini Ham and Cheese Quiches",
                    image_url: "https://example.com/images/mini_quiches.jpg",
                    price: 4.99,
                    description:
                        "Small quiches filled with ham, cheese, and eggs, a mini savory delight.",
                },
                {
                    _id: 6.1,
                    name: "Rainbow Veggie Wraps",
                    image_url: "https://example.com/images/rainbow_veggie_wraps.jpg",
                    price: 4.49,
                    description:
                        "Colorful wraps filled with an assortment of colorful veggies, a playful and healthy option.",
                },
            ],
            size_data: [
                {
                    _id: 8.11,
                    name: "Large",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.12,
                    name: "Medium",
                    description: "Melted cheese on a crispy thin crust.",
                    price: 7.99,
                    image_url: "/images/milk.png",
                },
                {
                    _id: 8.13,
                    name: "Small",
                    description:
                        "A hearty vegetable patty with fresh lettuce and tomato.",
                    price: 5.99,
                    image_url: "/images/milk.png",
                },
            ],
            free_data: [
                {
                    _id: 8.14,
                    name: "Water",
                    description: "A blend of fresh tropical fruits.",
                    price: 4.99,
                    image_url: "/images/san.png",
                },
            ],
        },
        {
            _id: 8,
            name: "Bakery",
            title: "Snacks",
            description: "Grilled chicken on a bed of crisp romaine lettuce.",
            price: 8.99,
            image_url: "/images/Bakery.png",
        },
        {
            _id: 9,
            name: "Snacks & Retail",
            title: "Snacks",
            description: "Grilled chicken on a bed of crisp romaine lettuce.",
            price: 8.99,
            image_url: "/images/Snacks-Retail.png",
        },
    ],
};

export default productData;

export const popularData = [
    {
        _id: 1,
        itemName: "Beef Burger",
        description: "A blend of fresh tropical fruits.",
        cost: 4.99,
        imageSrc: "/images/beef-b.png",
    },
    {
        _id: 2,
        itemName: "Peri Peri Chicken Burger",
        description: "Melted cheese on a crispy thin crust.",
        cost: 7.99,
        imageSrc: "/images/peri-b.png",
    },
    {
        _id: 3,
        itemName: "Oli Burger",
        description: "A hearty vegetable patty with fresh lettuce and tomato.",
        cost: 5.99,
        imageSrc: "/images/oli-b.png",
    },
    {
        _id: 4,
        itemName: "Lamb Pocket",
        description: "A blend of fresh tropical fruits.",
        cost: 4.99,
        imageSrc: "/images/lamb-p.png",
    },
    {
        _id: 5,
        itemName: "Mexican Chicken Pocket",
        description: "Melted cheese on a crispy thin crust.",
        cost: 7.99,
        imageSrc: "/images/mexican-p.png",
    },
    {
        _id: 6,
        itemName: "Moroccan Veg Pocket",
        description: "A hearty vegetable patty with fresh lettuce and tomato.",
        cost: 5.99,
        imageSrc: "/images/veg-p.png",
    },
    {
        _id: 7,
        itemName: "Lamb Pocket",
        description: "A blend of fresh tropical fruits.",
        cost: 4.99,
        imageSrc: "/images/lamb-p.png",
    },
    {
        _id: 8,
        itemName: "Mexican Chicken Pocket",
        description: "Melted cheese on a crispy thin crust.",
        cost: 7.99,
        imageSrc: "/images/mexican-p.png",
    },
    {
        _id: 9,
        itemName: "Moroccan Veg Pocket",
        description: "A hearty vegetable patty with fresh lettuce and tomato.",
        cost: 5.99,
        imageSrc: "/images/veg-p.png",
    },
];
