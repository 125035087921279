import styled from "styled-components";

export const OrderList = styled("div")``;
export const SizeArea = styled("div")`
  height: ${(props) => `calc(99vh - ${props.height + 325}px)`};
  padding-bottom: 3em;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
