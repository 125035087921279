import App from "./App";
import "bootstrap/dist/css/bootstrap.css";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Redux/store";
import LoadedProvider from "./context/LoadedContext";
import InitialDataContextDataProvider from "context/InitialDataContext";
import GlobalStatesContextProvider from "context/GlobalStatesContext";
import { BrowserRouter } from "react-router-dom";
import { not } from "utils/helper";
import PosProvider from "context/posContext";
import { ThemeProvider } from "styled-components";
import { brandingStyles } from "styles/brandingStyles";
import { fetchJsonData } from "services/apis";
import { AdminTransectionsContextProvider } from "context/features/admin/AdminTransectionsContext";
import { AdminProvider } from "context/features/admin/AdminProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));

/** Register Service Worker */
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    })
    .then(function () {
      // Now, register the new service worker
      navigator.serviceWorker
        .register("/service-worker.js", {
          scope: "/",
        })
        .then(() => {
          if (not(localStorage.getItem("startedSW"))) {
            localStorage.setItem("startedSW", true);
            window.location.href = "/";
          }
        });
    })
    .catch(function (error) {
      console.error("Service Worker registration failed:", error);
    });
}

// console.log(brandingStyles);
root.render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={brandingStyles}>
        <GlobalStatesContextProvider>
          <AdminProvider>
            <LoadedProvider>
              <InitialDataContextDataProvider>
                <PosProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </PosProvider>
              </InitialDataContextDataProvider>
            </LoadedProvider>
          </AdminProvider>
        </GlobalStatesContextProvider>
      </ThemeProvider>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
