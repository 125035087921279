import React from "react";
import PropTypes from "prop-types";
import {
  AddBtn,
  AddItems,
  AddMore,
  CartBtn,
  CountItems,
  DeleteBtn,
} from "../index.style";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { not } from "utils/helper";

const QuantityController = ({
                              item,
                              onDecrease, // updated prop name
                              onIncrease, // updated prop name
                              renderButtonText,
                              dealItemCost,
                            }) => {
  return (
      <AddItems>
        {not(dealItemCost) && (
            <AddMore>
              <DeleteBtn onClick={onDecrease}>
                <AiOutlineMinus />
              </DeleteBtn>

              <CountItems>{item?.quantity}</CountItems>

              <AddBtn onClick={onIncrease}>
                <AiOutlinePlus />
              </AddBtn>
            </AddMore>
        )}
        <CartBtn>{renderButtonText()}</CartBtn>
      </AddItems>
  );
};

QuantityController.propTypes = {
  item: PropTypes.object.isRequired,
  onDecrease: PropTypes.func.isRequired,
  onIncrease: PropTypes.func.isRequired,
  renderButtonText: PropTypes.func,
  dealItemCost: PropTypes.number,
};

export default QuantityController;