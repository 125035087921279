export const LINKLY_RES_STATUS = {
    PRESS_OKAY_TO_CONTINIE: "pressOkToContinue",
    SWIPE_CARD: "swipeCard",
    TRANSECTION_DECLINED: "transectionDeclined",
    ENTER_ACCOUNT: "enterAccount",
    PINPAD_OFFLINE: "pinpadOffline",
    PINPAD_BUSY: "pinpadBusy",
    PURCHASING: "purchasing",
    ENTER_PIN: "enterPin",
    REJECT_PURCHASE: "rejectPurchase",
    REJECT_REFUND: "rejectRefund",
    SIGNATURE: "signature",
    PRESENT: 'present',
    PROCESSING: 'processing'
};
