import React, { createContext, useEffect, useState } from "react";

export const AdminTransectionsContext = createContext();

export const AdminTransectionsContextProvider = ({ children }) => {
  const [transections, setTransections] = useState([]);
  // Save the transition on the localStorage at runtime.
  useEffect(() => {
    if (!Array.isArray(transections) && Object.keys(transections).length) {
      localStorage.setItem("transections", JSON.stringify(transections));
    }
  }, [transections]);

  /** Hold the current transection id on which refunding is processing. */
  const [currentTransectionID, setCurrentTransectionID] = useState(null);

  return (
    <AdminTransectionsContext.Provider
      value={{
        transections,
        setTransections,
        currentTransectionID,
        setCurrentTransectionID,
      }}
    >
      {children}
    </AdminTransectionsContext.Provider>
  );
};
