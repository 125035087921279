import styled from "styled-components";

export const Heading = styled("h1")`
    align-self: self-start;
    margin-top: 10px;
    font-size: 3.5em;
    font-weight: 800;
    margin: 1em 0;
    font-family: "Montserrat", sans-serif !important;
    @media (max-width: 992px) {
        & {
            font-size: 2em;
        }
    }
    @media (max-width: 786px) {
        & {
            font-size: 1.5em;
        }
    }
`;
export const ScanContainer = styled("div")`
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2.8em;
    font-weight: 700;
    margin-bottom: 0;
    font-family: "Context bd";
    line-height: 1;
  }
  h2 {
    font-size: 3.2em;
    margin-bottom: 1em;
    line-height: 1;
    font-family: "Helvetica-light";
  }
  @media (max-width: 768px) {
    & {
      height: 55vh;
      h1 {
        font-size: 1.8em;
      }
      h2 {
        font-size: 2.2em;
        margin-bottom: 0.3em;
      }
    }
  }
`;

export const QRCode = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 55px;
`;
export const QRCodeContainer = styled("div")`
  width: 400px;
  height: 400px;
  background-color: #e2dac6;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 378px !important;
    height: 378px important;
  }
  @media (max-width: 768px) {
    & {
      width: 230px;
      height: 230px;
    }
    svg {
      width: 350px !important;
      height: 350px !important;
    }
  }
  @media (max-width: 600px) {
    & {
      width: 250px;
      height: 250px;
    }
  }
`;
