import React, { useEffect, useState, useRef } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import checkmarkAnimationData from "./checkmark-animation.json"; // Make sure to update the path to the location of your JSON file
import rippleAnimationData from "./ripple.json"; // Make sure to update the path to the location of your JSON file
import arrowOptionsData from "./ArrowRight.json"; // Make sure to update the path to the location of your JSON file
import listenOptionsData from "./Listening.json"; // Make sure to update the path to the location of your JSON file
import loadingOptionsData from "./Loading.json"; // Make sure to update the path to the location of your JSON file
import checkOptionsData from "./Check.json"; // Make sure to update the path to the location of your JSON file

import {
  Arrow,
  ArrowBtn,
  FooterBtn,
  FooterFade,
  FooterWrapper,
  HoldingLeftGradient,
  HoldingRightGradient,
} from "./index.style";
import Lottie from "react-lottie";

import { LoadingOutlined } from "@ant-design/icons";

const defaultOptions = {
  loop: false, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: checkOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const listenOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: listenOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const arrowOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: arrowOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const loadingOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: loadingOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Footer = () => {
  const [holding, setHolding] = useState(false);
  const [enteredText, setEnteredText] = useState(false);
  const [processed, setProccessed] = useState(false);
  const holdTimer = useRef(null);
  function startHold(event) {
    event.preventDefault(); // Prevents the default browser behavior, especially useful for touch events

    // Set up a timeout for 1 second (1000 ms) to detect holding
    holdTimer.current = setTimeout(function () {
      setHolding(true);
    }, 1000);
  }

  function endHold() {
    setHolding(false);
    setEnteredText(true);

    setTimeout(() => {
      setEnteredText(false);
      setProccessed(true);
    }, 1500);
    clearTimeout(holdTimer.current);
  }

  useEffect(() => {
    const holdTarget = document.getElementById("holdTarget");
    if (holdTarget) {
      holdTarget.addEventListener("mousedown", startHold);
      holdTarget.addEventListener("mouseup", endHold);
      // holdTarget.addEventListener('mouseleave', endHold) // for when the mouse leaves the span while holding
      holdTarget.addEventListener("touchstart", startHold);
      holdTarget.addEventListener("touchend", endHold);
    }
  }, []);

  let text =
    holding || enteredText || processed ? (
      <p>
        get a beef burger without lettuce and extra tomato with a remedy orange
        drink and a large cookie
      </p>
    ) : (
      <h4>Ask For What You Want</h4>
    );

  return (
    <FooterWrapper>
      <FooterFade />
      {/* 
      <FooterBtn>
        {holding || enteredText || processed ? (
          <>
            <HoldingLeftGradient></HoldingLeftGradient>
            <HoldingRightGradient></HoldingRightGradient>
          </>
        ) : (
          ""
        )}
        <div className="textContainer">
          <div
            className={
              holding || enteredText || processed ? "holdingAnimation" : ""
            }
          >
            {text}
          </div>{" "}
        </div>
        <ArrowBtn>
          {holding || enteredText || processed ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "5px",
              }}
            >
              <p style={{ marginRight: "5px" }}>Hold the button to speak</p>
              <Lottie options={arrowOptions} height={40} width={40}></Lottie>
            </div>
          )}
          <div className="holdButton">
            {holding ? (
              <>
                <Lottie options={listenOptions}></Lottie>
              </>
            ) : enteredText ? (
              <Lottie options={loadingOptions}></Lottie>
            ) : processed ? (
              <Lottie options={defaultOptions} />
            ) : (
              <span className="activeBtn" id="holdTarget"></span>
            )}
          </div>
        </ArrowBtn>
      </FooterBtn> */}
    </FooterWrapper>
  );
};

export default Footer;
