import styled from "styled-components";

export const Heading = styled("h1")`
  font-size: 2.5em;
  font-weight: 800;
  margin: 1em 0;
  font-family: "Montserrat", sans-serif !important;
  @media (max-width: 992px) {
    & {
      font-size: 2em;
    }
  }
  @media (max-width: 786px) {
    & {
      font-size: 1.5em;
    }
  }
`;
export const OrderNumContainer = styled("div")`
  width: 100%;
  margin-top: 6em;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 3em;
    font-size: 2.5em;
    font-family: Helvetica-light;
  }
`;
export const OrderNum = styled("div")`
  width: 100%;
  /* background-color: lightblue; */
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: end;
  -webkit-box-align: center;
  align-items: center;
  line-height: 0.7;
  margin-top: 35px;
  h1 {
    font-size: 3em;
    margin-bottom: 0em;
  }
  h2 {
    font-size: 13em;
    margin-bottom: 0.2em;
    line-height: 1;
    font-family: "Helvetica-light";
  }
  @media (max-width: 768px) {
    & {
      margin-top: 35px;
      h1 {
        font-size: 1.2em;
      }
      h2 {
        font-size: 5.7em;
      }
    }
  }
`;
export const Receipt = styled("div")`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0.3em auto.
  align-items: center;

  h4 {
    line-height: 1;
    font-size: 2.6em;
    margin-bottom: 0em;
  }
  h3 {
    line-height: 1;
    font-size: 2.5em;
    font-family: "Helvetica-light";
    margin-bottom: 0em;
  }
  h1 {
    line-height: 1;
    font-size: 5.6em;
    margin-bottom: 0.5em;
  }
  @media (max-width: 768px) {
    & {
      h1 {
        font-size: 2.6em;
      }
      h3 {
        font-size: 1.2em;
      }
      h4 {
        font-size: 1.1em;
      }
    }
  }
`;
export const QRCode = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* background-color: lightcyan; */
`;
export const QRCodeContainer = styled("div")`
  width: 326px;
  height: 308px;
  background-color: #e2dac6;
  border-radius: 15pt;
  display: flex;
  justify-content: center;
  align-items: center;

  .imgstyle {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 768px) {
    & {
      width: 160px;
      height: 160px;
      .imgstyle {
        width: 125px;
        height: 125px;
      }
    }
  }
`;
