/** Get Selected Product for customization
 * @param {Array} cart;
 * @param {String} productId;
 * @returns {Object};
 */
export const getSelectedProduct = (cart = [], productId, notInCart) => {
    if (notInCart) {
        return cart?.menu_data?.filter(({_id}) => {
            return _id === Number(productId);
        });
    }

    const {items} = cart;
    return items?.filter(({product: {_id}}) => {
        return _id === Number(productId);
    });
};

/** Get Free_data and Size_data from Json by passing parent id.
 * @param {JSON} jsonData,
 * @param {Number} parentId,
 * @returns {Object};
 */
export const getDataById = (jsonData, parentId) => {
    const menuData = jsonData.menu_data;

    const parentItem = menuData.find((item) => item._id === Number(parentId));

    if (!parentItem) {
        return {size_data: [], free_data: []};
    }
    return parentItem;
};

export const getFreeDataAndSize = (data) => {
    const {size_data, free_data} = data;
    return {size_data, free_data};
};


/**
 * The problem statement:
 * Now the problem is when we select the extras against any item and change the size of the item
 * After changin the size the selected extras the selected but the price reduced.
 * So for sustaining the price of the item we are there to resolve it.
 * */
const getSelectedExtrasPrice = (selectedItem, selectedSize) => {
    // debugger
    return Number(selectedItem.cost) - Number(selectedSize?.cost || 0);
}
export const newSelectedSizePriceWithExtrasPrice = (selectedItem, newSelecteditem, selectedSize) => {

    return getSelectedExtrasPrice(selectedItem, selectedSize) + Number(newSelecteditem.cost);
}


