import React, {useEffect} from "react";
import {HeaderContainer} from "./index.style";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {selectCalculateTotal, selectGetCartItems, setCartData, setOpenCart,} from "../../Redux/cartSlice";
import {applyFixed} from "utils/helper";
import {calGrandTotal, calServiceFeeByPercent, getServiceChargePercentage,} from "screens/yourOrder/helper";
import useNavigation from "hooks/useNavigation";

const Header = ({listItems, setListItems}) => {
    const total = useSelector(selectCalculateTotal);
    const items = useSelector(selectGetCartItems);
    const {handleNavigate} = useNavigation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCartData());
        setTimeout(() => {
            dispatch(setOpenCart(false));
        }, 3000);
    }, []);

    const store = useSelector((state) => state);

    const {cartData, openCart} = store?.cart;

    useEffect(() => {
        if (openCart) {
            const scrollY = window.scrollY;
            document.documentElement.style.overflow = "hidden";
            document.body.style.overflow = "hidden";

            window.scrollTo(0, 0);

            return () => {
                document.documentElement.style.overflow = "visible";
                document.body.style.overflow = "visible";

                window.scrollTo(0, scrollY);
            };
        }
    }, [openCart]);
    console.log(total, "total, total")
    return (
        <HeaderContainer>
            <Link onClick={handleNavigate}>
                <img draggable={false} src="/images/oliver-logo.PNG" alt=""/>{" "}
            </Link>
            <div>
                {openCart && (
                    <div
                        className="cartBackDrop"
                        onClick={() => dispatch(setOpenCart(false))}
                    ></div>
                )}
                <div className="cart" onClick={() => navigate("/your_order")}>
                    <strong>Checkout</strong>
                    <span>
            ${" "}
                        {applyFixed(
                            calGrandTotal(total, calServiceFeeByPercent(total, getServiceChargePercentage())),
                            2
                        )}
          </span>
                </div>
                <div className={`menuBox ${openCart ? "openMenuBox" : ""}`}>
                    {items?.map((item) => {
                        return (
                            <span
                                className="headerCartItem"
                                onClick={() => navigate("/your_order")}
                            >
                {item?.quantity + "x  " + item?.itemName}
              </span>
                        );
                    })}
                    {!items?.length && (
                        <span
                            className="headerCartItem"
                            onClick={() => navigate("/your_order")}
                        >
              Cart is empty
            </span>
                    )}
                </div>
            </div>
        </HeaderContainer>
    );
};

export default Header;
