import React, { useEffect, useRef, useState } from "react";
import {
  BlueText,
  Container,
  Form,
  FormContainer,
  InputField,
  InputLabel,
  SubmitButton,
  SubmitButtonContainer,
  Title,
  Wrapper,
} from "./index.style";

import { Logo } from "../components/AdminHeader.style";
import { useNavigate } from "react-router-dom";
import { Cancel } from "common/index.style";
import KioskBoard from "kioskboard";
import { Form as AntdForm } from "antd";

const Login = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const passwordRef = useRef();
  const [form] = AntdForm.useForm();

  const setupKioskBoard = (ref) => {
    if (ref.current) {
      KioskBoard.run(ref.current, {
        language: "en",
        theme: "dark",
        allowRealKeyboard: true,
        allowMobileKeyboard: true,
        // The Enter key can close and remove the keyboard. Prevented when "false"
        keysEnterCanClose: true,
        keysArrayOfObjects: [
          {
            0: "1",
            1: "2",
            2: "3",
            3: "4",
            4: "5",
            5: "6",
            6: "7",
            7: "8",
            8: "9",
            9: "0",
          },
          {
            0: "Q",
            1: "W",
            2: "E",
            3: "R",
            4: "T",
            5: "Y",
            6: "U",
            7: "I",
            8: "O",
            9: "P",
          },
          {
            0: "A",
            1: "S",
            2: "D",
            3: "F",
            4: "G",
            5: "H",
            6: "J",
            7: "K",
            8: "L",
          },
          {
            0: "Z",
            1: "X",
            2: "C",
            3: "V",
            4: "B",
            5: "N",
            6: "M",
          },
        ],
      });
    }
  };

  const addEventListener = (ref, event, handler) => {
    if (ref.current) {
      ref.current.addEventListener(event, handler);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (ref.current) {
        ref.current.removeEventListener(event, handler);
      }
    };
  };

  useEffect(() => {
    const clearPasswordListener = addEventListener(
      passwordRef,
      "change",
      handleChangePassword
    );
    return () => {
      clearPasswordListener();
    };
  });

  useEffect(() => {
    setupKioskBoard(passwordRef);
  }, []);

  const handleChangePassword = ({ target: { value } }) => {
    setPassword(() => value);
  };
  const handleSubmit = () => {
    if (password == "saregama") {
      navigate("/admin/settings");
    }
    if (password !== "correct_password") {
      // Set a custom error message for the password field
      form.setFields([
        {
          name: "password",
          errors: ["Incorrect password. Please try again."],
        },
      ]);
    }
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Title>
            <p>Kiosk Setting Page</p>
          </Title>
          <FormContainer>
            <div>
              <BlueText>Admin Login</BlueText>
            </div>
            <Form style={{ position: "relative" }}>
              <AntdForm form={form}>
                <div
                  onClick={() => navigate("/")}
                  style={{
                    position: "absolute",
                    right: -10,
                    top: -10,
                    color: "#fff",
                    textAlign: "center",
                    width: 40,
                    height: 40,
                    background: "red",
                    borderRadius: "50%",
                    fontSize: "1.5rem",
                  }}
                >
                  X
                </div>
                <InputLabel>Password</InputLabel>
                <AntdForm.Item name="password" style={{ width: "100%" }}>
                  <InputField
                    name="password"
                    onChange={handleChangePassword}
                    type="password"
                    ref={passwordRef}
                    data-kioskboard-type="keyboard"
                    style={{ marginBottom: 20, }}
                  />
                </AntdForm.Item>
                <SubmitButtonContainer>
                  <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                </SubmitButtonContainer>
              </AntdForm>
            </Form>
          </FormContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default Login;
