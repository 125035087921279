import {createSlice, current} from "@reduxjs/toolkit";
import {getCart} from "../utils/helper";

const initialState = {
    loading: false,
    openCart: false,
    showOrderLimit: false,
    setOpenModel: false,
    cartData: {
        items: [],
        ids: {
            3232: "", // id with holding the index
        },
        children: {},
        count: 0,
        total: 0,
        grandTotal: 0,
    },
};

// Helpers
const incrementProductCount = (state, product, count) => {
    product.quantity += count;
    product.cost = Number(product?.cost) * count;
    product.price = Number(product.price) * count;
};

const doesExistAlready = (state, productID) => {
    return state?.cartData?.ids?.[productID];
};

const doesHaveSamePrice = (product, currentProduct) => {
    return product?.cost === currentProduct?.cost;
};

const doesHaveSameSize = (product, currentProduct) => {
    return JSON.stringify(product?.size) === JSON.stringify(currentProduct?.size);
};

const doesHaveSameItems = (product, currentProduct) => {
    const items1 = product?.items ?? {};
    const items2 = currentProduct?.items ?? {};

    const keys1 = Object.keys(items1);
    const keys2 = Object.keys(items2);

    // Check if the outer keys are the same for both items
    if (
        keys1.length !== keys2.length ||
        !keys1.every((key) => keys2.includes(key))
    ) {
        return false; // Different set of outer keys
    }

    // Check if the inner keys are the same for each outer key
    for (const key of keys1) {
        const innerKeys1 = Object.keys(items1[key]) ?? [];
        const innerKeys2 = Object.keys(items2[key]) ?? [];

        // Check if the inner keys are the same for both outer keys
        if (
            innerKeys1.length !== innerKeys2.length ||
            !innerKeys1.every((innerKey) => innerKeys2.includes(innerKey))
        ) {
            return false; // Different set of inner keys for the same outer key
        }
    }

    // If both the set of outer keys and their corresponding inner keys are the same, return true
    return true;
};

const isSame = (product, currentProduct) => {
    return (
        doesHaveSameSize(product, currentProduct) &&
        doesHaveSamePrice(product, currentProduct) &&
        doesHaveSameItems(product, currentProduct)
    );
};


const pushToCart = (state, payload) => {
    if (state?.cartData?.ids) {
        state.cartData.ids[payload?._id || payload?.productCode] = true;
    }
    state?.cartData?.items.push({ ...payload });
    state.cartData.total += payload?.total;
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart(state, { payload }) {
            const productID = payload?._id;
            const productCode = payload?.productCode;

            if (doesExistAlready(state, productID || productCode)) {
                const product = state?.cartData?.items.find((p) => {
                    return p?._id === productID;
                });

                if (isSame(product, payload)) {
                    incrementProductCount(state, product, payload?.quantity);
                    state.cartData.total += payload?.total;
                } else {
                    pushToCart(state, payload);
                }
            } else {
                pushToCart(state, payload);
            }
        },
        updateCartItem(state, { payload }) {
            const { index, item } = payload;
            const updatedItems = [...state.cartData.items];
            updatedItems[index] = item;

            return {
                ...state,
                cartData: {
                    ...state.cartData,
                    items: updatedItems,
                },
            };
        },

        updateGrandTotal(state, { payload }) {
            const grandTotal = payload;
            state.cartData.grandTotal = grandTotal;
        },

        removeCartItem(state, { payload }) {
            const { index } = payload;
            if (index >= 0 && index < state.cartData.items.length) {
                state.cartData.items.splice(index, 1);  // Remove item from array
                // Recalculate total
                state.cartData.total = state.cartData.items.reduce((acc, item) => acc + item.total, 0);
            }
        },

        updateItemPrice(state, { payload }) {
            const { index, quantity } = payload;
            if (index >= 0 && index < state.cartData.items.length) {
                const oldQuantity = state.cartData.items[index].quantity;
                const changeInQuantity = quantity - oldQuantity;
                state.cartData.items[index].quantity = quantity;
                state.cartData.items[index].total = state.cartData.items[index].cost * quantity;
                // Adjust total based on change in quantity
                state.cartData.total += state.cartData.items[index].cost * changeInQuantity;
                if (quantity <= 0) {
                    state.cartData.items.splice(index, 1);
                }
            } else {
                console.error(`Invalid index: ${index}`);
            }
        },
        makeCartEmpty(state) {
            state.cartData = {
                items: [],
                children: {},
                count: 0,
                total: 0,
            };
        },
        setOrderLimit(state) {
            state.showOrderLimit = false;
        },
        setCartData(state) {
            if (getCart()) {
                state.cartData = getCart();
            }
        },
        setOpenCart(state, {payload}) {
            state.openCart = payload;
        },
        setOpenModel(state, {payload}) {
            state.openCart = payload;
        },

        // ---------------Reset the whole Cart_slice----------------- //
        clearCart(state) {
            Object.assign(state, initialState);
        },
    },
});

export const selectGetCartItems = (state) => state?.cart?.cartData?.items;
export const selectGetTotal = (state) => state?.cart?.cartData?.total;
export const selectCalculateTotal = (state) => {
    const items = state?.cart?.cartData?.items || [];
    const total = items.reduce((acc, current) => acc + (current?.total || 0), 0);
    return total > state?.cart?.cartData?.total ? total : state.cart?.cartData?.total;
};
export const selectGrandTotal = (state) => state?.cart?.cartData?.grandTotal;

export default cartSlice.reducer;
export const {
    clearCart,
    addToCart,
    getCartItems,
    updateCartItem,
    removeCartItem,
    setCartData,
    setOrderLimit,
    setOpenCart,
    setOpenModel,
    updateItemPrice,
    makeCartEmpty,
    updateGrandTotal,
} = cartSlice.actions;
