import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  background-color: black;
  padding: 20px 30px;
  position: relative;
  z-index: 9991;

  .content-header {
    margin-top: 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      color: white !important;
      font-size: 2.5em;
    }
    .offline {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .dot {
        height: 28px;
        width: 28px;
        background-color: #ff0000;
        border-radius: 50%;
        display: inline-block;
      }
      p {
        margin: 0 !important;
        color: white !important;
        font-size: 2em;
      }
    }
    .settlement {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #c7c7c7;
      gap: 20px;
      padding: 10px 30px;
      border-radius: 50px;
      cursor: pointer;
      height: 80px;
      width: 35%;
      h2 {
        font-size: 1.5em;
        margin: 0 !important;
        color: white !important;
      }
      p {
        font-size: 1.5em;
        margin: 0 !important;
        color: white !important;
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #434343;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 1.5em 0;
    height: 80px;
    input {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 20px;
      color: black;
      width: 100%;
      color: white;
    }
    svg {
      color: #3ca3dc;
      font-size: 2.5em;
    }
  }

  .transactionTable {
    margin-top: 20px;
    overflow-x: auto;
    max-height: 75vh;
    overflow: auto;
    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 10px;
        text-align: left;
      }

      th {
        color: white;
        font-weight: 400;
        font-size: 1.1em;
      }

      td {
        border-bottom: 1px solid #ddd;
        color: white;
        font-size: 1.3em;
        font-weight: 600;
      }

      .refund,
      .approved,
      .pending,
      .decline {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 50px;
        margin-bottom: 5px;
        cursor: pointer;
        color: white;
        font-weight: 700;
        width: 130px;
        text-align: center;
      }

      .refund {
        background-color: #3ca3dc;
      }
      .refunded {
        background-color: #b726c3;
      }
      .approved {
        background-color: #44c325;
      }

      .pending {
        background-color: #ff9300;
      }

      .decline {
        background-color: #ff0000;
      }

      @media only screen and (max-width: 600px) {
        .statusCell {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
`;
