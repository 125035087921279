export const retrieveLinklyData = (key) => {
    let data = localStorage.getItem(key);
    if (data) {
        try {
            return JSON.parse(data);
        } catch (e) {
            return data
        }
    } else return null;
};

export const storeLinklyData = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
};
  