import styled, {css} from "styled-components";

export const ItemDetailsCard = styled("div")`
    p {
        color: #000;
    }

    .active {
        background-color: blue;
        color: white;
        font-weight: bold; 
    }

    // height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    text-align: center;
    margin: 1.5em 0;
    cursor: pointer;

    div {
        height: 188px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0em 0.5em;
        overflow: hidden;
        margin-bottom: 0.5em;
    }

    img {
        width: 83%;
        height: auto;
    }

    h5 {
        line-height: 1.3;
        font-size: 1.3em;
        font-weight: 700;
        margin-bottom: 0;
        color: #000000;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }

    p {
        font-family: "Helvetica-light" important;
        font-size: 1.2em;
        margin-top: 0.5em;
    }

    span {
        font-size: 1.2em;
    }

    ${(props) =>
            props.isSelected &&
            css`
                border-radius: 20px;
                background-color: #e0d4bb;
            `} @media (
    pointer: fine) {
    /* Styles for non-touchscreen devices (e.g., mouse or stylus) go here */

    &:hover,
    &:focus {
        border-radius: 20px;
        background-color: #e0d4bb;
    }
}

    @media (max-width: 992px) {
        div {
            height: 115px;
        }
    }
    @media (max-width: 768px) {
        & {
            margin: 0em 0;

            div {
                height: 85px;
                padding: 0.5em;
            }

            h5 {
                font-size: 1.1em;
                height: 32px;
            }

            span {
                font-size: 1em;
            }
        }
    }
    @media (max-width: 568px) {
        & {
            padding: 0.5em;

            div {
                padding: 0em 0.5em 0;
            }

            span,
            h5 {
                font-size: 0.9em;
            }

            p {
                font-size: 0.8em;
            }
        }
    }
    @media (max-width: 440px) {
        & {
            padding: 0.5em;

            div {
                height: 80px;
                padding: 0em 0.5em 0;
            }

            span,
            h5 {
                font-size: 0.8em;
                height: 25px;
            }
        }
    }
`;

export const DataContainer = styled("div")`
    height: 66vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 3em;
    padding-bottom: 1em;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (max-width: 992px) {
        height: 45vh;
        margin-bottom: 0em;
    }
`;
