import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SaucesContainer = styled(Modal)`
  .ant-modal-content {
    background-color: #e3dac6;
    padding: 1em 1em 0;
    box-shadow: none;
    border-radius: 30px;
    text-align: center;
    font-weight: 700;
    .ant-modal-body {
      h1 {
        font-weight: 700;
        margin-bottom: 1.5em;
        font-size: 2em !important;
        color: #000000;
      }
    }
  }
  .ant-modal-close {
    display: none;
  }
  .selectItem {
    background-color: #528d24;
    h6 {
      color: #ffffff !important;
    }
  }
  @media (max-width: 768px) {
    & {
      .ant-modal-content {
        max-width: 350px;
        margin: auto;
        .ant-modal-body {
          h1 {
            font-size: 1.6em !important;
          }
        }
      }
    }
  }
`;
export const AddBtn = styled(Link)`
  max-width: 310px;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
  margin: 1em auto;
  background: #000000;
  color: #ffffff;
  font-size: 1.4em;
  padding: 0.2em 1.5em;
  border-radius: 30px;
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    & {
      font-size: 0.8em;
      padding: 0.2em 0.5em;
      max-width: 180px;
    }
  }
`;
export const ModalContent = styled("div")`
  margin: 0em auto 1em;
  background-color: #e3dac6;

  @media (max-width: 768px) {
    & {
      font-size: 0.8em;
      padding: 0.1em 0.5em;
    }
  }
`;
export const SaucesCategory = styled("div")`
  height: 100px;
  cursor: pointer;
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: end;
  h1 {
  }
`;
