import styled from "styled-components";

export const Heading = styled("h1")`
  font-size: 2.5em;
  font-weight: 800;
  margin: 1em 0;
  font-family: "Montserrat", sans-serif !important;
  @media (max-width: 992px) {
    & {
      font-size: 2em;
    }
  }
  @media (max-width: 786px) {
    & {
      font-size: 1.5em;
    }
  }
`;
export const ScanContainer = styled("div")`
  width: 100%;
  // height: 70vh;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 2.8em;
    margin-bottom: 0;
    font-family: "Context bd";
    line-height: 1;
  }
  h2 {
    font-size: 2.3em;
    margin-bottom: 1em;
    line-height: 1;
    font-family: "Context bd";
  }
  @media (max-width: 768px) {
    & {
      height: 55vh;
      h1 {
        font-size: 1.8em;
      }
      h2 {
        font-size: 1.5em;
        margin-bottom: 0.3em;
      }
    }
  }
  // @media (max-width: 600px) {
  //   & {
  //     h1 {
  //       font-size: 1.1em;
  //     }
  //     h2 {
  //       font-size: 0.9em;
  //     }
  //   }
  // }
`;

export const QRCode = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // margin-top: 55px;
`;
export const QRCodeContainer = styled("div")`
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 400px !important;
    // height: 350px important;
  }
  @media (max-width: 768px) {
    & {
      border-radius: 25px;
      width: 230px;
      height: 230px;
    }
    svg {
      width: 350px !important;
      height: 350px !important;
    }
  }
  @media (max-width: 600px) {
    & {
      width: 250px;
      height: 250px;
    }
  }
`;
