import React from "react";
import Skeleton from "react-loading-skeleton";

import styled from "styled-components";

const ImagePlaceholderWrapper = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  text-align: center;
  position: relative;
  z-index: 0;
`;

const StyledSekeleton = styled(Skeleton)`
  width: 100%;
  height: 300px;

  @media (max-width: 992px) {
    height: 115px;
  }
  @media (max-width: 768px) {
    & {
      margin: 0em 0;
      height: 85px;
      padding: 0.5em;
    }
  }

  @media (max-width: 440px) {
    & {
      height: 80px;
      padding: 0em 0.5em 0;
    }
  }
`;


export const ImagePlaceholder = () => {
  return (
    <ImagePlaceholderWrapper>
      <StyledSekeleton />
      <Skeleton width="70%" />
      <Skeleton width="50%" />
    </ImagePlaceholderWrapper>
  );
};
