import styled from "styled-components";

export const FooterWrapper = styled("div")`
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  /* background-color: #f9f6e9; */
`;

export const FooterFade = styled.div`
  width: 100%;
  height: 100px;
  /* position: fixed; Fixed position for the footer */
  background: linear-gradient(
    to bottom,
    #fbf8ee04,
    #fbf8ee99,
    #fbf8ee,
    #fbf8ee
  );
`;

export const FooterBtn = styled("div")`
  max-width: 1080px;
  padding: 2.5em 2em;
  bottom: 1.1em;
  display: flex;
  height: 90px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  border-radius: 50px;
  margin: 1em auto;
  color: rgb(255, 255, 255);
  // position: fixed;
  position: relative;
  width: 90%;
  margin: 0 auto;
  z-index: 100000 !important;
  p {
    margin-bottom: 0px;
    font-family: "Context bd";
    font-size: 1.1em;
    white-space: nowrap;
  }
  h4 {
    color: #ffffff !important;
    font-size: 2.2em;
    margin-bottom: 0;
    margin-left: 0.5em;
  }

  .textContainer {
    width: 89%;
    overflow: hidden;
    .holdingAnimation {
      p {
        font-size: 0.9em;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      width: 90%;
      height: 42px;
      padding: 0.5em 1em;
      p {
        font-size: 0.9em !important;
      }
      h4 {
        font-size: 1.2em;
      }
      .textContainer {
        width: 89%;
        overflow: hidden;
        .holdingAnimation {
          font-size: 0.7em;
        }
      }
    }
  }
  @media (max-width: 560px) {
    & {
      h4 {
        font-size: 1em;
      }
    }
  }
`;
export const ArrowBtn = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .holdButton {
    position: relative;
    display: flex;
    z-index: 22222;
    svg {
      width: 95px !important;
      height: 95px !important;
    }
  }
  p {
    font-size: 1.1em;
    font-family: "Context regular";
    color: #ffffff90;
  }
  span.activeBtn {
    width: 40px;
    height: 40px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
    display: inline-block;
  }

  .rippleBtn {
    position: absolute;
    background: black;
    top: 0;
    left: 0;
    transform: translate(60%, 60%);
  }
  .spinner {
    font-size: 1.5em;
  }
  @media (max-width: 992px) {
    & {
      span.activeBtn {
        width: 30px;
        height: 30px;
      }
      .holdButton {
        position: relative;
        display: flex;
        z-index: 22222;
        svg {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }
`;

export const Arrow = styled("strong")`
  width: 27px;
  margin-left: 0.5em;
  svg {
    font-size: 1.4em;
    // margin-top: 0.3em;
  }
`;

export const HoldingLeftGradient = styled("span")`
  position: absolute;
  width: 30%;
  height: 37px;
  border-radius: 50px 0px 0px 52px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%) 0%
    0% no-repeat padding-box padding-box transparent;
  z-index: 999;
  left: 0;
`;
export const HoldingRightGradient = styled("span")`
  position: absolute;
  width: 30%;
  height: 37px;
  border-radius: 50px 0px 0px 52px;
  background: linear-gradient(99deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%) 0%
    0% no-repeat padding-box padding-box transparent;
  z-index: 999;
  right: 60px;
`;
