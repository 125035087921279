const fridgeMsg = "Please collect from display fridge";
const retailMsg = "Please collect from retail display";
const lunchAndEveningMsg = "Will be available after 11AM";
const coffeeNotSelectedMsg = "Please add a coffee.";

export const MESSAGES = Object.freeze({
  info: {
    Coffee: "",
    Smoothie: "",
    Breakfast: "",
    "Cold Drinks": fridgeMsg,
    Lunch: "",
    "Grab and Go": fridgeMsg,
    Kids: "",
    Bakery: "",
    Snacks: retailMsg,
    "Lunch & Evening": lunchAndEveningMsg,
  },
  warning: {
    coffeeNotSelected: coffeeNotSelectedMsg
  }
});
