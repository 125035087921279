import React from "react";
import AdminHeader from "./components/AdminHeader";
import {Outlet, useLocation} from "react-router-dom";


const Admin = () => {
    const location = useLocation();
    return (
        <>
            {
                !location.pathname.includes('/admin/login') && <AdminHeader/>
            }

            <Outlet/>
        </>
    )
}

export default Admin