import styled from "styled-components";
export const Logo = styled.div`
    width: 30%;
    
    img {
        padding: 1rem 1rem;
        width: 100%;
    }
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
    background: #000;
`;
export const Menu = styled.div`
  width: 75%;
  height: 80px !important;
  background-color: #434343;
  border-radius: 50px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  gap: 15px;

  .active,
  a {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: 1.5em;
    justify-content: center;
    align-items: center;
    color: #3ca3dc;
  }
  .active {
    color: #ffff !important;
    background-color: transparent !important;
  }
  span {
    color: white;
    background-color: white;
    border: 1px solid #434343;
    width: 1px;
    height: 50px;
  }
`;

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: end;
    row-gap: 10px;
    ;
`