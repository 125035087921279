import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useData from "./useData";
import { not } from "utils/helper";
import Swal from "sweetalert2";
import { GlobalStatesContext } from "context/GlobalStatesContext";

const useNavigation = () => {
  const location = useLocation();
  const { setActive } = useData();
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const { setGlobalState } = useContext(GlobalStatesContext);

  function isRoot() {
    return location.pathname === "/";
  }
  function isAdmin() {
    return location.pathname.includes("/admin");
  }
  function isKitchNotAvail() {
    return location.pathname.includes("/order_incomplete");
  }
  function getCurrentPath() {
    return location.pathname;
  }
  /**
   * Redirect to the Root when user click on the Logo.
   * setActive is a global state the reason to set it 0 is that, when the user will goes to / root then
   * Popular data will show.
   */
  const handleNavigate = (e) => {
    e.preventDefault();
    if (not(isAdmin())) {
      if ((isRoot() || isKitchNotAvail()) && count === 7) {
        setCount(0);
        navigate("/admin/login");
      } else if (not(isRoot()) && not(isKitchNotAvail())) {
        setActive(0);
        navigate("/");
      } else {
        setCount((prev) => prev + 1);
      }
    }
  };

  const handleLeaveAdmin = () => {

    setGlobalState((prev) => {
      return {
        ...prev,
        closeAdmin: true,
      };
    });
  };

  return { handleNavigate, handleLeaveAdmin, getCurrentPath };
};

export default useNavigation;
