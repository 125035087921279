import styled from "styled-components";

export const Header = styled("div")`
  width: 100%;
  height: auto;
  line-height: 1;
  display: flex;
  margin-top: -5px;
`;
export const Body = styled("div")`
  width: 100%;
  height: auto;
  margin-top: 1em;

  h3 {
    font-size: 2.5em;
  }
  p {
    font-size: 1.2em;
    font-family: "Helvetica-light";
    line-height: 1.3;
    margin-bottom: 2em;
  }
  @media (max-width: 992px) {
    p {
      font-size: 1.3em;
    }
    h3 {
      font-size: 1.4em;
    }
  }
  @media (max-width: 768px) {
    p {
      font-size: 0.9em;
    }
    h3 {
      font-size: 1em;
    }
  }
  @media (max-width: 300px) {
    margin-top: 0.5em;
    p {
      font-size: 0.6em;
    }
    h3 {
      font-size: 0.9em;
    }
  }
`;
export const Footer = styled("div")`
  max-width: 1020px;
  height: auto;
  position: fixed;
  right: 4%;
  left: 4%;
  bottom: 38px;
  h3 {
    font-weight: 700;
    margin-top: 10px;
    font-size: 2.5em;
  }
  @media (max-width: 768px) {
    h3 {
      font-size: 1.7em;
    }
  }
  @media (max-width: 768px) {
    h3 {
      font-size: 1em;
    }
    left: 5%;
    right: 5%;
  }
  @media (max-width: 450px) {
    position: unset;
    bottom: 38px;
    left: 4%;
    right: 4%;
    h3 {
      font-size: 0.8em;
    }
  }
  @media (max-width: 300px) {
    h3 {
      font-size: 0.8em;
    }
  }
`;
export const Left = styled("div")`
  width: 50%;
  height: auto;
  /* background-color: green; */
  tr {
    td {
      font-family: "Helvetica-bold";
      color: #000000;
      font-size: 2.3em;
      margin-bottom: 0.2em;
      display: block;
    }
  }
  @media (max-width: 992px) {
    & {
      tr {
        td {
          font-size: 1.2em;
        }
      }
    }
  }
  @media (max-width: 768px) {
    & {
      tr {
        td {
          font-size: 1em;
        }
      }
    }
  }
  @media (max-width: 300px) {
    & {
      tr {
        td {
          font-size: 0.6em;
        }
      }
    }
  }
`;
export const Right = styled("div")`
  width: 50%;
  height: 410px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .proImgSty {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    & {
      width: 60%;
      height: 180px;
    }
  }
  @media (max-width: 300px) {
    & {
      height: 100px;
    }
  }
`;
export const FooterContext = styled("div")`
  width: 50%;
  height: 140px;
  background-color: #ffffff;
  border: 4px solid #388067;
  border-radius: 10pt;
  display: flex;
  .Left {
    width: 30%;
    height: 100%;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mapimgstyle {
      width: 105px;
      height: 84px;
      margin-bottom: 0.5em;
    }
  }
  .Right {
    width: 70%;
    height: 100%;
    /* background-color: brown; */
    line-height: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    h1 {
      font-weight: 500;
      font-size: 1.1em;
      text-align: justify;
    }
    h2 {
      font-weight: 700;
      font-size: 1.4em;
    }

    .progrsbar {
      width: 88%;
      height: 15px;
      background-color: black;
      border-radius: 24pt;
      margin-top: 10px;

      .innerbar {
        width: 48%;
        height: 100%;
        background-color: #fff;
        border-radius: 24pt;
      }
    }
  }
  @media (max-width: 780px) {
    & {
      width: 70%;
    }
  }
  @media (max-width: 300px) {
    & {
      width: 100%;
      height: 80px;
      .Right {
        h1 {
          font-size: 0.6em;
        }
      }
    }
  }
`;
export const TopHeader = styled("div")`
  position: relative;
  span {
    position: absolute;
    top: -2px;
    right: 0;
    cursor: pointer;
  }
`;

export const Scale = styled.div`
  width: 100px;
  max-width: 100px;
  height: 25px;
  border: 3px solid #388067;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
`;

export const ScalePoint = styled.div`
  width: 5%;
  height: 40%;
  margin-left: ${({ distance }) => distance}%;
  background-color: #388067;
  position: absolute;
  bottom: 0;
`;

export const Fill = styled.div`
  width: ${({ percentage }) => percentage || 0}%;
  height: 100%;
  background-color: #f1b350;
`;

export const DisclamerContext = styled.div `
    margin-top: 40px;
    & p {
        color: #000;
        text-align: justify;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
`