
/** Find is product listred in cart.?
 * @param {Array} cart;
 * @param {Number} productId;
 * @returns boolean;
 */
export const isProductInCart = (cart, productId) => {
    const { items } = cart;
    return items.find(({ product: { _id } }) => {
        return _id === productId;
    });
}

export const not = value => !value;

export const isObj = value => typeof value === 'object';
export const isArray = value => Array.isArray(value);

export const is = value => {
    if (value) {
        return (isObj(value) && Object.keys(value).length) ||
            (isArray(value) && value.length);
    }
    return false;
}

export const keysOf = value => (isObj(value) && Object.keys(value)) || [];

export const contains = (obj = {}, item = "") => obj[item] || false;

export const log = (value = '', msg = 'By log util') => {
    console.group(msg);
    console.log(value);
    console.groupEnd(msg);
}