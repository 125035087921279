import React, {useEffect, useState} from "react";

import {
    AddBtn,
    AddItems,
    Avatar,
    CartBtn,
    CountItems,
    Customize,
    CustomizedLink,
    DeleteBtn,
    ModalContainer,
} from "./index.style";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addToCart, makeCartEmpty, selectGetCartItems, setOpenCart, updateItemPrice,} from "../../Redux/cartSlice";
import {BiInfoCircle} from "react-icons/bi";
import {selectGetSelectedProduct, setSelectedProduct,} from "../../Redux/productSlice";

const SelectItemModal = ({
                             product,
                             visible,
                             setVisible,
                             parentId,
                             isMeal,
                             meall,
                             handleOpenDetailCard,
                         }) => {
    const dispatch = useDispatch();

    const items = useSelector(selectGetCartItems);
    const selectedProduct = useSelector(selectGetSelectedProduct);
    const addedProduct = items?.find((p, i) => p._id === selectedProduct?._id);
    // console.log(selectedProduct, "selectedProduct");
    const index = items.findIndex((p) => p._id === selectedProduct?._id);

    const [quantity, setQuantity] = useState(null);
    useEffect(() => {
        if (addedProduct) {
            setQuantity(() => {
                return addedProduct?.quantity;
            });
        }
    }, []);

    const updateQuantity = (quantity) => {
        if (addedProduct) {
            dispatch(updateItemPrice({...addedProduct, quantity, index}));
        }
    };
    useEffect(() => {
        if (quantity !== null) {
            updateQuantity(quantity);
        }
    }, [quantity]);

    // console.log(selectedProduct, " selectedProduct...");

    const handleCustomized = () => {
        if (addedProduct && isMeal) {
            dispatch(
                setSelectedProduct({
                    ...addedProduct,
                    imageSrc: meall?.img,
                    size: "Medium",
                    items: {
                        ...meall,
                    },
                })
            );
        } else if (addedProduct) {

            dispatch(setSelectedProduct({...addedProduct, size: "Medium"}));
        } else {
            const total = Number(selectedProduct.itemSize[0]?.cost) * quantity;
            const price = selectedProduct.itemSize[0].cost;
            if (isMeal) {
                const total = meall?.cost * quantity;
                const price = selectedProduct.itemSize[0].cost;
                dispatch(
                    setSelectedProduct({
                        ...selectedProduct,
                        ...selectedProduct?.itemSize[0],
                        cost: meall?.cost,
                        imageSrc: meall?.img,
                        displayName: meall?.mealName,
                        itemName: meall?.mealName,
                        total,
                        quantity,
                        items: {
                            ...meall,
                        },
                    })
                );
            } else {
                dispatch(
                    setSelectedProduct({
                        ...selectedProduct,
                        ...selectedProduct?.itemSize[0],
                        total,
                        quantity,
                    })
                );
            }
        }
    };

    const handleAddToCart = () => {
        if (isMeal) {
            debugger
            dispatch(
                addToCart({
                    ...selectedProduct,
                    ...selectedProduct?.itemSize[0],
                    imageSrc: meall?.img,
                    displayName: meall?.mealName,
                    itemName: meall?.mealName,
                    cost: meall?.cost,
                    items: {
                        ...meall,
                    },
                    quantity,
                    total: Number(selectedProduct?.itemSize[0]?.cost * quantity),
                })
            );
        } else {
            debugger
            dispatch(
                addToCart({
                    ...selectedProduct,
                    ...selectedProduct?.itemSize[0],
                    quantity,
                    total: Number(selectedProduct?.itemSize[0]?.cost * quantity),
                })
            );

        }
        debugger
        dispatch(setOpenCart(true));
        debugger
        setTimeout(() => {
            debugger
            dispatch(setOpenCart(false));
        }, 2500);
        setVisible(false);
    };

    // console.log(addedProduct, " added");

    return (
        <>
            <ModalContainer
                centered
                open={visible}
                onCancel={setVisible}
                footer={false}
            >
                <Avatar>
                    <img
                        draggable={false}
                        src={meall?.img || product?.imageSrc}
                        alt=""
                        loading="lazy"
                    />
                </Avatar>
                <h5>
                    {meall?.mealName || product?.itemName}{" "}
                    <Link to={`/product_details/${product?._id}`}>
                        <BiInfoCircle
                            onClick={() => dispatch(setSelectedProduct({...product}))}
                        />
                    </Link>
                </h5>
                <h6>
                    $
                    {addedProduct?.total?.toFixed(2) ||
                        addedProduct?.price?.toFixed(2) ||
                        (
                            Number(meall?.cost || selectedProduct?.cost) *
                            (quantity ||
                                meall?.cost ||
                                selectedProduct?.cost ||
                                selectedProduct?.price)
                        ).toFixed(2)}
                </h6>
                {Object.keys(addedProduct ?? {})?.length > 0 ? (
                    <CartBtn
                        onClick={() => {
                            setVisible(false);
                            dispatch(setOpenCart(true));
                        }}
                    >
                        {/* <span>Add To Cart</span> */}
                    </CartBtn>
                ) : (
                    <CartBtn onClick={handleAddToCart}>
                        <span>Add To Cart</span>
                    </CartBtn>
                )}

                <AddItems>
                    {addedProduct?.quantity === undefined ||
                    addedProduct?.quantity === 0 ? (
                        <DeleteBtn
                            onClick={() => {
                                setQuantity((prev) => {
                                    if (prev > 1) {
                                        return prev - 1;
                                    }
                                    return prev;
                                });
                                dispatch(makeCartEmpty());
                            }}
                        >
                            -
                        </DeleteBtn>
                    ) : (
                        <DeleteBtn
                            onClick={() =>
                                setQuantity((prev) => {
                                    if (prev > 1) {
                                        return prev - 1;
                                    }
                                })
                            }
                        >
                            -
                        </DeleteBtn>
                    )}

                    <CountItems>{addedProduct?.quantity ?? quantity}</CountItems>

                    {addedProduct?.quantity === undefined ||
                    addedProduct?.quantity === 0 ? (
                        <AddBtn onClick={() => setQuantity((prev) => prev + 1)}>+</AddBtn>
                    ) : (
                        <AddBtn onClick={() => setQuantity((prev) => prev + 1)}>+</AddBtn>
                    )}
                </AddItems>

                {selectedProduct?.itemSize?.length && (
                    <Customize>
                        <CustomizedLink
                            onClick={handleCustomized}
                            to={`/customize_order/${parentId}/${product?._id}`}
                        >
                            Customise
                        </CustomizedLink>
                    </Customize>
                )}
            </ModalContainer>
        </>
    );
};

export default SelectItemModal;
