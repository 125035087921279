import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {BackBtn, TopHeading, Wrapper} from "../../common/index.style";
import Footer from "../../components/footer";
import SelectOrder from "../../components/allItems/selectOrder";
import {Col, Row} from "antd";
import {ItemDetailsCard} from "../../common/products/index.style";
import {useDispatch, useSelector} from "react-redux";
import {setOpenCart} from "../../Redux/cartSlice";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getDataById, getFreeDataAndSize, newSelectedSizePriceWithExtrasPrice} from "./helper";
import productData from "../../assets/data/products";
import {renderSizes} from "../../dataService/dataService";
import "./style.css";
import {selectIsItemFromOrderedList, setSelectedProduct, updateSelectedProduct,} from "../../Redux/productSlice";
import {SizeArea} from "./index.style";
import SaucesItemModal from "../../components/modals/saucesItems";
import SaltItemModal from "../../components/modals/SaltItems";
import {not} from "../../components/modals/helper";
import {CancelBtn} from "components/tabs/index.style";
import CancelOrderModal from "components/modals/orderCancel";
import {formatPrice, getChildsArray, log} from "utils/helper";
import {GlobalStatesContext} from "context/GlobalStatesContext";
import ItemSizes from "./components/ItemsSizes";
import useClearApp from "hooks/useClearApp";

const CustomizeOrder = ({handleSelectHotChocolate, currentCategory}) => {
    const dispatch = useDispatch();
    const {globalState, setGlobalState} = useContext(GlobalStatesContext);
    const {productId, parentId} = useParams();
    const [searchParams] = useSearchParams();

    const {selectedProduct} = useSelector((state) => state?.products);

    const [editingMeal, setEditingMeal] = useState(false);

    const posData = localStorage.getItem("posData") || "{}";
    const parsedPosData = JSON.parse(posData || "{}");
    const {storeCode = ""} = parsedPosData;
    // log(selectedProduct, " selectedProduct in customizeOrder");

    /** If current Parent product is editing and the child is comming back from the editing mode
     * Into the parent product then the parent product have to be updated
     */
    // useEffect(() => {
    //     if (globalState?.customOrder?.editing?.[productId]) {
    //
    //         setEditingMeal(true);
    //         setGlobalState((prev) => {
    //             return {
    //                 ...prev,
    //                 ...prev?.customOrder,
    //                 editing: {[productId]: false},
    //             };
    //         });
    //     }
    // }, [globalState, productId]);

    useEffect(() => {
        if (globalState?.[productId]?.customOrder?.editing) {
            setEditingMeal(true);
            setGlobalState((prev) => {
                return {
                    ...prev,
                    [productId]: {
                        ...prev[productId],
                        customOrder: {
                            ...prev[productId]?.customOrder,
                            editing: false,
                        },
                    },
                };
            });
        }
    }, [globalState, productId]);

    useEffect(() => {
        console.log(globalState, '...globalState...');
    }, [globalState]);

    const [selectedCustomizedData, setSelectedCustomizedDatga] = useState(null);
    const isItemFromOrderedList = useSelector(selectIsItemFromOrderedList);

    const [sizeData, setSizeData] = useState([]);
    const [freeData, setFreeData] = useState([]);
    const [isSauceOpen, setIsSauceModalOpen] = useState(false);
    const [isSaltOpen, setSaltModalOpen] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);
    const {clearWholeApp} = useClearApp();

    const dealItemCost = useMemo(
        () => searchParams.get("dealItemCost"),
        [searchParams]
    );
    const isDealItem = useMemo(
        () => searchParams.get("isDealItem"),
        [searchParams]
    );

    const parentSize = useMemo(
        () => searchParams.get("parentSize"),
        [searchParams]
    );

    useEffect(() => {
        // console.log(selectedProduct, " selectedPorudct...");
        // setSelectedProduct()
    }, [isDealItem]);

    const showCancelOrder = () => {
        setCancelOrder(true);
    };
    const handleCancelOrder = () => {
        clearWholeApp();
        setCancelOrder(false);
    };

    const handleSauceCancel = () => {
        setIsSauceModalOpen(false);
        setSaltModalOpen(false);
    };

    useEffect(() => {
        const product = getDataById(productData, parentId);

        const {free_data, size_data} = getFreeDataAndSize(product);
        setFreeData(free_data);
        setSizeData(size_data);
        // console.log(selectedCustomizedData);
    }, [selectedCustomizedData]);

    useEffect(() => {
        if (
            Array.isArray(selectedCustomizedData) &&
            selectedCustomizedData.length &&
            selectedCustomizedData[0]?.product?.size
        ) {
            const data = {...selectedCustomizedData[0]?.product};
            delete data?.free_data;
            delete data?.key;
            delete data?.size_data;
        }
    }, [selectedCustomizedData]);

    // console.log(selectedProduct, " selectedProduct...");

    const [sizes, setSizes] = useState([]);
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedExtras, setSelectedExtras] = useState({});
    const [childItemsList, setChildItemsList] = useState([]); // Like Hot chocolate in the meal avacodo.
    const [selectedOption, setSelectedOption] = useState("");
    const [displayItemName, setDisplayItemName] = useState("");
    const [doesContainsSizes, setDoesContainsSizes] = useState(false);
    const navigate = useNavigate();

    const [selectedProductSizeAndPrice, setSelectedProductSizeAndPrice] =
        useState({size: "", price: ""});

    console.log(selectedExtras, ' selectedExtras..')

    useEffect(() => {
        if (isItemFromOrderedList) {
            setSelectedExtras(selectedProduct?.items);
        }
    }, [isItemFromOrderedList]);

    useEffect(() => {
        if (selectedOption && displayItemName) {
            // Point one
            dispatch(
                updateSelectedProduct({
                    ...selectedProduct,
                    size: selectedProductSizeAndPrice?.size,
                    cost: selectedProductSizeAndPrice?.cost,
                    price: selectedProductSizeAndPrice?.cost,
                    items: {
                        ...selectedProduct?.items,
                        [displayItemName]: {
                            displayItemName:
                                selectedProduct?.items?.[displayItemName]?.displayItemName +
                                ` (${selectedOption})`,
                            itemCost: selectedProduct?.items?.[displayItemName]?.itemCost,
                        },
                    },
                })
            );
        }
    }, [selectedOption, selectedProductSizeAndPrice]);

    // console.log(productId, "productId");

    /**
     * This useEffect and it's code is responsible related to the sizes, and default sizes
     * In this code, selected product is able to change the size
     *
     */

    useEffect(() => {
        // Destructuring properties from the result of renderSizes(selectedProduct)
        const {
            sizes = [], // Array of available sizes
            defaultSize = {}, // Default size object
            containsSizes, // Boolean indicating if sizes are available
        } = renderSizes(selectedProduct);

        // If default size is "NA", set it to an empty string
        if (defaultSize?.size === "NA") {
            defaultSize.size = "";
        }
        // Set the state to reflect whether sizes are available
        setDoesContainsSizes(containsSizes);

        // Set the state with the array of available sizes
        setSizes(sizes);
        const parentSelectedSize = globalState?.[productId]?.customOrder?.parentSelectedSize;
        // Check if selectedSize is not set
        if (!selectedSize && !parentSelectedSize) {
            // Log selectedExtras (for debugging purposes)
            // console.log(selectedExtras);
            // Check if there is a selectedSize stored in localStorage
            if (localStorage.getItem("selectedSize")) {
                // Retrieve and parse the stored selectedSize
                setSelectedSize(
                    JSON.parse(localStorage.getItem("selectedSize") || "{}")
                );
                // ""
                // Remove the stored selectedSize from localStorage
                localStorage.removeItem("selectedSize");
            } else {
                // If no stored selectedSize, set it to the defaultSize
                setSelectedSize({...defaultSize});
            }

            // Determine if a size is selected based on selectedProduct and selectedExtras
            const isSizeSelected =
                selectedProduct?.sizeSelected ||
                Object.keys(selectedExtras || {}).length;

            const defaultItemCost = isSizeSelected
                ? selectedProduct?.cost
                : defaultSize.cost;

            const defaultItemSize = isSizeSelected
                ? parentSize || selectedProduct.size
                : parentSize || defaultSize.size;
            // Update size and price based on the selected or default size
            if (isDealItem && dealItemCost) {
                updateSizeAndPrice({size: defaultItemSize, cost: dealItemCost, originalPriceOfItem: dealItemCost});
            } else {
                updateSizeAndPrice({
                    size: defaultItemSize,
                    cost: defaultItemCost,
                    originalPriceOfItem: defaultItemCost
                });
            }
            // If Child is added to the meal deal, Then used the parent selectedSize object which was save while jumping tot the child.
        } else if (parentSelectedSize) {
            handleSelectSize({...parentSelectedSize});
            // After updating the parent SelectedSize object back, Now remove this becuae it will be there the app will going into loop
            // const updatedState = {
            //     ...globalState,
            //     customOrder: {
            //         ...globalState.customOrder,
            //         parentSelectedSize: null, // or use null, or completely omit this line if you want to remove the property
            //     },
            // };
            // setGlobalState(updatedState);

            const updatedState = {
                ...globalState,
                [productId]: {
                    ...globalState[productId],
                    customOrder: {
                        ...globalState[productId]?.customOrder,
                        parentSelectedSize: null, // or use null, or completely omit this line if you want to remove the property
                    },
                },
            };
            setGlobalState(updatedState);

        }
    }, [selectedExtras]);

    console.log(selectedExtras, " selectedExtras...");


    console.log(selectedSize, '...selectedSize...');
    console.log(sizes, '...sizes...');


    const updateSizeAndPrice = ({prev, size, cost, originalPriceOfItem, item={}}) => {
        setSelectedProductSizeAndPrice({size, cost});
        debugger
        dispatch(
            updateSelectedProduct({
                ...(prev || {...selectedProduct, ...item}),
                size: size,
                price: cost,
                cost: cost,
                itemCost: cost,
                actualCost: globalState?.customOrder?.actualCostOfMeal ? globalState?.customOrder?.actualCostOfMeal : selectedProduct.actualCost,
                // childActualCost: globalState?.customOrder.actualCost ? cost : null,
                quantity: selectedProduct?.quantity || 1,
                originalPriceOfItem: originalPriceOfItem || selectedProduct?.originalPriceOfItem,
            })
        );
    };

    console.log(selectedProduct, 'selectedProruct')
    const deleteItemAndPrice = (section, item, cost) => {
        const items = JSON.parse(JSON.stringify(selectedProduct?.items || {})); // Deep clone
        delete items[section][item];
        dispatch(
            updateSelectedProduct({
                ...selectedProduct,
                price: Number(selectedProduct?.price) - Number(cost),
                cost: Number(selectedProduct?.price) - Number(cost),
                priceChanged: true,
                items:
                    {
                        ...items,
                    } || {},
            })
        );
    };

    const updateItemsAndPrice = (
        isMeal,
        {displayItemName, itemCost, imageSrc},
        expendingItems,
        isSwitching,
        previousExtras
    ) => {
        if (isMeal) {
            dispatch(
                updateSelectedProduct({
                    ...selectedProduct,
                    price: Number(itemCost),
                    cost: Number(itemCost),
                    items: {...expendingItems},
                    imageSrc,
                    displayItemName,
                    itemName: displayItemName,
                })
            );
        } else {
            const updateProduct = (isSwitching, selectedProduct, previousExtras) => {
                let previousExtrasCost = 0;
                if (isSwitching && previousExtras) {
                    previousExtrasCost = Object.keys(previousExtras).map((key) => {
                        return previousExtras[key].itemCost;
                    })[0];
                }
                const selectedProductCost = Number(selectedProduct?.cost);
                const selectedProductPrice = Number(selectedProduct?.price);
                const newSelectedExtrasCost = Number(itemCost);
                return {
                    ...selectedProduct,
                    price:
                        (!isSwitching &&
                            Number(selectedProductPrice || selectedProductCost) +
                            Number(newSelectedExtrasCost)) ||
                        Number(selectedProductPrice || selectedProductCost) -
                        Number(previousExtrasCost) +
                        Number(newSelectedExtrasCost),
                    cost:
                        (!isSwitching &&
                            Number(selectedProductPrice || selectedProductCost) +
                            Number(newSelectedExtrasCost)) ||
                        Number(selectedProductPrice || selectedProductCost) -
                        Number(previousExtrasCost) +
                        Number(newSelectedExtrasCost),
                    actualCost:
                        selectedProduct?.actualCost ||
                        selectedProduct?.price ||
                        selectedProduct?.cost,

                    items:
                        {
                            ...expendingItems,
                        } || {},
                };
            };
            const updatedProduct = updateProduct(
                isSwitching,
                selectedProduct,
                previousExtras
            );
            dispatch(
                updateSelectedProduct({...updatedProduct, priceChanged: true})
            );
        }
    };

    // Convert the object to an array
    const productArray = (inputObject) => {
        return Object.keys(inputObject)
            .filter((key) => !isNaN(key)) // Filter out non-numeric keys
            .map((key) => inputObject[key]); // Map to the corresponding product objects
    };

    const getSize = (size) => {
        if (size === "NA") {
            return "Medium";
        }
        return size;
    };

    // Change the Item size
    const handleSelectSize = useCallback((item) => {

        setSelectedSize({...item});
        dispatch(updateSelectedProduct({...selectedProduct, ...item, preve: true}));
        let selectedSizePrice;
        if (selectedSize) {
            selectedSizePrice = newSelectedSizePriceWithExtrasPrice(selectedProduct, item, selectedSize);
        } else {
            // selectedSizePrice = globalState?.customOrder?.actualCostOfMeal;
            selectedSizePrice = globalState?.[productId]?.customOrder?.actualCostOfMeal;
        }
        // ""
        // setGlobalState(prev => {
        //     return {
        //         ...prev,
        //         customOrder: {
        //             ...prev.customOrder,
        //             actualCostOfMeal: selectedSizePrice || item?.cost,
        //         }
        //     }
        // });
        setGlobalState(prev => {
            return {
                ...prev,
                [productId]: {
                    ...(prev[productId] || {}),
                    customOrder: {
                        ...((prev[productId] && prev?.[productId].customOrder) || {}),
                        actualCostOfMeal: selectedSizePrice || item?.cost,
                        ...item,
                    }
                }
            }
        });


        debugger
        updateSizeAndPrice({
            size: getSize(item?.size),
            cost: selectedSizePrice || item?.cost,
            originalPriceOfItem: item?.cost,
            item: item
        });
    }, [selectedProduct]);

    /** Getting know I SELECTED item from the section called Make it a Meal or Standard Meal or Upgrade Meal
     * So item from mentioned section then treat is as meal.!
     */
    const isMeal = (section) =>
        section === "Make it a Meal" ||
        section === "Standard Meal" ||
        section === "Upgrade Meal";
    const [selectedMeal, setSelectedMeal] = useState({});

    const openModal = (itemName = "") => {
        switch (itemName) {
            case "Beans":
                setIsSauceModalOpen(true);
                break;
            case "Sweet Potato Chips":
                setSaltModalOpen(true);
                break;
            default:
                return "";
        }
    };

    const handleSelectExtras = (
        section,
        extra,
        isSwitching = false,
        previousExtras
    ) => {
        const displayItemName = extra?.displayItemName;
        let items = JSON.parse(JSON.stringify(selectedExtras || {})); // Nested object cloning.
        if (not(isSwitching)) {
            items = {
                ...items,
                [section]: {
                    ...items[section],
                    [displayItemName]: {displayItemName, itemCost: extra?.itemCost},
                },
            };
        } else {
            items = {
                ...items,
                [section]: {
                    [displayItemName]: {displayItemName, itemCost: extra?.itemCost},
                },
            };
        }
        setDisplayItemName(extra?.displayItemName);

        extra?.haveModal && openModal(extra?.displayItemName);
        setSelectedExtras((prev) => {
            let newState = JSON.parse(JSON.stringify(prev || {})); // deep cloning

            if (!newState[section]) {
                newState[section] = {};
            }

            const displayItemName = extra?.displayItemName;
            // Always add the item without checking if it's already selected
            if (not(isSwitching) && section && displayItemName) {
                newState[section][displayItemName] = {
                    displayItemName,
                    itemCost: extra?.itemCost,
                };
            } else {
                newState[section] = {
                    [displayItemName]: {displayItemName, itemCost: extra?.itemCost},
                };
            }
            // Now, you can call your updateItemsAndPrice function
            return newState;
        });
        console.group("Items");
        // console.log(items, "Items");
        console.groupEnd("Items");

        if (isMeal(section)) {
            setSelectedMeal(extra);
        }

        if (isMeal(section)) {
            navigate(`/customize_order/category/${extra?.productCode}?isDealItem=true`);
        }

        /** If item is HOT-CHOCOLATE */
        if (
            extra?.productCode == "MP-883803" ||
            extra?.productCode == "MP-883810" ||
            extra?.productCode == "MP-883797" ||
            extra?.productCode == "MP-883780" ||
            extra?.productCode == "MP-883819" ||
            extra?.productCode == "MP-883783" ||
            extra?.productCode == "MP-883813"
        ) {

            handleSelectHotChocolate({
                section,
                extra,
                productId,
                childId: extra.productCode,
                currentSelectedExtras: selectedExtras,
                currentSelectedModifires: selectedProduct?.modifiers,
                /** If selected Product not have actualCost property it's mean it have the actual price in the cost property.
                 * When cost will changed then the actual cost will be initiated with the actual cost
                 */
                actualCost: Math.max(
                    selectedProduct?.actualCost || 0,
                    selectedProduct?.cost || 0
                ),
                previousChildItemsCost: globalState?.[productId]?.customOrder?.childItemPrice,
                parentSize: selectedSize?.size,
                selectedSize: selectedSize, // Saving the parent selected size and price object. After comming back to child then this object will again populate to this item.
                parentProductId: productId,
            });
        }
        updateItemsAndPrice(
            isMeal(section),
            extra,
            items,
            isSwitching,
            previousExtras
        );
    };

    const switchSelectedExtra = ({
                                     section,
                                     extra,
                                     isSwitching = true,
                                     previousExtras,
                                 }) => {

        handleSelectExtras(section, extra, isSwitching, previousExtras);
    };

    console.group();
    console.log(selectedExtras, " selectedExtras");
    console.groupEnd();

    useEffect(() => {
        if (Object.keys(selectedExtras || {})?.length) {
            const updatedSelectedProduct = {
                ...selectedProduct,
                size: selectedProductSizeAndPrice?.size,
                cost:
                    (!selectedProduct?.priceChanged &&
                        selectedProductSizeAndPrice?.cost) ||
                    selectedProduct?.cost,
                price:
                    (!selectedProduct?.priceChanged &&
                        selectedProductSizeAndPrice?.cost) ||
                    selectedProduct?.price,
                items:
                    {
                        ...selectedExtras,

                    } || {},
            };
            debugger
            dispatch(
                updateSelectedProduct({
                    ...updatedSelectedProduct,
                })
            );
        }
    }, [selectedExtras, selectedProductSizeAndPrice]);

    // Function to deselect an item
    const handleDeselectExtras = (section, extra) => {
        setSelectedExtras((prev) => {
            const newState = JSON.parse(JSON.stringify(prev));

            const currentItemName = extra?.displayItemName;

            // Check if the item is already selected, and if so, deselect it
            if (newState[section] && newState[section][currentItemName]) {
                delete newState[section][currentItemName];
            }


            return newState;
        });

        // Now, you can call your updateItemsAndPrice function
        deleteItemAndPrice(section, extra?.displayItemName, extra?.itemCost);
    };

    const [extrasDefaultMinMax, setExtrasDefaultMinMax] = useState({});

    useEffect(() => {
        selectedSize?.modifiers?.forEach((modifire) => {
            Object.keys(modifire)?.forEach((extras) => {
                getDefaultMinMax(extras, modifire[extras]);
            });
        });
    }, [selectedSize]);

    // console.log(selectedExtras, " selectedExtrase");
    // console.log(extrasDefaultMinMax, " selectedExtras");
    /** Return Item which contains productPlu.
     * @param {Number} productPlu.
     * @param {Object} extra
     * @returns {Object}
     */
    const getExtraViaDefaultId = (productPlu, extra) => {
        const arr = productArray(extra);

        return (
            arr.find((el) => {
                return el?.productPlu == productPlu;
            }) || {
                displayItemName: "",
                imageSrc: "",
                itemCost: "0",
                modifiers_extras: null,

                posItemName: "",
                productCode: "",
                productPlu: "",
                size: "NA",
            }
        );
    };

    const getDefaultMinMax = (section, extra) => {
        let defaultExtra = null;
        const min = extra?.min;
        const max = extra?.max;

        if (extra?.default) {

            defaultExtra = getExtraViaDefaultId(extra?.default, extra);
            not(Object.keys(selectedExtras?.[section] || {}).length) &&
            handleSelectExtras(section, defaultExtra);
        }

        setExtrasDefaultMinMax((prev) => {
            return {...prev, [section]: {defaultExtra, min, max}};
        });
    };

    useEffect(() => {
        if (extrasDefaultMinMax) {
            // handleSelectExtras(section, defaultExtra);
        }
    }, [extrasDefaultMinMax]);

    const doesRemainMin = (section, min) =>
        Object.keys(selectedExtras[section]).length > min;

    const doesRemainLessThenMax = (section, max) => {
        return Object.keys(selectedExtras?.[section] || {})?.length < max;
    };

    /**Get seleted Items from LocalStorage and set to selected items state
     * Problem Statement: The purpose of this process is to ensure that
     * when we navigate to the information page by clicking on the info icon
     * within the customized page and then return back,
     * the previously selected items are still selected.
     */
    useEffect(() => {
        try {
            // Check if localStorage is available
            if (!window || !window.localStorage) {
                console.error("Local Storage is not available.");
                return;
            }

            const fromInfo = localStorage.getItem("fromInfo");
            const selectedExtrasJSON = localStorage.getItem("selectedExtras");

            if (fromInfo && selectedExtrasJSON) {
                const isJsonString = (str) => {
                    try {
                        JSON.parse(str);
                    } catch {
                        return false;
                    }
                    return true;
                }

                if (!isJsonString(selectedExtrasJSON)) {
                    console.error("Invalid JSON string");
                    return;
                }

                const parsedSelectedExtras = JSON.parse(selectedExtrasJSON);

                setSelectedExtras(parsedSelectedExtras);
                localStorage.removeItem("fromInfo");
            }

            localStorage.removeItem("selectedExtras");
        } catch (error) {
            console.error("Error in useEffect:", error);
        }
    }, []);

    /** Save the crurent Seleted Items when goes to the info page */
    const handleInfo = (item) => {
        dispatch(setSelectedProduct({...item, sizeSelected: true}));
        localStorage.setItem("selectedExtras", JSON.stringify(selectedExtras));
        localStorage.setItem("selectedSize", JSON.stringify(selectedSize));
    };
    const ref = React.useRef(null);
    const [height, setHeight] = useState(190);

    useEffect(() => {
        setHeight(ref.current.offsetHeight);
    }, [selectedProduct]);
    // console.log(parentId, " parentID");

    // /** Update the selected Item after comming back from the seperate screen
    //  * Example: In the case of hot chocolate, When user clicks on the item then sperate screen opens for selecting sizes.
    //  * After selecting the sizes and other stuff. When item added to the meal then this effect will work.
    //  */
    // useEffect(() => {
    //     /* It is checking if the maximum number of
    //     extras for a specific category (drinks, beans, etc.) is 1. If it is, then it sets a timeout of
    //     200 milliseconds. After the timeout, it updates the selected extras state by adding the updated
    //     item to the corresponding category. It also updates the global state by removing the customOrder
    //     property. */
    //     const section = globalState?.customOrder?.category; // Example drinks, beans...
    //     const updatedItem = globalState?.customOrder?.updatedItem;
    //     const currentSelectedExtras =
    //         globalState?.customOrder?.currentSelectedExtras;
    //     const currentSelectedModifires =
    //         globalState?.customOrder?.currentSelectedModifires;
    //     const childs = globalState?.customOrder?.childs;
    //     const childsArr = getChildsArray(selectedProduct?.childs || childs || {});
    //     console.log(childsArr);
    //
    //     // -----------------------------------------------------------------------------------
    //     if (extrasDefaultMinMax?.[section]?.max == 1) {
    //
    //         setChildItemsList(childsArr);
    //         setSelectedExtras((prev) => {
    //             return {
    //                 ...prev,
    //                 ...currentSelectedExtras,
    //                 [section]: {
    //                     [updatedItem?.displayItemName]: {...updatedItem},
    //                 },
    //             };
    //         });
    //         const updatedSelectedProduct = {
    //             ...selectedProduct,
    //             childs: {
    //                 ...childs,
    //             },
    //             modifiers: [
    //                 ...(currentSelectedModifires || selectedProduct?.modifiers),
    //             ],
    //         };
    //
    //         dispatch(setSelectedProduct({...updateSelectedProduct}));
    //
    //         updateSizeAndPrice({
    //             prev: {
    //                 ...updatedSelectedProduct,
    //             },
    //             size: selectedProduct?.size,
    //             cost:
    //                 Number(globalState.customOrder.actualCost || selectedProduct?.cost || selectedProduct?.price) +
    //                 (Number(
    //                     updatedItem?.itemCost || updatedItem?.cost || updatedItem?.price
    //                 ) || 0),
    //         });
    //
    //         // setGlobalState((prev) => {
    //         //     const {customOrder, ...newState} = prev;
    //         //     return newState;
    //         // });
    //
    //     }
    // }, [extrasDefaultMinMax, globalState?.customOrder]);


    // useEffect(() => {
    //     const section = globalState?.customOrder?.category;
    //     const updatedItem = globalState?.customOrder?.updatedItem;
    //     const currentSelectedExtras = globalState?.customOrder?.currentSelectedExtras;
    //     const currentSelectedModifires = globalState?.customOrder?.currentSelectedModifires;
    //     const childs = globalState?.customOrder?.childs;
    //     const childsArr = getChildsArray(selectedProduct?.childs || childs || {});
    //     console.log(childsArr);
    //
    //     if (extrasDefaultMinMax?.[section]?.max == 1) {
    //         setChildItemsList(childsArr);
    //         setSelectedExtras((prev) => {
    //             return {
    //                 ...prev,
    //                 ...currentSelectedExtras,
    //                 [section]: {
    //                     [updatedItem?.displayItemName]: {...updatedItem},
    //                 },
    //             };
    //         });
    //     }
    // }, [extrasDefaultMinMax]);

    // useEffect(() => {
    //     const section = globalState?.customOrder?.category;
    //     const updatedItem = globalState?.customOrder?.updatedItem;
    //     const currentSelectedExtras = globalState?.customOrder?.currentSelectedExtras;
    //     const currentSelectedModifires = globalState?.customOrder?.currentSelectedModifires;
    //     const childs = globalState?.customOrder?.childs;
    //     const childsArr = getChildsArray(selectedProduct?.childs || childs || {});
    //     console.log(childsArr);
    //
    //     if (extrasDefaultMinMax?.[section]?.max == 1) {
    //         const updatedSelectedProduct = {
    //             ...selectedProduct,
    //             childs: {
    //                 ...childs,
    //             },
    //             modifiers: [
    //                 ...(currentSelectedModifires || selectedProduct?.modifiers),
    //             ],
    //         };
    //
    //         dispatch(setSelectedProduct({...updateSelectedProduct}));
    //         ""
    //
    //         updateSizeAndPrice({
    //             prev: {
    //                 ...updatedSelectedProduct,
    //             },
    //             size: selectedProduct?.size,
    //             cost:
    //                 Number(globalState.customOrder.actualCostOfMeal || selectedProduct?.cost || selectedProduct?.price),
    //         });
    //     }
    // }, [globalState?.customOrder, extrasDefaultMinMax]);


    /** Update the selected Item after comming back from the seperate screen
     * Example: In the case of hot chocolate, When user clicks on the item then sperate screen opens for selecting sizes.
     * After selecting the sizes and other stuff. When item added to the meal then this effect will work.
     */
    // useEffect(() => {
    //     /* It is checking if the maximum number of
    //     extras for a specific category (drinks, beans, etc.) is 1. If it is, then it sets a timeout of
    //     200 milliseconds. After the timeout, it updates the selected extras state by adding the updated
    //     item to the corresponding category. It also updates the global state by removing the customOrder
    //     property. */
    //     const section = globalState?.customOrder?.category; // Example drinks, beans...
    //     const updatedItem = globalState?.customOrder?.updatedItem;
    //     const currentSelectedExtras =
    //         globalState?.customOrder?.currentSelectedExtras;
    //     const currentSelectedModifires =
    //         globalState?.customOrder?.currentSelectedModifires;
    //     const childs = globalState?.customOrder?.childs;
    //     const childsArr = getChildsArray(selectedProduct?.childs || childs || {});
    //     console.log(childsArr);
    //
    //     // -----------------------------------------------------------------------------------
    //     if (extrasDefaultMinMax?.[section]?.max == 1) {
    //
    //         setChildItemsList(childsArr);
    //         setSelectedExtras((prev) => {
    //             return {
    //                 ...prev,
    //                 ...currentSelectedExtras,
    //                 [section]: {
    //                     [updatedItem?.displayItemName]: {...updatedItem},
    //                 },
    //             };
    //         });
    //         const updatedSelectedProduct = {
    //             ...selectedProduct,
    //             childs: {
    //                 ...childs,
    //             },
    //             modifiers: [
    //                 ...(currentSelectedModifires || selectedProduct?.modifiers),
    //             ],
    //         };
    //
    //         dispatch(setSelectedProduct({...updateSelectedProduct}));
    //
    //         updateSizeAndPrice({
    //             prev: {
    //                 ...updatedSelectedProduct,
    //             },
    //             size: selectedProduct?.size,
    //             cost:
    //                 Number(globalState.customOrder.actualCostOfMeal || selectedProduct?.cost || selectedProduct?.price),
    //         });
    //
    //         // setGlobalState((prev) => {
    //         //     const {customOrder, ...newState} = prev;
    //         //     return newState;
    //         // });
    //
    //     }
    // }, [extrasDefaultMinMax, globalState?.customOrder]);


    useEffect(() => {
        const section = globalState?.[productId]?.customOrder?.category;
        const updatedItem = globalState?.[productId]?.customOrder?.updatedItem;
        const currentSelectedExtras =
            globalState?.[productId]?.customOrder?.currentSelectedExtras;
        const currentSelectedModifires =
            globalState?.[productId]?.customOrder?.currentSelectedModifires;
        const childs = globalState?.[productId]?.customOrder?.childs;
        const childsArr = getChildsArray(selectedProduct?.childs || childs || {});
        console.log(childsArr);

        if (extrasDefaultMinMax?.[section]?.max == 1) {
            setChildItemsList(childsArr);
            setSelectedExtras((prev) => {
                return {
                    ...prev,
                    ...currentSelectedExtras,
                    [section]: {
                        [updatedItem?.displayItemName]: {...updatedItem},
                    },
                };
            });
            const updatedSelectedProduct = {
                ...selectedProduct,
                childs: {
                    ...childs,
                },
                modifiers: [
                    ...(currentSelectedModifires || selectedProduct?.modifiers),
                ],
            };
            debugger
            dispatch(setSelectedProduct({...updateSelectedProduct}));
            updateSizeAndPrice({
                prev: {
                    ...updatedSelectedProduct,
                },
                size: selectedProduct?.size,
                cost:
                    Number(globalState?.[productId]?.customOrder?.actualCostOfMeal || selectedProduct?.cost || selectedProduct?.price),
            });

        }
    }, [extrasDefaultMinMax, globalState?.[productId]?.customOrder]);


    /** If this is the Child item of the meal and going to the editing the this code will beused */
    // useEffect(() => {
    //     const childInDeal = globalState?.customOrder?.childs?.[productId] || null;
    //     if (childInDeal) {
    //         setSelectedExtras((prev) => {
    //             return {...prev, ...childInDeal?.items};
    //         });
    //     }
    // }, []);


    console.log(selectedExtras, ' selectedExtras...')


    useEffect(() => {
        const childInDeal = globalState?.[productId]?.customOrder?.childs?.[productId] || null;
        if (childInDeal) {

            setSelectedExtras((prev) => {
                return {...prev, ...childInDeal?.items};
            });
        }
    }, []);

    useEffect(() => {
    }, [selectedProduct]);


    const handleBack = () => {
        dispatch(setOpenCart(false))
        setGlobalState((prev) => {
            if (!productId) return prev;
            const {[productId]: removedProduct, ...newState} = prev;
            const {[prev.parentProductId]: removedParentProduct, ...finalState} = newState;
            return {...finalState};
        });
        clearWholeApp({goBack: true});
    }

    return (
        <>
            <SaucesItemModal
                visible={isSauceOpen}
                setVisible={handleSauceCancel}
                setSelectedOption={setSelectedOption}
            />
            <SaltItemModal
                visible={isSaltOpen}
                setVisible={handleSauceCancel}
                setSelectedOption={setSelectedOption}
            />
            <CancelOrderModal
                visible={cancelOrder}
                setVisible={setCancelOrder}
                handleCancelOrder={handleCancelOrder}
            />
            <Wrapper>
                <BackBtn to="/" onClick={handleBack}>
                    Back
                </BackBtn>

                <CancelBtn onClick={showCancelOrder}>Cancel Order</CancelBtn>
                <div ref={ref} style={{margin: "2em 0 3em"}}>
                    <SelectOrder
                        parantProductId={productId}
                        childItemsList={childItemsList}
                        editingMeal={editingMeal}
                        item={selectedProduct}
                        handleInfo={handleInfo}
                    />
                </div>

                <SizeArea height={height}>
                    {doesContainsSizes && (
                        <>
                            {not(isDealItem) && (
                                <ItemSizes
                                    selectedProduct={selectedProduct}
                                    sizes={sizes}
                                    handleSelectSize={handleSelectSize}
                                    selectedSize={selectedSize}
                                />
                            )}
                        </>
                    )}

                    {selectedSize?.modifiers?.map((modifire) => {
                        return Object.keys(modifire)?.map((extras) => {
                            return (
                                <>
                                    <TopHeading style={{marginBottom: "0.5em"}}>
                                        {extras}
                                    </TopHeading>
                                    <Row gutter={10}>
                                        {/*{productArray(modifire[extras])?.map((extra, i) => {*/}
                                        {/*    return (*/}
                                        {/*        <Col*/}
                                        {/*            className=".active"*/}
                                        {/*            disabled*/}
                                        {/*            span={8}*/}
                                        {/*            onClick={() => {*/}
                                        {/*                if (*/}
                                        {/*                    selectedExtras?.[extras]?.[*/}
                                        {/*                        extra?.displayItemName*/}
                                        {/*                        ] &&*/}
                                        {/*                    doesRemainMin(*/}
                                        {/*                        extras,*/}
                                        {/*                        extrasDefaultMinMax?.[extras]?.min*/}
                                        {/*                    )*/}
                                        {/*                ) {*/}
                                        {/*                    handleDeselectExtras(extras, extra);*/}
                                        {/*                } else if (*/}
                                        {/*                    doesRemainLessThenMax(*/}
                                        {/*                        extras,*/}
                                        {/*                        extrasDefaultMinMax?.[extras]?.max*/}
                                        {/*                    ) &&*/}
                                        {/*                    not(*/}
                                        {/*                        selectedExtras?.[extras]?.[*/}
                                        {/*                            extra?.displayItemName*/}
                                        {/*                            ]*/}
                                        {/*                    )*/}
                                        {/*                ) {*/}
                                        {/*                    handleSelectExtras(extras, extra);*/}
                                        {/*                } else if (extrasDefaultMinMax?.[extras]?.max < 2) {*/}
                                        {/*                    // If max is not 1.*/}
                                        {/*                    switchSelectedExtra({*/}
                                        {/*                        section: extras,*/}
                                        {/*                        extra,*/}
                                        {/*                        isSwitching: true,*/}
                                        {/*                        previousExtras: selectedExtras?.[extras],*/}
                                        {/*                    });*/}
                                        {/*                }*/}
                                        {/*            }}*/}
                                        {/*        >*/}
                                        {/*            <ItemDetailsCard*/}
                                        {/*                style={{marginBottom: "0.5em"}}*/}
                                        {/*                className={*/}
                                        {/*                    selectedExtras?.[extras]?.[extra?.displayItemName]*/}
                                        {/*                        ? "active"*/}
                                        {/*                        : ""*/}
                                        {/*                }*/}
                                        {/*                key={i}*/}
                                        {/*            >*/}
                                        {/*                <div>*/}
                                        {/*                    <img*/}
                                        {/*                        draggable={false}*/}
                                        {/*                        src={extra.imageSrc}*/}
                                        {/*                        alt=""*/}
                                        {/*                    />*/}
                                        {/*                </div>*/}
                                        {/*                <h5>{extra?.displayItemName}</h5>*/}
                                        {/*                <span>{formatPrice(extra?.itemCost)}</span>*/}
                                        {/*            </ItemDetailsCard>*/}
                                        {/*        </Col>*/}
                                        {/*    );*/}
                                        {/*})}*/}

                                        {productArray(modifire[extras])
                                            ?.filter(extra => {
                                                if (!storeCode) return true; // If no storeCode, include all extras
                                                if (!extra.location) return true; // If extra.location is undefined, include the extra

                                                // Normalize storeCode by removing '-' signs and trimming spaces
                                                let storeCodesArray = storeCode
                                                    .split(',')
                                                    .map(code => code.replace('-', '').trim());

                                                // Normalize extra.location by splitting into an array, removing '-' signs, and trimming spaces
                                                let locationArray = extra.location
                                                    .split(',')
                                                    .map(loc => loc.replace('-', '').trim());

                                                console.log('storeCodesArray:', storeCodesArray);
                                                console.log('locationArray:', locationArray);

                                                // Check if any normalized store code matches the normalized extra location
                                                const matchFound = storeCodesArray.some(code => locationArray.includes(code));

                                                console.log('matchFound:', matchFound);

                                                return !matchFound; // Return true to include the item if no match is found
                                            })
                                            ?.map((extra, i) => (
                                                <Col
                                                    className={selectedExtras?.[extras]?.[extra?.displayItemName] ? "active" : ""}
                                                    disabled={selectedExtras?.[extras]?.[extra?.displayItemName]}
                                                    span={8}
                                                    key={i}
                                                    onClick={() => {
                                                        if (
                                                            selectedExtras?.[extras]?.[extra?.displayItemName] &&
                                                            doesRemainMin(extras, extrasDefaultMinMax?.[extras]?.min)
                                                        ) {
                                                            handleDeselectExtras(extras, extra);
                                                        } else if (
                                                            doesRemainLessThenMax(extras, extrasDefaultMinMax?.[extras]?.max) &&
                                                            !selectedExtras?.[extras]?.[extra?.displayItemName]
                                                        ) {
                                                            handleSelectExtras(extras, extra);
                                                        } else if (extrasDefaultMinMax?.[extras]?.max < 2) {
                                                            switchSelectedExtra({
                                                                section: extras,
                                                                extra,
                                                                isSwitching: true,
                                                                previousExtras: selectedExtras?.[extras],
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <ItemDetailsCard
                                                        style={{ marginBottom: "0.5em" }}
                                                        className={selectedExtras?.[extras]?.[extra?.displayItemName] ? "active" : ""}
                                                    >
                                                        <div>
                                                            <img draggable={false} src={extra.imageSrc} alt="" />
                                                        </div>
                                                        <h5>{extra?.displayItemName}</h5>
                                                        <span>{formatPrice(extra?.itemCost)}</span>
                                                    </ItemDetailsCard>
                                                </Col>
                                            ))}



                                    </Row>
                                </>
                            );
                        });
                    })}
                </SizeArea>
                <Footer/>
            </Wrapper>
        </>
    );
};

export default CustomizeOrder;
