import React from "react";
import { AdminTransectionsContext } from "context/features/admin/AdminTransectionsContext";
import { useContext } from "react";
import { PENDING } from "constants";
import { COLUMNS } from "constants/ADMIN";
import { APPROVED } from "constants";
import { DECLINED } from "constants";
import { REFUND } from "constants";

export const useAdminTransection = () => {
  const {
    transections,
    setTransections,
    currentTransectionID,
    setCurrentTransectionID,
  } = useContext(AdminTransectionsContext);
  /** Save the transection STATUS for displaying in the ADMIN Dashboard.
   * @param {String} status
   * @param {String} txnRef
   * @param {String} sessionID
   */
  const saveTransectionStatus = ({ status, txnRef, sessionID, ...rest }) => {
    switch (status) {
      case PENDING:
        setTransections((prev) => {
          return {
            ...prev,
            [sessionID]: {
              [COLUMNS["Transaction Status"]]: PENDING,
              [COLUMNS["Date/Time"]]: new Date(),
              [COLUMNS?.Refund]: false,
              sessionID: sessionID,
            },
          };
        });
        break;
      case APPROVED:
        setTransections((prev) => {
          return {
            ...prev,
            [sessionID]: {
              ...prev?.[sessionID],
              [COLUMNS["Transaction Status"]]: APPROVED,
              [COLUMNS["Date/Time"]]: new Date(),
              [COLUMNS?.["Transaction ID"]]: txnRef,
              [COLUMNS?.Refund]: true,
              sessionID: sessionID,
              ...rest,
            },
          };
        });
        break;
      case DECLINED:
        setTransections((prev) => {
          return {
            ...prev,
            [sessionID]: {
              ...prev?.[sessionID],
              [COLUMNS["Transaction Status"]]: DECLINED,
              [COLUMNS["Date/Time"]]: new Date(),
              [COLUMNS?.Refund]: false,
              sessionID: sessionID,
            },
          };
        });
        break;
      case REFUND:
        setTransections((prev) => {
          return {
            ...prev,
            [sessionID]: {
              ...prev?.[sessionID],
              [COLUMNS["Transaction Status"]]: REFUND,
              [COLUMNS["Date/Time"]]: new Date(),
              [COLUMNS?.["Transaction ID"]]: txnRef,
              sessionID: sessionID,
              ...rest,
            },
          };
        });
        break;
      default:
        break;
    }
  };

  /** Store the Sesssion id of the transection of whihc refunding is starting.... */
  const startRefunding = (sessionID) => {
    localStorage.setItem("currentRefundingSessionID", sessionID);
    setCurrentTransectionID(sessionID);
  };

  /** Is refunding under process
   * @param {String} transectionID
   */
  const isRefundingUnderProcess = () => {
    return currentTransectionID && true;
  };

  /** Update Refunding status in the transection
   * @param {sessionID}
   * @returns {Object} null
   */
  const updateRefundingStatus = (currentTransectionID) => {
    setTransections((prev) => {
      return {
        ...prev,
        [currentTransectionID]: {
          ...prev?.[currentTransectionID],
          refunded: true,
        },
      };
    });
    setCurrentTransectionID(null);
    return null;
  };

  // Clear transactions
  const clearTransections = () => {
    setTransections({});
  };

  return {
    transections,
    clearTransections,
    currentTransectionID,
    setCurrentTransectionID,
    setTransections,
    saveTransectionStatus,
    isRefundingUnderProcess,
    updateRefundingStatus,
    startRefunding,
  };
};
