import React, {memo} from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";
import ItemSize from "./ItemSize";
import {TopHeading} from "common/index.style";

const ItemSizes = ({sizes, selectedSize, handleSelectSize, selectedProduct}) => {
    console.log(selectedProduct, ' selectedProduct...');
    return (
        <>
            <TopHeading style={{marginBottom: "0.5em"}}>Size</TopHeading>
            <Row gutter={10}>
                {sizes?.map((item, i) => {
                    return (
                        item?.size !== "NA" && (
                            <Col span={8} onClick={() => {
                                handleSelectSize(item)
                            }
                            }>
                                <ItemSize item={item} index={i} selectedSize={selectedSize}/>
                            </Col>
                        )
                    );
                })}
            </Row>
        </>
    );
};

ItemSizes.propTypes = {
    sizes: PropTypes.array,
    selectedSize: PropTypes.object,
    handleSelectSize: PropTypes.func,
};

export default memo(ItemSizes);
