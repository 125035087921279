import React, {useEffect, useRef, useState} from "react";
import QRCode from "qrcode.react";
import {generateQRCodeImage} from "services/apis";

const ReceiptComponent = ({receipt = [], dynamicValues = {}}) => {
    console.log(dynamicValues);
    const canvasRef = useRef(null);
    const [qrcodeImage, setQrcodeImage] = useState();
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState("");
    const convertTxtToImage = () => {
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL("image/png");
        setImg(dataURL);
    };

    useEffect(() => {
        if (receipt?.length) {
            convertTxtToImage();
        }
    }, [receipt]);

    useEffect(() => {
        if (img) {
            generateQRCodeImage(img).then((res) => {
                debugger
                setQrcodeImage(res?.data?.RESPONSE?.imageUrl);
                setLoading(false);
            });
        }
    }, [img]);

    useEffect(() => {
        if (Object.keys(dynamicValues)?.length) {
            drawReceipt();
            setTimeout(() => {
                convertTxtToImage();
            }, 2000);
        }
    }, [dynamicValues]);

    function calculateDynamicHeight(items, logoHeight) {
        const headerSpace = 300; // Adjust as needed
        const footerSpace = 300; // Adjust as needed

        const mainItemHeight = 20; // Space for the main item line
        const modifierHeight = 20; // Space for each modifier line
        const spaceAfterItem = 30; // Space after each item and its modifiers

        let itemsHeight = items.reduce((acc, item) => {
            // Calculate height needed for each item and its modifiers
            let itemTotalHeight =
                mainItemHeight +
                item.modifiers.length * modifierHeight +
                spaceAfterItem;
            return acc + itemTotalHeight;
        }, 0);

        return headerSpace + itemsHeight + footerSpace + logoHeight;
    }

    function drawReceipt() {
        const canvas = document.getElementById("receiptCanvas");
        const ctx = canvas.getContext("2d");
        const logo = new Image();

        logo.onload = function () {
            // Calculate scaled logo dimensions
            const maxLogoWidth = 200;
            const scale = maxLogoWidth / this.naturalWidth;
            const scaledWidth = this.naturalWidth * scale;
            const scaledHeight = this.naturalHeight * scale;

            // Pass the logo image object to continueDrawingReceipt
            continueDrawingReceipt(ctx, 10, canvas, this, scaledWidth, scaledHeight);
        };

        logo.onerror = function () {
            console.error("Failed to load the logo image.");
            continueDrawingReceipt(ctx, 10, canvas, null, 0, 0);
        };

        logo.src = dynamicValues.logoBase64;
    }

    function continueDrawingReceipt(
        ctx,
        yPosition,
        canvas,
        logo,
        logoWidth,
        logoHeight
    ) {
        const lineHeight = 30;
        const padding = 20;
        const headerSpace = 300;
        const footerSpace = 250; // Increase footer space for additional information
        const modifiersLineHeight = 30; // Smaller line height for modifiers
        // Function to calculate the height needed for a single item with modifiers
        const itemHeight = (item) => {
            const mainItemHeight = 20; // Space for the main item line
            const modifierHeight = item.modifiers.length * 20; // 20px per modifier line
            const spaceAfterItem = 30; // Space after the item and its modifiers
            return mainItemHeight + modifierHeight + spaceAfterItem;
        };

        // Calculate the dynamic height based on the items
        const dynamicHeight =
            headerSpace +
            dynamicValues?.items.reduce((acc, item) => acc + itemHeight(item), 0) +
            footerSpace;

        // Now set the canvas height dynamically
        canvas.height = dynamicHeight;
        // Set a white background
        ctx.fillStyle = "#fbf8ee";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        yPosition += 20;
        // Redraw the logo
        if (logo) {
            const logoX = (canvas.width - logoWidth) / 2;
            ctx.drawImage(logo, logoX, yPosition, logoWidth, logoHeight);
            yPosition += logoHeight + 20; // Adjust yPosition for the rest of the content
        }

        ctx.fillStyle = "#000";
        ctx.font = "bold 45px Arial";
        ctx.textAlign = "center";
        // Set smaller font for the additional header details
        ctx.font = "11px Arial";
        yPosition = 80; // Starting position for the additional details below "Oliver's"
        // Add padding before the additional details
        yPosition += 20; // Padding from the top of the "Oliver's" title
        // Additional details below "Oliver's" title
        ctx.fillText("Oliver's Real Food Limited", canvas.width / 2, yPosition);
        yPosition += 15; // Line height for 11px font size
        ctx.fillText("ABN: 33166495441", canvas.width / 2, yPosition);
        yPosition += 15; // Line height for 11px font size
        ctx.fillText(dynamicValues.address, canvas.width / 2, yPosition);
        yPosition += 15; // Line height for 11px font size
        ctx.fillText("TEL: " + dynamicValues.tel, canvas.width / 2, yPosition);

        // Add padding after the additional details before the items
        yPosition += 50;

        // Set font for column titles
        ctx.font = "bold 12px Arial";
        ctx.fillText("Items", 65, yPosition);
        // Assuming 'Quantity' is roughly in the middle:
        ctx.fillText(
            "Quantity",
            canvas.width / 2 + 80 - ctx.measureText("Quantity").width / 2,
            yPosition
        );
        // Align 'Price' to the right:
        ctx.fillText("Price", canvas.width - 80, yPosition);

        // Adding padding after the column titles
        yPosition += 20;

        // Draw other details like on the original receipt
        ctx.font = "10px Arial";
        ctx.textAlign = "left";
        yPosition = 220;

        dynamicValues?.items.forEach((item) => {
            ctx.font = "bold 14px Arial";

            // Split item.mainItem into lines if it's longer than four words
            var mainItemLines = splitTextIntoLines(item.mainItem, 4);

            // Output each line separately
            mainItemLines.forEach((line, index) => {
                ctx.fillText(line, 50, yPosition + index * 20);
            });

            ctx.fillText(
                item.quantity.toString(),
                canvas.width / 2 +
                65 -
                ctx.measureText(item.quantity.toString()).width / 2,
                yPosition
            );
            ctx.fillText(item.price, canvas.width - 100, yPosition);
            yPosition += mainItemLines.length * 20; // Adjust the yPosition based on the number of lines in mainItem

            item.modifiers.forEach((modifier) => {
                ctx.font = "10px Arial";
                ctx.fillText(modifier.name, 55, yPosition); // Indented modifiers
                ctx.fillText(
                    modifier.quantity.toString(),
                    canvas.width / 2 + 80 - ctx.measureText("Quantity").width / 2,
                    yPosition
                );
                ctx.fillText(modifier.price, canvas.width - 100, yPosition); // Price aligned to the right
                yPosition += 20; // Next line for next modifier
            });

            ctx.beginPath();
            ctx.moveTo(50, yPosition + 10);
            ctx.lineTo(canvas.width - 50, yPosition + 10);
            ctx.stroke();

            yPosition += 30; // Gap after modifiers before next main item
        });

        // Function to split text into lines
        function splitTextIntoLines(text, maxWordsPerLine) {
            const words = text.split(' ');
            const lines = [];
            for (let i = 0; i < words.length; i += maxWordsPerLine) {
                lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
            }
            return lines;
        }

        ctx.font = "14px Arial";
        // surcharge
        yPosition += 20;
        ctx.font = "10px Arial";
        ctx.fillText("Surcharge:", 50, yPosition);
        ctx.fillText("$" + dynamicValues?.surcharge, canvas.width - 100, yPosition); // Replace $XX.XX with the actual subtotal
        // Sub Total
        ctx.font = "14px Arial";
        yPosition += 20;
        ctx.fillText("Total:", 50, yPosition);
        ctx.fillText("$" + dynamicValues.subTotal, canvas.width - 100, yPosition); // Replace $XX.XX with the actual subtotal
        yPosition += 20;
        // Tax
        ctx.fillText("Tax:", 50, yPosition);
        ctx.fillText("$" + dynamicValues.tax, canvas.width - 100, yPosition); // Replace $X.XX with the actual tax amount
        yPosition += 20;
        // Add padding before Kiosk Payment
        yPosition += 20; // Top padding
        // Kiosk Payment
        ctx.fillText("Kiosk Payment", 50, yPosition);
        ctx.fillText(
            "$" + dynamicValues.kiosk_payment,
            canvas.width - 100,
            yPosition
        );
        // Add padding after Kiosk Payment
        yPosition += 20; // Bottom padding
        // Adding padding before the main payment details
        yPosition += 100; // Padding from the top of this block

        // Set font for main payment details
        ctx.font = "11px Arial";
        ctx.fillText("Card Number: " + dynamicValues.cardNumber, 50, yPosition);
        yPosition += 12;
        ctx.fillText("Receipt ID: " + dynamicValues.receiptID, 50, yPosition);
        yPosition += 12;
        ctx.fillText("Card Type: " + dynamicValues.cardType, 50, yPosition);
        yPosition += 12;
        ctx.fillText("Approved: " + dynamicValues.approved, 50, yPosition);
        yPosition += 12;
        ctx.fillText("Auth No: " + dynamicValues.authNo, 50, yPosition);
        yPosition += 12;
        ctx.fillText("Sale Date: " + dynamicValues.saleDate, 50, yPosition);
        yPosition += 12; // Adding padding after the main payment details
        // Set font for additional payment details
        ctx.font = "9px Arial";
        ctx.fillText("AID: " + dynamicValues.aid, 50, yPosition);
        yPosition += 10;
    }


    return (
        <div>
            <canvas
                ref={canvasRef}
                id="receiptCanvas"
                width="450"
                height="800"
                style={{
                    position: "absolute",
                    left: "-9999px", // Position off-screen
                    top: "-9999px",
                }}
            ></canvas>

            {(loading && (
                <div
                    style={{
                        fontSize: "2rem",
                        color: "#000",
                        position: "relative",
                        animation: "fontSizeAnimation 1s alternate infinite",
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    Generating Receipt
                </div>
            )) || <QRCode value={qrcodeImage} style={{width: 250, height: 250}}/>}
        </div>
    );
};

export default ReceiptComponent;
