/**
 * Check if current selected Item is itself meal than apply the logic to test the coffee availability.
 * @param {Object} params
 * @param {Array} params.dealsList - list of deals in which we have to check
 * @param {object} params.selectedItems - List of the selected Items.
 * @returns {boolean} - return the true or false.
 */
export const isCoffeeSelected = ({dealsList = [], selectedItems = {}}) => (
  selectedItems?.items && dealsList?.includes(selectedItems?.productCode)
    ? Object.keys(selectedItems?.items).some(key => key === 'Coffee')
    : false
);

/**
 * Return is this Meal Deal or single Item.
 * @param {string} productCode
 * @param {array} deals
 * @return {boolean}
 * */
export const isMealDeal = (productCode, deals) => deals.includes(productCode);