import styled from "styled-components";

export const Wrapper = styled("div")`
  // background-color: #f9f6e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 3em 8em;
  max-width: 1080px;
  margin: 0px auto;
  @media (max-width: 992px) {
    & {
      padding: 2em 2em;
    }
  }
`;

export const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.4em;
  a {
    width: 30%;
    img {
      width: 100%;
    }
  }

  .ant-dropdown-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    padding: 0.7em 1em;
    background-color: #000000;
    border-radius: 30px;
    color: #ffffff;
    position: relative;
    z-index: 1052;
    span {
      color: #ffffff99;
    }
  }
  @media (max-width: 768px) {
    & {
      .ant-dropdown-trigger {
        width: 160px;
        padding: 0.7em 1em;
      }
    }
  }
`;

export const Heading = styled("h1")`
  /* margin-left: -17.5rem; */
  font-size: 2.5em;
  font-family: Montserrat, sans-serif !important;
  font-weight: 800;
  margin: 1em 0px 1em;
  @media (max-width: 786px) {
    font-size: 1.5em;
    margin-bottom: 1em;
  }
`;
export const PaymentCard = styled("div")`
  position: relative;
  width: 100%;
  min-height: ${(props) => `${props.height}px`};
  // min-height: 500px;
  border-radius: 25px;
  background-color: #e2dac6;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.5em;
  padding-left: 2em;
  padding-bottom: 12em;

  h2 {
    font-size: 16px;
  }
  p {
    line-height: 0.5;
    font-weight: 400 !important;
  }
  @media (max-width: 992px) {
    & {
      min-height: 300px;
    }
  }
`;

export const Product1 = styled("div")`
  display: flex;
`;
export const Product2 = styled("div")`
  display: flex;
`;
export const Product3 = styled("div")`
  display: flex;
`;
export const Total = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-top: 25px;
  position: absolute;
  bottom: 34px;
  left: 32px;
  right: 18px;
  h2 {
    font-size: 1.9em;
    margin-bottom: 0;
    &:last-child {
      font-family: "Helvetica-light" !important;
    }
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 1em;
    }
  }
`;
export const LeftSection = styled("div")`
  //   background-color: red;
  width: 70%;
  height: 100%;
  margin-bottom: 0.5em;
  h2 {
    font-size: 1.9em;
    margin-bottom: 0;
  }
  p {
    line-height: 1;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 1em;
    }
  }
`;
export const RightSection = styled("div")`
  //   background-color: blue;
  text-align: right;
  width: 30%;
  height: 100%;
  h2 {
    font-size: 1.9em;
    margin-bottom: 0;
    font-family: "Helvetica-light" !important;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 1em;
    }
  }
`;
export const UserName = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2em 0;
  width: 100%;

  h1 {
    font-weight: 800;
    margin-top: 15px;
  }

  input {
    background-color: #e2dac6;
    border: none;
    display: block;
    width: 100%;
    height: 90px;
    font-size: 1.5em;
    border-radius: 50px;
    outline: none;
    padding: 25px;
  }
  @media (max-width: 768px) {
    & {
      input {
        width: 100%;
        height: 40px;
        padding: 15px;
        font-size: 1em;
      }
    }
  }
`;
export const PaymentMethod = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  // height: auto;
  margin-top: 10px;

  h1 {
    font-weight: 800;
    margin-top: 15px;
  }
`;
export const Container = styled("div")`
  width: 100%;
  margin-top: 18%;
  // height: 150px;

  .icon {
    width: 156px;
    height: 120px;
  }

  .IconTxtWraper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      width: 100px;
      height: 80px;
    }
    h5 {
      font-size: 3.7em;
      margin-top: 0.3em;
      margin-bottom: 0em;
      font-family: "Context bd";
      line-height: 1.2;
    }
  }
  .activeItems,
  .paymentMethod {
    height: 240px;
    border-radius: 40px;
    background-color: #e2dac6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${(props) => props?.theme?.colors?.primary};
      cursor: pointer;
      h5 {
        color: #ffffff !important;
      }
      img {
        filter: invert(84%) sepia(94%) saturate(0%) hue-rotate(349deg)
          brightness(108%) contrast(107%) !important;
      }
    }
    &:focus-within {
      background-color: ${(props) => props?.theme?.colors?.primary} !important;
      h5,
      svg {
        color: #ffffff !important;
      }
      img {
        filter: invert(84%) sepia(94%) saturate(0%) hue-rotate(349deg)
          brightness(108%) contrast(107%) !important;
      }
    }
  }
  .activeItems {
    background-color: ${(props) => props?.theme?.colors?.primary};
    h5,
    svg {
      color: #ffffff !important;
    }
    img {
      filter: invert(84%) sepia(94%) saturate(0%) hue-rotate(349deg)
        brightness(108%) contrast(107%) !important;
    }
  }
  @media (max-width: 768px) {
    & {
      .icon {
        width: 80px;
        height: 80px;
      }
      .paymentMethod {
        width: 80%;
        height: 140px;
        padding-top: 0.5em;
        svg {
          height: 50px;
        }
        img {
          height: 80px;
        }
      }
      .IconTxtWraper {
        h5 {
          font-size: 1.3em;
        }
      }
    }
  }
  @media (max-width: 600px) {
    & {
      .paymentMethod {
        width: 100%;
        height: 130px;

        img {
          height: 65px;
        }
      }
      .IconTxtWraper {
        h5 {
          font-size: 1.2em;
        }
      }
    }
  }
  @media (max-width: 600px) {
    & {
      .paymentMethod {
        height: 110px;

        img {
          height: 50px;
        }
      }
      .IconTxtWraper {
        h5 {
          font-size: 1em;
        }
      }
    }
  }
`;
export const Buttons = styled("div")`
  position: fixed;
  width: 96%;
  margin-top: 0;
  // display: flex;
  // justify-content: flex-end;
  // gap: 1rem;
  z-index: 2;
  bottom: 1em;
  right: 0;
  left: 0;
  margin-top: 3em;

  .BackBtn {
    margin-left: auto;
  }
  .CheckoutBtn {
    margin-left: auto;
  }

  @media (max-width: 992px) {
    & {
      position: fixed;
    }
  }
`;

export const FlexContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  .activeItems {
    background-color: ${(props) => props?.theme?.colors?.primary};
    border: 1px solid ${(props) => props?.theme?.colors?.primary};
    color: #ffffff !important;
  }
`;
export const MemberId = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
  h5 {
    font-size: 2em;
  }
  div {
    font-size: 1.7em;
    margin-right: 3em;
  }
`;

export const StyledUsernameError = styled.div`
  background-color: #ff00002b !important;
  color: red !important;
  border: 2px solid red !important;
  display: block;
  width: 100%;
  height: 90px;
  font-size: 1.5em;
  border-radius: 50px;
  outline: none;
  padding: 25px;
  position: absolute;
  ::placeholder {
    color: red !important;
    text-align: center;
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
      height: 40px;
      padding: 15px;
      font-size: 1em;
    }
  }
`;
export const UserInput = styled("div")`
  position: relative;
  width: 100%;
`;
export const CustomOverlay = styled("div")`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  /* filter: blur(5px); */
  /* transition: 300ms; */
  opacity: 0.5;
`;
