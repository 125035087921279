import React, { useState, useDispatch } from "react";
import {
  AddBtn,
  ModalContent,
  SaucesCategory,
  SaucesContainer,
} from "./index.style";
import { Col, Row } from "antd";

const SaucesItemModal = ({ visible, setVisible, setSelectedOption }) => {
  const [selectedItems, setSelectedItems] = useState("");
  // const dispatch = useDispatch();
  const handleClick = (item) => {
    setSelectedOption(item?.categoryName);
  };
  const sauceData = [
    {
      image: "",
      categoryName: "Aioli",
    },
    {
      image: "",
      categoryName: "BBQ",
    },
    {
      image: "",
      categoryName: "Betroot",
    },
    {
      image: "",
      categoryName: "Chipotle",
    },
    {
      image: "",
      categoryName: "Ketchup",
    },
    {
      image: "",
      categoryName: "Peri Peri",
    },
    {
      image: "",
      categoryName: "",
    },
  ];
  // console.log("all items-------------", selectedItems);
  return (
    <>
      <SaucesContainer
        // title="Basic Modal"
        centered
        open={visible}
        footer={false}
        onCancel={setVisible}
      >
        <ModalContent>
          <h1>Please choose your sauce</h1>
          <Row justify="start" gutter={10}>
            {sauceData?.map((item, i) => (
              <Col span={6} key={i}>
                <SaucesCategory
                  style={{
                    backgroundColor: selectedItems === item ? "#528d24" : "",
                  }}
                  onClick={() => handleClick(item)}
                >
                  <div>
                    <img
                      draggable={false}
                      src="https://d1t329gmbwipcp.cloudfront.net/large/sodaclick_64c012d6ef89b.png"
                      alt=""
                    />
                  </div>
                  <h6>{item.categoryName}</h6>
                </SaucesCategory>
              </Col>
            ))}
          </Row>
          <AddBtn onClick={setVisible}>Done</AddBtn>
        </ModalContent>
      </SaucesContainer>
    </>
  );
};

export default SaucesItemModal;
