import { checkPosStatus } from "services/apis";

/** Return the date formate like this 12/12/2021 - 12:00
 * @param {Object} data
 */

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
}

// const inputDateString = "2023-12-14T09:42:47.268Z";
// const formattedDate = formatDate(inputDateString);

// console.log(formattedDate);

// const inputDateString = "2023-12-14T14:31:37.443Z";
// const formattedDate = formatDate(inputDateString);

// console.log(formattedDate);

/**
 * The function `isLive` checks the status of a terminal and returns the terminal number if it is
 * online, otherwise it returns a response message.
 * @param locations - The `locations` parameter is an array of locations that you want to check the POS
 * (Point of Sale) status for.
 * @returns either the terminal number if the request is successful and the terminal is online, or the
 * response message if either the request is not successful or the terminal is not online.
 */
export const isLive = async (locations) => {
  try {
    const res = await checkPosStatus(locations);
    const isRequestSuccessful = () =>
      res?.RESPONSE?.isRequestSuccessful && res?.RESPONSE?.terminalStatus;
    const isTerminalOnline = () =>
      res?.RESPONSE?.terminalStatus?.isTerminalOnline;
    const returnTerminalNumber = () =>
      res?.RESPONSE?.terminalStatus?.terminalNumber;
    const returnMessage = () => res?.RESPONSE?.responseMessage;

    if (isRequestSuccessful() && isTerminalOnline()) {
      return returnTerminalNumber();
    } else {
      return returnMessage();
    }
  } catch (error) {
    console.error("Error in isLive:", error);
    throw error; // Rethrow the error to propagate it to the caller
  } finally {
  }
};

