import React from "react";
import { isLive } from "screens/admin/helpers";

const useAdmin = () => {
  const fetLocationsStatus = async (locations) => {
    const posLocations = Object.keys(locations).map((key) => ({
      [key]: locations[key],
    }));

    const locationPromises = posLocations.map(
      (location) =>
        new Promise(async (resolve, reject) => {
          try {
            const key = Object.keys(location)[0];
            const res = await isLive([location[key]]);
            resolve(res); // Resolve with the result of isLive
          } catch (error) {
            // Handle errors if needed
            reject(error);
          }
        })
    );

    try {
      const results = await Promise.all(locationPromises);

      // Check if Any one of them locations is live
      const isLiveAnyLocation = results.some((result) => result !== undefined);

      return isLiveAnyLocation;
    } catch (error) {
      return false; // Return false if there's an error in any promise
    }
  };
  const checkIsLiveAnyLocation = async () => {
    const posData = localStorage.getItem("posData") || "{}";
    const parsedPosData = JSON.parse(posData || "{}");
    const {
      storeLocation,
      username,
      password,
      pairCode,
      address,
      phoneNumber,
      ...rest
    } = parsedPosData;
    return await fetLocationsStatus(rest);
  };
  return { checkIsLiveAnyLocation };
};
export default useAdmin;
