import styled from "styled-components";

export const OrderList = styled("div")``;
export const ShowPayment = styled("div")`
  position: fixed;
  right: 4%;
  left: 3%;
  z-index: 6;
  bottom: 103px;
  @media (max-width: 768px) {
    & {
      bottom: 60px;
      right: 0%;
      left: 0%;
    }
  }
`;
export const BtnsContent = styled("div")`
  padding: 0 1.5em 0.5em;
  max-width: 1080px;
  margin: 0 auto 0.5em;
  background-color: #fbf8ee;
  .totalCost {
    font-family: "HankenGrotesk-Regular";
  }
  strong {
    font-size: 1.9em;
    font-family: "Helvetica-bold";
  }
  span {
    font-size: 1.9em;
    color: black;
    font-family: "Helvetica-light";
  }
  @media (max-width: 992px) {
    strong {
      font-size: 1.4em;
    }
    span {
      font-size: 1.4em;
    }
  }
  @media (max-width: 600px) {
    strong {
      font-size: 0.9em;
    }
    span {
      font-size: 0.9em;
    }
  }
`;

export const CenteredContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const CenteredText = styled("div")`
  text-align: center;
  h2 {
    font-size: 2em;
  }
`;
export const ServiceFee = styled("div")`
  text-align: center;
  width: 30%;
  margin-left: 1.5em;
  text-align: start;
  div {
    display: flex;
    justify-content: space-between;
    div {
      font-size: 1.7em;
      width: 90px;
    }
  }
  h4 {
    font-size: 1.7em;
    margin-bottom: 0;
  }
`;

export const SelectOrderItems = styled("div")`
  height: 73vh;
  overflow-y: auto;
  padding-bottom: 4em;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  &::after {
    content: "";
    position: fixed;
    bottom: 269px;
    right: 0px;
    left: 0px;
    width: 100%; /* Adjust the width to control the fade length */
    height: 80px;
    background: linear-gradient(
      to bottom,
      #fbf8ee04,
      #fbf8ee99,
      #fbf8ee,
      #fbf8ee
    );
  }
`;
