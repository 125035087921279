import React from "react";
import {TxtWraper} from "../index.style";
import {Link} from "react-router-dom";
import Lottie from "react-lottie";
import loadingOptions from "../loading.json";

const defaultOptions = {
    loop: true, // Set to true if you want the animation to loop:
    autoplay: true, // Set to false if you don't want the animation to autoplay on render
    animationData: loadingOptions,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

export default function Waiting({route = "#"}) {
    return (
        <TxtWraper style={{justifyContent: "start", marginTop: "4em"}}>
            <h1>Processing</h1>
            <h2>Please Wait</h2>
            <div className="loadingIcon" style={{margin: "4em 0em"}}>
                <Link to={route}>
                    <Lottie options={defaultOptions}/>
                </Link>
            </div>
        </TxtWraper>
    );
}
