import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SaucesContainer = styled(Modal)`
  .ant-modal-content {
    background-color: #e3dac6;
    padding: 1em 1em 0;
    box-shadow: none;
    border-radius: 30px;
    text-align: center;
    font-weight: 700;
  }
  p {
    margin-bottom: 0;
    font-size: 3em;
  }
  h2 {
    margin-bottom: 0;
    font-size: 4em !important;
  }
  .ant-modal-close {
    display: none;
  }

  @media (max-width: 768px) {
    & {
      .ant-modal-content {
        max-width: 350px;
        margin: auto;
        .ant-modal-body {
          h1 {
            font-size: 1.6em !important;
          }
        }
      }
    }
  }
`;
