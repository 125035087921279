import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/animation/payment_enimation.json";
class RenderAnimation extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: this.props.animation,
      renderer: "svg",
    };
    return (
      <div>
        <Lottie options={defaultOptions} height={600} width={600} />
      </div>
    );
  }
}
export default RenderAnimation;
