import {GlobalStatesContext} from "context/GlobalStatesContext";
import React, {useContext} from "react";
import LoginModal from "./modals/login";
import useClearApp from "hooks/useClearApp";

export default function CountDown({setLanding}) {
    const {clearWholeApp} = useClearApp();

    const {enableCountdown, setEnableCountdown} =
        useContext(GlobalStatesContext);
    const handleCancelOrder = () => {
        clearWholeApp({goBack: false});
        setEnableCountdown(false);
    };
    // setLanding(true);
    console.log(enableCountdown, ' EnableCountdonwn')
    return (
        <>
            {enableCountdown && (
                <LoginModal visible={enableCountdown} setVisible={handleCancelOrder}/>
            )}
        </>
    );
}
