// {
//     "id": "t39kq18134553",
//     "amt": 18700,
//     "tax": 1760,
//     "dis": 650,
//     "sur": 374,
//     "items": [{
//         "id": "t39kq002",
//         "sku": "k24086723",
//         "qty": 2,
//         "amt": 2145,
//         "tax": 200,
//         "dis": 50,
//         "name": "XData USB Drive"
//     }, {
//         "id": "t39kq003",
//         "sku": "s23475697",
//         "qty": 1,
//         "amt": 8910,
//         "tax": 810,
//         "name": "MSoft OSuite",
//         "srl": "ms7843k346j23"
//     }, {
//         "id": "t39kq004",
//         "sku": "m47060855",
//         "qty": 5,
//         "amt": 1100,
//         "tax": 110,
//         "dis": 110,
//         "name": "A4 Notepad"
//     }]
// }
import { v4 as uuidv4 } from "uuid";
export const generateBaskit = (cartItems) => {
  const items = cartItems?.items?.map((item) => {
    const {
      _id,
      cost: amt,
      quantity: qty,
      itemName: name,
      skuCode: sku,
    } = item;
    return { _id, amt, qty, name, sku };
  });
  return { id: uuidv4(), amt: cartItems?.grandTotal, items };
};
