import styled from "styled-components";
export const brandingStyles = {
  colors: {
    primary: "#34B334",
  },
};

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: 0.1;
  filter: blur(1.4rem);
  position: absolute; // Add position for overlay to cover the whole screen.
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990; // Ensure it's on top of other elements
`;
