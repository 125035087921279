import React from "react";
import { ModalContent, SaucesContainer } from "./index.style";
import { Col, Row } from "antd";
import { BackBtn } from "common/index.style";

const SignOutModal = ({ visible, setVisible, title, handleYesSattlement }) => {
  return (
    <>
      <SaucesContainer
        // title="Basic Modal"
        centered
        open={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <ModalContent>
          <h1>{title}</h1>
          {title === "Are You Sure You Want To Settle" ? (
            <p>You Can Only Settle Once Every 24 Hours</p>
          ) : (
            ""
          )}
          <Row justify="start" gutter={10}>
            <Col span={12}>
              <BackBtn
                to="#"
                style={{ background: "#ffffff", color: "#000000" }}
                onClick={() => setVisible(false)}
              >
                Back
              </BackBtn>
            </Col>
            <Col span={12}>
              <BackBtn
                to="#"
                style={{ background: "#3CA3DC" }}
                onClick={() => handleYesSattlement()}
              >
                Yes
              </BackBtn>
            </Col>
          </Row>
        </ModalContent>
      </SaucesContainer>
    </>
  );
};

export default SignOutModal;
