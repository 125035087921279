import React, { useContext, useEffect } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cancel } from "common/index.style";
import { Col, Row } from "antd";
import cancelOptionsData from "./cancel.json";
import Lottie from "react-lottie";
import {
  OrderNumContainer,
  QRCode,
  Receipt,
} from "screens/completeOrders/index.style";
import { GlobalStatesContext } from "context/GlobalStatesContext";

const defaultOptions = {
  loop: true, // Set to true if you want the animation to loop
  autoplay: true, // Set to false if you don't want the animation to autoplay on render
  animationData: cancelOptionsData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const FailedError = ({ showPayment, cancelOrder }) => {
  const { globalState, setGlobalState } = useContext(GlobalStatesContext);
  const { state } = useLocation();
  const { tryAgain = true, statusCode } = state || {};
  // console.log(state, "ssss");
  const navigate = useNavigate();
  const handleOk = (e) => {
    e.preventDefault();
    setGlobalState((prev) => {
      return { ...prev, [statusCode]: { count: 1 } };
    });
    navigate("/instructions");
  };

  return (
    <>
      <OrderNumContainer>
        <Receipt>
          <h4 style={{ fontSize: "3.5em" }}>Transaction</h4>
          <h3 style={{ marginBottom: "2em", fontSize: "7.1em" }}>Failed</h3>
        </Receipt>
        <QRCode onClick={() => navigate("/")}>
          <Lottie options={defaultOptions} height={350} width={350} />
        </QRCode>
        {(tryAgain && (
          <>
            <p>Please Try Again</p>
            <Row gutter={20}>
              <Col span={12}>
                <Cancel
                  style={{ fontSize: "1.9em" }}
                  to="/"
                  onClick={cancelOrder}
                >
                  Cancel
                </Cancel>
              </Col>
              <Col span={12}>
                <Cancel
                  // to="#"
                  onClick={handleOk}
                  style={{
                    fontSize: "1.9em",
                    background: "#518E24",
                    color: "#ffffff",
                  }}
                >
                  Ok
                </Cancel>
              </Col>
            </Row>
          </>
        )) || <p>Please Use Another Order Point</p>}
      </OrderNumContainer>
    </>
  );
};

export default FailedError;
