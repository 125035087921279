// import { useState, useEffect, useContext } from "react";
// import { GlobalStatesContext } from "context/GlobalStatesContext";

// const useApiConfig = () => {
//   const { globalState } = useContext(GlobalStatesContext);
//   // Base URLs for both development and production
//   const baseDevUrl = "https://auth.sandbox.cloud.pceftpos.com";
//   const baseProdUrl = "https://auth.cloud.pceftpos.com";
// // "https://auth.sandbox.cloud.pceftpos.com/v1/pairing/cloudpos"
// // "https://rest.pos.sandbox.cloud.pceftpos.com/v1/sessions/";
//   // Function to get the appropriate API URL based on the environment
//   const getApiUrl = (path) => {
//     const baseUrl = globalState?.isDev ? baseDevUrl : baseProdUrl;
//     return `${baseUrl}${path}`;
//   };

//   // API paths
//   const pairingPath = getApiUrl("/v1/pairing/cloudpos");
//   const tokenRequestPath = getApiUrl("/v1/tokens/cloudpos");
//   const linklyEndpoint = getApiUrl("/v1/sessions/");

//   // Other constants
//   const posName = "Oliver's POS";
//   const posVersion = "1.0.0";
//   const posId = "f818d27b-c98c-4007-97f5-6eb173bb7d9b";
//   const posVendorId = "482c12c7-4506-482e-a05a-761c113c9a40";
//   const headers = {
//     "Content-Type": "application/json",
//     Accept: "application/json",
//   };
//   console.log(globalState, ' ....globalStaete...')
//   return {
//     pairingPath,
//     tokenRequestPath,
//     linklyEndpoint,
//     posName,
//     posVersion,
//     posId,
//     posVendorId,
//     headers,
//   };
// };

// export default useApiConfig;
import { useContext } from "react";
import { GlobalStatesContext } from "context/GlobalStatesContext";

const useApiConfig = () => {
  const { globalState } = useContext(GlobalStatesContext);

  // Base URLs for both development and production
  const baseDevUrl = "https://auth.sandbox.cloud.pceftpos.com";
  const baseProdUrl = "https://auth.cloud.pceftpos.com";
  const sessionBaseDevUrl = "https://rest.pos.sandbox.cloud.pceftpos.com";
  const sessionBaseProdUrl = "https://rest.pos.cloud.pceftpos.com";
  // Function to get the appropriate API URL based on the environment
  const getApiUrl = (path) => {
    const baseUrl = globalState?.isDev ? baseDevUrl : baseProdUrl;

    // Adjust the URL for the "/v1/sessions/" endpoint
    if (path === "/v1/sessions/") {
      return `${
        globalState?.isDev
          ? sessionBaseDevUrl + "/v1/sessions/"
          : sessionBaseProdUrl + "/v1/sessions/"
      }`;
    }

    return `${baseUrl}${path}`;
  };

  // API paths
  const pairingPath = getApiUrl("/v1/pairing/cloudpos");
  const tokenRequestPath = getApiUrl("/v1/tokens/cloudpos");
  const linklyEndpoint = getApiUrl("/v1/sessions/");

  // Other constants
  const posName = "Oliver's POS";
  const posVersion = "1.0.0";
  const posId = "f818d27b-c98c-4007-97f5-6eb173bb7d9b";
  const posVendorId = "482c12c7-4506-482e-a05a-761c113c9a40";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return {
    pairingPath,
    tokenRequestPath,
    linklyEndpoint,
    posName,
    posVersion,
    posId,
    posVendorId,
    headers,
  };
};

export default useApiConfig;
