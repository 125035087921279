import { useContext, useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import AdminHeader from "../components/AdminHeader";
import { Wrapper } from "./index.style";
// import { useAdminTransection } from "context/features/admin/AdminTransectionsContext";
import { useAdminTransection } from "hooks/admin/useAdminTransection";
import { keysOf } from "utils/helper";

import { COLUMNS } from "constants/ADMIN";
import usePOS from "hooks/usePOS";
import { useNavigate } from "react-router-dom";
import { storeLinklyData } from "screens/settings/helpers/SecretManager";
import SignOutModal from "../components/signoutModal";
import { formatDate, formateDate } from "../helpers";
import { Status } from "../posData/index.style";
import Logout from "../logout/Logout";
import { GlobalStatesContext } from "context/GlobalStatesContext";
import { Overlay } from "react-bootstrap";
import { toast } from "react-toastify";

const Transactions = () => {
  const [openSignOutModal, setOpenSignOutModal] = useState(false);
  const [openSettleModal, setOpenSettleModal] = useState(false);
  const {
    transections,
    clearTransections,
    isRefundingUnderProcess,
    startRefunding,
  } = useAdminTransection();
  const { globalState, setGlobalState } = useContext(GlobalStatesContext);

  console.log(transections, " transection");
  const {
    sendPairRequest,
    paired,
    refund,
    setPaired,
    secret,
    token,
    tokenExpiry,
    getToken,
    sattlement,
  } = usePOS();

  // console.log(transections, " transections...");
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setOpenSettleModal(false);
    // setOpenSignOutModal(false);
  };

  useEffect(() => {
    // console.log("SECRET NOTED BY EFFECT");
    if (secret) {
      console.log("SECRET NOT NULL... STORING...");
      storeLinklyData("secret", secret);
      if (!token || tokenExpiry <= Date.now()) {
        // console.log("GETTING TOKEN");
        getToken(secret);
      } else {
        // console.log("SETTING PAIRED");
        setPaired(true);
      }
    }
  }, [secret]);

  const handleSignout = (state) => {
    if (state) {
      setGlobalState((prev) => {
        return { ...prev, closeAdmin: false };
      });
      navigate("/");
    } else {
      setGlobalState((prev) => {
        return { ...prev, closeAdmin: false };
      });
    }
  };
  useEffect(() => {
    if (globalState?.closeAdmin === false || globalState?.closeAdmin === true) {
      setOpenSignOutModal(globalState?.closeAdmin);
    }
  }, [globalState?.closeAdmin]);

  const [startSattlement, setStartSattlement] = useState(false);
  const [lastSettlementTime, setLastSettlementTime] = useState(
    localStorage.getItem("lastSettlementTime") || null
  );
  const handleYesSattlement = async () => {
    const now = new Date().getTime();

    if (
      !lastSettlementTime ||
      now - lastSettlementTime >= 24 * 60 * 60 * 1000
    ) {
      // Perform the settlement logic here
      try {
        setStartSattlement(true);
        await sattlement();

        clearTransections();
        localStorage.removeItem("transectionUnderProcess");
        localStorage.removeItem("transections");

        toast.success("Settlement has been successfully completed.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setStartSattlement(false);
        setOpenSettleModal(false);
      } catch (e) {
        toast.error("There is something wrong with settlement.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        localStorage.removeItem("transections");
        setStartSattlement(false);
        setOpenSettleModal(false);
      }
      // Update the last settlement time in localStorage
      localStorage.setItem("lastSettlementTime", now);
      setLastSettlementTime(now);
    } else {
      // Alert the user that they can only settle once in 24 hours
      toast.error("You can only settle once in 24 hours.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setStartSattlement(false);
      setOpenSettleModal(false);
    }
  };

  return (
    <Wrapper>
      {startSattlement && <Overlay />}
      <Logout open={openSignOutModal} handleSignout={handleSignout} />
      {/* <SignOutModal visible={openSignOutModal} setVisible={handleCloseModal} /> */}
      <SignOutModal
        handleYesSattlement={handleYesSattlement}
        visible={openSettleModal}
        setVisible={handleCloseModal}
        title="Are You Sure You Want To Settle"
      />

      <div className="content-header">
        <h1>Transaction Status</h1>
        <Status status={paired}>
          <span className="dot"></span>
          <p>{(paired && "Online") || "Offline"}</p>
        </Status>
        <div className="settlement" onClick={() => setOpenSettleModal(true)}>
          <h2>Settlement</h2>
          <p>$0.00</p>
        </div>
      </div>
      <div className="search">
        <input type="text" placeholder="Search" />
        <GoSearch />
      </div>

      {/* table */}
      <div className="transactionTable">
        <table>
          {keysOf(transections).map((transection) => {
            return keysOf(transection).map((key) => {
              return <tr>{key}</tr>;
            });
          })}
          {/* Columns */}
          <tr>
            {keysOf(COLUMNS)?.map((column) => {
              return <th>{column}</th>;
            })}
          </tr>
          {/* <tr> */}
          {Object.keys(transections)
            .reverse()
            .map((transection) => {
              if (transection !== "undefined" && transection) {
                return (
                  <tr>
                    {Object.keys(COLUMNS).map((column) => {
                      const field = transections[transection]?.[column];
                      const transectionResponse = transections[transection];
                      const {
                        txnRef,
                        refunded,
                        sessionID,
                        isRefund,
                        AmtPurchase,
                      } = transections[transection];

                      const isStatus = () =>
                        [
                          "DECLINED",
                          "APPROVED",
                          "PENDING",
                          "Refund",
                          "REFUND",
                        ].includes(field);

                      const isRefundColumn = () => field && column == "Refund";
                      // const isRefundStatus = () => field && field == "REFUND";
                      const isDate = () => field && column == "Date/Time";
                      const className =
                        field === "DECLINED"
                          ? "decline"
                          : field === "APPROVED"
                          ? "approved"
                          : field === "PENDING"
                          ? "pending"
                          : field === "REFUND"
                          ? "refunded"
                          : column == "Refund"
                          ? "refund"
                          : "";
                      return isStatus() ? (
                        <td key={column}>
                          <span className={className}>{field}</span>
                        </td>
                      ) : isRefundColumn() ? (
                        <td
                          key={column}
                          onClick={() => {
                            // If transection not refuned.
                            if (
                              !refunded &&
                              !isRefundingUnderProcess(sessionID)
                            ) {
                              setTimeout(() => {
                                startRefunding(sessionID);
                                refund({
                                  txnRef,
                                  transectionResponse,
                                  sessionID,
                                });
                              }, 300);
                              navigate("/instructions");
                            }
                          }}
                        >
                          <span
                            style={{ background: refunded ? "gray" : "" }}
                            className={className}
                          >
                            Refund
                          </span>
                        </td>
                      ) : field && isRefund ? (
                        <td>
                          {/* If field is data then format this. */}
                          {isDate(field)
                            ? formatDate(field)
                            : field.toString() || (
                                <span className={className}>
                                  {field.toString()}
                                </span>
                              )}
                        </td>
                      ) : field ? (
                        <td key={column}>
                          {isDate(field) ? formatDate(field) : field.toString()}
                        </td>
                      ) : (
                        <td key={column}>NA</td>
                      );
                    })}
                  </tr>
                );
              } else {
                return "";
              }
            })}
          {/* </tr> */}
        </table>
      </div>
    </Wrapper>
  );
};

export default Transactions;
