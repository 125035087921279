import React, {useEffect, useRef} from 'react';
import {useLocation} from "react-router-dom";

const SplashScreen = ({closeLanding, className}) => {
    const iframeRef = useRef(null);
    const location = useLocation();

    const handleOverlayClick = () => {
        closeLanding();
    };

    // CLEAR THE COUNTDOWN IF THE PATH IS ROOT.
    useEffect(() => {
        if (location.pathname === '/' && localStorage.getItem('timeoutID')) {
            clearTimeout(Number(localStorage.getItem('timeoutID')));
            localStorage.removeItem('timeoutID');
        }
    }, [location.pathname]);

    return (
        <div id="iframeContainer"

             style={{
                 position: 'absolute', width: '100vw', height: '100vh', top: 0, zIndex: 9999,
                 display: className === 'hide' ? 'none' : ''
             }}>
            <iframe

                id="iframe_sodaclick_design"
                ref={iframeRef}
                src="https://html5.sodaclick.com/playlist/TGVrYUNjVHpldjJFaG93R2ZHV2sxM1J1MzV0UEVnWWgvU0d2N0c3MWh5VT0=/playlist?id=1&playlist=true&pwa=true"
                style={{
                    // position: 'relative',
                    width: '100vw',
                    height: '100%',
                    border: 'none',
                    zIndex: 1000,
                    top: 0,
                    margin: 0,
                    padding: 0,
                    left: 0,
                }}
            ></iframe>
            <div onClick={handleOverlayClick}
                 style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer'}}
            ></div>
        </div>
    );
};

export default SplashScreen;
