export const CATAGORIES = Object.freeze({
  COLD_DRINKS: "Cold Drinks",
  COFFEE: "Coffee",
  SMOOTHIE: "Smoothie",
  BREAKFAST: "Breakfast",
  COLD_DRINKS: "Cold Drinks",
  LUNCH: "Lunch & Evening",
  GRAB_AND_GO: "Grab and Go",
  KIDS: "Kids",
  BAKERY: "Bakery",
  SNACKS: "Snacks",
});
