import React, {useContext, useEffect, useRef, useState} from "react";

import KioskBoard from "kioskboard";

import {
    Container,
    CustomOverlay,
    FlexContainer,
    MemberId,
    Product1,
    StyledUsernameError,
    Total,
    UserInput,
    Wrapper,
} from "../payOrder/index.style";
import {Heading, LeftSection, PaymentCard, PaymentMethod, RightSection, UserName,} from "./index.style";
import Logo from "./Logo";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "antd";
import {selectCalculateTotal, selectGetCartItems, updateGrandTotal,} from "../../Redux/cartSlice";
import {SelectedItems} from "../../components/allItems/selectOrder/index.style";
import {ServiceFee} from "../yourOrder/index.style";
import {
    calGrandTotal,
    calServiceFeeByPercent,
    calSubTotal,
    getServiceChargePercentage,
} from "screens/yourOrder/helper";
import {applyFixed, not} from "utils/helper";
import {CancelBtn} from "components/tabs/index.style";
import CancelOrderModal from "components/modals/orderCancel";
import useClearApp from "hooks/useClearApp";
import {useNavigate} from "react-router-dom";
// Custom hooks
import {BarcodeScanner} from "./Zxing";
import usePOS from "hooks/usePOS";
import {generateBaskit} from "screens/settings/helpers";
import {v4 as uuidv4} from "uuid";
import {GlobalStatesContext} from "context/GlobalStatesContext";
import {isExpo} from "../../config";
import {isScannerEnable} from "./helper";

const Order = () => {
    const keyboardRef = useRef(null);
    const {purchase} = usePOS();
    // Scanner
    const [scannedCode, setScannedCode] = useState(null);
    const [isScaning, setIsScaning] = useState(false);
    const {globalState} = useContext(GlobalStatesContext);
    const dispatch = useDispatch();
    const items = useSelector(selectGetCartItems);
    // const total = useSelector(selectGetTotal);
    const total = useSelector(selectCalculateTotal);

    const {clearWholeApp} = useClearApp();
    const [selectGiftCard, setSelectGiftCard] = useState(false);
    const [selectMemberCard, setSelectMemberCard] = useState(false);
    const [selectCreditCard, setSelectCreditCard] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);
    const [memberId, setMemberId] = useState(false);
    const [username, setUsername] = useState("");
    const [showError, setShowError] = useState(false);
    // height
    const ref = React.useRef(null);
    const [height, setHeight] = useState(500);

    useEffect(() => {
        if (keyboardRef.current) {
            keyboardRef.current.addEventListener("change", handleChnageUsername);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            if (keyboardRef.current) {
                keyboardRef.current.removeEventListener("change", handleChnageUsername);
            }
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("scannedCode", scannedCode);
    }, [scannedCode]);

    /** Update the grandTotal inside the Cartslice */
    useEffect(() => {
        dispatch(
            updateGrandTotal(
                applyFixed(
                    calGrandTotal(
                        total,
                        calServiceFeeByPercent(total, getServiceChargePercentage())
                    ),
                    2
                )
            )
        );
    }, [total]);
    const navigate = useNavigate();

    const showCancelOrder = () => {
        setCancelOrder(true);
    };
    const handleCancelMember = () => {
        setMemberId(false);
    };
    const handleCancelOrder = () => {
        clearWholeApp({goBack: false});
        setCancelOrder(false);
    };

    // username
    const handleChnageUsername = ({target: {name, value}}) => {
        setUsername(() => value);
    };

    const handleSelectCard = () => {
        setSelectCreditCard(true);
        setSelectMemberCard(false);
        setSelectGiftCard(false);
    };

    const handleSelectGiftCard = () => {
        setSelectCreditCard(false);
        setSelectMemberCard(false);
        setSelectGiftCard(true);
    };

    const handleSelectMemberCard = () => {
        navigate("/");
    };

    useEffect(() => {
        if (keyboardRef.current) {
            KioskBoard.run(keyboardRef.current, {
                language: "en",
                theme: "dark",
                keysArrayOfObjects: [
                    {
                        0: "Q",
                        1: "W",
                        2: "E",
                        3: "R",
                        4: "T",
                        5: "Y",
                        6: "U",
                        7: "I",
                        8: "O",
                        9: "P",
                    },
                    {
                        0: "A",
                        1: "S",
                        2: "D",
                        3: "F",
                        4: "G",
                        5: "H",
                        6: "J",
                        7: "K",
                        8: "L",
                    },
                    {
                        0: "Z",
                        1: "X",
                        2: "C",
                        3: "V",
                        4: "B",
                        5: "N",
                        6: "M",
                    },
                ],
            });
        }
    }, [keyboardRef]);
    const {cartData} = useSelector((state) => state?.cart);
    const txnRef = uuidv4().slice(-16);

    // Log the current globalState for child product
    console.log(globalState, "glogalState");
    console.log(cartData, "cartData");

    const handlePay = () => {
        if (isExpo) {
            navigate("/instructions");
        } else {
            if (username) {
                localStorage.setItem("username", username);
                setTimeout(() => {
                    debugger;
                    purchase({
                        amtPurchase: Number(applyFixed(cartData?.grandTotal * 100, 0)),
                        basket: generateBaskit(cartData),
                        txnRef,
                    });
                }, 1000);
                navigate("/instructions");
            } else {
                setShowError(true);
            }
        }
    };

    return (
        <>
            <Wrapper>
                {/* <Header /> */}
                {isScaning ? <CustomOverlay/> : ""}
                <Logo/>
                <CancelOrderModal
                    visible={cancelOrder}
                    setVisible={setCancelOrder}
                    handleCancelOrder={handleCancelOrder}
                />
                <FlexContainer>
                    <div>
                        <Heading style={{marginBottom: "0.3em"}}>Order</Heading>
                        {/* <h3>Loyalty Number</h3> */}
                    </div>
                    <div style={{textAlign: "center"}}>
                        <CancelBtn
                            onClick={showCancelOrder}
                            style={{
                                height: "48px",
                                marginTop: "1.5em",
                            }}
                        >
                            Cancel Order
                        </CancelBtn>
                        {/* <p>1234567890</p> */}
                    </div>
                </FlexContainer>

                <PaymentCard height={height}>
                    {items?.map((item) => (
                        <>
                            <Product1>
                                <LeftSection>
                                    <h2>{item?.itemName}</h2>
                                    <p>{item?.size !== "NA" ? item?.size : ""}</p>
                                    {Object.keys(
                                        item?.items || item?.product?.items || item?.items || {}
                                    )?.map((elm) => {
                                        const {displayItemName, itemCost} = item?.items[elm];
                                        return (
                                            <SelectedItems>
                                                <p>{displayItemName}</p>
                                                <p>{itemCost}</p>
                                            </SelectedItems>
                                        );
                                    })}
                                </LeftSection>
                                <RightSection>
                                    <h2>${item?.total?.toFixed(2) || 10}</h2>
                                </RightSection>
                            </Product1>
                        </>
                    ))}
                    <ServiceFee
                        style={{
                            width: "94.5%",
                            position: "absolute",
                            bottom: "75px",
                            left: "1%",
                        }}
                    >
                        <div>
                            <h4>Sub Total</h4>
                            <div style={{width: "unset"}}>
                                ${applyFixed(calSubTotal(total), 2)}
                            </div>
                        </div>
                        <div>
                            <h4>Surcharge</h4>
                            <div style={{width: "unset"}}>
                                $
                                {applyFixed(
                                    calServiceFeeByPercent(total, getServiceChargePercentage()),
                                    2
                                )}
                            </div>
                        </div>
                    </ServiceFee>
                    <Total>
                        <h2>Total:</h2>
                        <h2>
                            $
                            {applyFixed(
                                calGrandTotal(
                                    total,
                                    calServiceFeeByPercent(total, getServiceChargePercentage())
                                ),
                                2
                            )}
                        </h2>
                    </Total>
                </PaymentCard>

                <UserName>
                    <FlexContainer>
                        <Heading>Your Name</Heading>
                        {
                            isScannerEnable() && (
                                <CancelBtn
                                    className={scannedCode ? `activeItems` : ``}
                                    style={{
                                        height: "48px",
                                        maxWidth: "100%",
                                        marginTop: "0em",
                                    }}
                                    onClick={() => setIsScaning(true)}
                                >
                                    Add Member Number
                                </CancelBtn>
                            )
                        }

                        {isScaning && (
                            <BarcodeScanner
                                isScaning={isScaning}
                                setIsScaning={setIsScaning}
                                setScannedCode={setScannedCode}
                            />

                        )}
                    </FlexContainer>
                    <UserInput>
                        {not(username) && showError && (
                            <StyledUsernameError
                                onClick={() => setShowError(false)}
                                // type="text"
                                data-kioskboard-type="keyboard"
                                // placeholder="Please enter your name"
                            >
                                Please enter your name
                            </StyledUsernameError>
                        )}
                        <input
                            className="inputFromKey"
                            style={{
                                visibility: (showError && "hidden") || "visible",
                            }}
                            name="username"
                            ref={keyboardRef}
                            type="text"
                            data-kioskboard-type="keyboard"
                            value={username}
                            placeholder="Enter your name"
                        />
                    </UserInput>
                    <MemberId>
                        <h5>{scannedCode ? "Member Number" : ""}</h5>{" "}
                        <div>{scannedCode}</div>
                    </MemberId>
                </UserName>
                <PaymentMethod>
                    {/* <Heading>Payment Method</Heading> */}
                    <Container>
                        <Row gutter={26} justify="space-between">
                            <Col xs={{span: 8}} md={{span: 12}}>
                                <div
                                    className={`paymentMethod`}
                                    onClick={() => navigate("/your_order")}
                                >
                                    <div className="IconTxtWraper">
                                        <h5>Back</h5>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={{span: 8}} md={{span: 12}}>
                                <div
                                    id="payOrder"
                                    className={`paymentMethod activeItems`}
                                    onClick={() => handlePay()}
                                    style={{marginLeft: "auto"}}
                                >
                                    <div className="IconTxtWraper">
                                        <h5>Pay</h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </PaymentMethod>

                {/* <Footer /> */}
            </Wrapper>
        </>
    );
};

export default Order;
