import React from "react";
import CardItems from "../../common/products";

const PopularItems = ({ setAllCount, listItems, setListItems, popularData }) => {
  return (
    <div className="popularContainer">
      <CardItems
        titleName="Popular"
        setAllCount={setAllCount}
        listItems={listItems}
        formatedMenuData={popularData}
        setListItems={setListItems}
        parentId="popular"
      />
    </div>
  );
};

export default PopularItems;
