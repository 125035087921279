import React, { createContext, useState } from "react";

// Create a context to manage the loaded state
export const PosContext = createContext();

function PosProvider({ children }) {
  const [token, setToken] = useState();
  const [tokenExpiry, setTokenExpiry] = useState();
  const [secret, setSecret] = useState();
  const [txnResponse, setTxnResponse] = useState();
  const [shift8Response, setShift8Response] = useState();
  const [txnInProgress, setTxnInProgress] = useState();
  const [lastSessionId, setLastSessionId] = useState(null);
  const [transectionSuccessed, setTransectionSuccesed] = useState(false);
  // Error recovery
  const [enterToRecovery, setEnterToRecovery] = useState(false);
  const [transactionStarted, setTransactionStarted] = useState(false);

  return (
    <PosContext.Provider
      value={{
        token,
        setToken,
        tokenExpiry,
        setTokenExpiry,
        secret,
        setSecret,
        txnResponse,
        setTxnResponse,
        shift8Response,
        setShift8Response,
        txnInProgress,
        setTxnInProgress,
        lastSessionId,
        setLastSessionId,

        enterToRecovery,
        setEnterToRecovery,
        transactionStarted,
        setTransactionStarted,
        transectionSuccessed,
        setTransectionSuccesed,
      }}
    >
      {children}
    </PosContext.Provider>
  );
}

export default PosProvider;
