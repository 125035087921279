import React, {useEffect} from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const tooltip = (
    <Tooltip id="tooltip">
        <strong>Reload</strong> For updating.
    </Tooltip>
);
const AppVersionDisplay = () => {
    const currentVersion = JSON.parse(localStorage.getItem('currentVersion') || "{}");
    const latestVersion = JSON.parse(localStorage.getItem('latestVersion') || "{}");

    const isUpToDate = currentVersion?.version === latestVersion?.version;

    return (
        <div style={{display: 'flex', textAlign: 'center', flexDirection: 'column', color: '#fff', fontSize: '1.2rem'}}>
            <p style={{marginBottom: 0}}>{isUpToDate ? 'Your App is up to date' : 'New version available'}</p>
            <p style={{marginBottom: 0}}>
                Version {currentVersion?.version} (Official Build)
                {isUpToDate ? null : (
                    <OverlayTrigger placement="bottom" overlay={tooltip}>
                        <span style={{
                            color: 'red',
                            marginLeft: '0.5rem',
                            background: '#fff',
                            padding: "5px 10px",
                            borderRadius: 30
                        }}>{latestVersion?.version}</span></OverlayTrigger>
                )}
            </p>
        </div>
    );
};


export default AppVersionDisplay;
