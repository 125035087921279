import {applyFixed, calculatePercentage, keysOf} from "utils/helper";
import {v4 as uuidv4} from "uuid";

export const calSubTotal = (total = 0) => {
    total = Number(total);
    return total;
};

export const calGrandTotal = (total = 0, percent = 0) => {
    total = Number(total);
    return total + percent;
};

export const calServiceFeeByPercent = (total = 0, percent = 0) => {
    total = Number(total);
    const calculatePercent = calculatePercentage(percent);
    return calculatePercent(total);
};

export function getServiceChargePercentage() {
    // const currentDate = new Date();
    // const currentYear = currentDate.getFullYear();
    // const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
    // const currentDay = currentDate.getDate();
    //
    // const targetDate1 = new Date("2023-12-26");
    // const targetYear1 = targetDate1.getFullYear();
    // const targetMonth1 = targetDate1.getMonth() + 1;
    // const targetDay1 = targetDate1.getDate();
    //
    // const targetDate2 = new Date("2024-01-01");
    // const targetYear2 = targetDate2.getFullYear();
    // const targetMonth2 = targetDate2.getMonth() + 1;
    // const targetDay2 = targetDate2.getDate();
    //
    // return (currentYear === targetYear1 && currentMonth === targetMonth1 && currentDay === targetDay1) || (currentYear === targetYear2 && currentMonth === targetMonth2 && currentDay === targetDay2) ? 10 : 1;
    const posData = JSON.parse(localStorage.getItem('posData') || "{}");
    return posData?.surcharge;
}

const getFromModifiers = (cartItem) => {
    const currentItemModifires = cartItem?.modifiers?.[0];
    const constrains = ["default", "max", "min", "price_option"];
    const selectedModifires = cartItem?.items;
    const getExtraInstructions = (properties = []) => {
        return keysOf(selectedModifires)
            .map((modifire) => {
                if (properties?.includes(modifire)) {
                    // Selected Items again selectd Modifires: Example: Options: [item1, item2, item3]
                    const selectedItems = keysOf(selectedModifires[modifire]);
                    const items = currentItemModifires[modifire];
                    return keysOf(items)
                        ?.map((item) => {
                            if (!constrains.includes(item) && selectedItems?.includes(items[item]?.displayItemName)) {
                                const currentItem = items[item];
                                return {
                                    productCode: currentItem?.productCode,
                                    productName: currentItem?.displayItemName,
                                    pluCodes: [currentItem?.productPlu],
                                    skuCodes: [currentItem?.productPlu],
                                    productPrice: [currentItem?.itemCost],
                                    quantity: "na",
                                    itemInstruction: true,
                                    productType: false,
                                };
                            }
                        })
                        .filter((item) => item !== undefined);
                }
            })
            .filter((item) => item !== undefined)
            .flat();
    };
    const getProductUpdates = (properties = []) => {
        return keysOf(selectedModifires)
            .map((modifier) => {
                if (properties.includes(modifier)) {
                    // Selected Items again selectd Modifires: Example: Options: [item1, item2, item3]
                    const selectedItems = keysOf(selectedModifires[modifier]);
                    const items = currentItemModifires[modifier];
                    return keysOf(items)
                        .map((item) => {
                            if (!constrains.includes(item) && selectedItems?.includes(items[item]?.displayItemName)) {
                                const currentItem = items[item];
                                return {
                                    productCode: currentItem?.productCode,
                                    productName: currentItem?.displayItemName,
                                    pluCodes: [currentItem?.productPlu],
                                    skuCodes: [currentItem?.productPlu],
                                    productPrice: [currentItem?.itemCost],
                                    quantity: 1,
                                };
                            }
                        })
                        .filter((item) => item !== undefined);
                }
            })
            .filter((item) => item !== undefined)
            .flat();
    };

    const getOtherItems = (properties = [], cartItem) => {

        return keysOf(selectedModifires)
            .map((modifier) => {

                if (properties.includes(modifier)) {

                    // Selected Items again selectd Modifires: Example: Options: [item1, item2, item3]
                    const selectedItems = keysOf(selectedModifires[modifier]);
                    const items = currentItemModifires[modifier];

                    return keysOf(items)
                        .map((item, index) => {

                            if (!constrains.includes(item) && selectedItems?.includes(items[item]?.displayItemName)) {
                                const currentItem = items[item];
                                // If Item is not Coffee. Because Coffee Is part of Meals and will be available in childs object.
                                if (currentItem?.productCode !== 'MP-883803') {
                                    return {
                                        ...cartItem,
                                        extraInstructions: [],
                                        productUpdates: [],
                                        productName: currentItem?.posItemName,
                                        productCode: currentItem?.productCode,
                                        pluCodes: [currentItem?.productPlu],
                                        skuCodes: [currentItem?.productPlu],
                                        productPrice: [currentItem?.itemCost],
                                        index,
                                    };
                                }
                            }
                        })
                        .filter((item) => item !== undefined);
                }
            })
            .filter((item) => item !== undefined)
            .flat();
    };

    // const getOtherItems = (properties = [], cartItem) => {
    //   return keysOf(selectedModifires)
    //     .map((modifier, index) => {
    //       if (properties.includes(modifier)) {
    //         const selectedItems = keysOf(selectedModifires[modifier]);
    //         const items = currentItemModifires[modifier];
    //         // const item = currentItemModifires[modifier];
    //         // const currentItem = items[item];
    //         debugger;
    //         return {
    //           ...cartItem,
    //           productCode: item?.productCode,
    //           pluCodes: [item?.productPlu],
    //           skuCodes: [item?.productPlu],
    //           productPrice: [item?.itemCost],
    //           index,
    //         };
    //       }
    //     })
    //     .filter((item) => item !== undefined);
    // };
    return {getExtraInstructions, getProductUpdates, getOtherItems};
};

/** Generate baskit unique id and set to all items.
 * @param {Array} cartItems.
 * @returns {Array}
 */
export const generateCartData = (cartItems = []) => {

    const {stringify} = JSON;
    const sixDigitID = uuidv4().slice(-6);
    return cartItems?.map((item, index) => {
        // This code is responsible to collection the item id and find the actual orignal price without extras price
        // The reason is Shift 8 SDK add extras price by itself.
        const {getExtraInstructions, getProductUpdates, getOtherItems} = getFromModifiers(item);
        const obj = {
            cart_id: sixDigitID,
            tagID: Number(uuidv4().slice(1, 14)),
            imageSrc: item?.imageSrc,
            productName: item?.displayName,
            index: Number(index),
            skuCodes: [item?.pluCode],
            pluCodes: [item?.pluCode],
            productCode: item?.productCode,
            productPrice: [applyFixed(Number(item?.originalPriceOfItem || item?.actualCost || item?.cost), 2),],
            quantity: Number(item?.quantity),
            productType: stringify(true),
            extraInstructions: getExtraInstructions(["Remove", "Ingredients", "Options", "Option"]) || [],
            productUpdates: [],
            itemSizeOption: stringify(null),
            itemOption: stringify(true),
            itemGroup: item?.category,
            productSize: [item?.size],
        };

        obj.otherItems = getOtherItems(["Sides", "Side", "Drinks"], obj) || [];
        obj.productUpdates = getProductUpdates(["Extras", "Sauces", "Milk", "Type", "Syrups", "Sugars", "Strength", // "Coffee",
        ]) || [];
        debugger
        console.log(getOtherItems(["Sides", "Side", "Drinks"], obj) || [], ' otherItems')
        if (Object.keys(item?.childs || {}).length) {

            const childObjects = item?.childs || {};
            let sizeMap = {'Small': 0, 'Medium': 1, 'Large': 2};
            // Mapping accordingly of Parent size, Example is parent size is large than the child size have to be large.
            let cartItems = Object.keys(childObjects)?.map((key) => childObjects?.[key]) || [];
            cartItems = cartItems.map(catItem => {
                let sizeIndex = sizeMap[obj?.productSize[0]];
                return {...catItem, ...(catItem.itemSize[sizeIndex]), cost: catItem?.cost, quantity: obj?.quantity}
            });

            const childItems = generateCartData(cartItems);

            obj.otherItems = [...obj.otherItems, ...childItems];
        }
        return obj;
    });
};
