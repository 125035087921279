import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const SaucesContainer = styled(Modal)`
  .ant-modal-content {
    background-color: #e3dac6;
    padding: 1em 1em 0;
    box-shadow: none;
    border-radius: 30px;
    text-align: center;
    font-weight: 700;
    .ant-modal-body {
      h1 {
        font-weight: 700;
        margin-bottom: 1.5em;
        font-size: 2em !important;
        color: #000000;
      }
    }
  }
  .ant-modal-close {
    display: none;
  }

  @media (max-width: 768px) {
    & {
      .ant-modal-content {
        max-width: 350px;
        margin: auto;
        .ant-modal-body {
          h1 {
            font-size: 1.6em !important;
          }
        }
      }
    }
  }
`;

export const ModalContent = styled("div")`
  margin: 0em auto 1em;
  background-color: #e3dac6;
  text-align: center;
  h1 {
    width: 254px;
    text-transform: capitalize;
    margin: 1em auto;
  }
  a {
    margin: auto;
    width: 200px;
    font-family: "Helvetica-light";
    font-weight: 400;
  }
  @media (max-width: 768px) {
    & {
      font-size: 0.8em;
      padding: 0.1em 0.5em;
    }
  }
`;
