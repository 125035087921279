import React, {useEffect, useState} from "react";
import {DataContainer} from "./index.style";
import {Col, Row} from "antd";
// import Products from "../../assets/data/products.json";
import SelectItemModal from "../../components/modals/SelectItemModal";
import {TopHeading} from "../index.style";
import {
    clearInnerMeal,
    selectGetInnerMeal,
    selectGetSelectedCatagory,
    setSelectedProduct,
} from "../../Redux/productSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Product from "./Product";
import {CATAGORIES} from "../../constants/CATAGORIES";
// test
const CardItems = ({
                       setAllCount,
                       listItems,
                       setListItems,
                       titleName,
                       formatedMenuData,
                       parentId,
                   }) => {
    // console.log(formatedMenuData, " formatedMenuData..", listItems, "listItems");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProduct, setModalProduct] = useState({});
    const [isComboOpen, setIsComboModalOpen] = useState(false);
    const posData = localStorage.getItem("posData") || "{}";
    const parsedPosData = JSON.parse(posData || "{}");
    const {storeCode = ""} = parsedPosData;

    const handleComboCancel = () => {
        setIsComboModalOpen(false);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const {name, editing} = useSelector(selectGetInnerMeal);
    const selectedCatagory = useSelector(selectGetSelectedCatagory);

    const getMealByCatagoryAndMealName = (mealName, catagory) => {
        return formatedMenuData?.find((item) => {
            return item?.category === catagory && item?.itemName === mealName;
        });
    };
    useEffect(() => {
        if (name) {
            const meal = getMealByCatagoryAndMealName(
                name,
                selectedCatagory?.name || ""
            );
            if (meal) {
                dispatch(clearInnerMeal());
                handleOpenDetailCard(meal);
                navigate(`/customize_order/212/${21}`);
            }
        }
    }, [name, formatedMenuData]);

    const handleOpenDetailCard = (item) => {
        // showModal();
        // setModalProduct(item);
        debugger
        dispatch(
            setSelectedProduct({
                ...item,
                ...item?.itemSize[0],
                quantity: 1,
                editing,
            })
        );
    };

    return (
        <>
            <SelectItemModal
                setAllCount={setAllCount}
                listItems={listItems}
                setListItems={setListItems}
                visible={isModalOpen}
                product={modalProduct}
                setVisible={handleCancel}
                parentId={parentId}
                // isMeal={true}
                handleOpenDetailCard={handleOpenDetailCard}
            />

            <TopHeading>{titleName}{<span
                style={{fontWeight: 'normal'}}>{titleName === CATAGORIES.LUNCH && ' ( From 11AM )'}</span>}</TopHeading>
            {/* Exclude those item which math the location with storeCode in admin */}
            <DataContainer>
                <Row gutter={10}>
                    {formatedMenuData?.filter(item => (storeCode ? !new RegExp(storeCode).test(item?.location) : true)).map((item, i) => (
                        <Col span={8} key={i}>
                            <Product
                                item={item}
                                handleOpenDetailCard={handleOpenDetailCard}
                                index={i}
                                parentId={parentId}
                            />
                        </Col>
                    ))}
                </Row>
            </DataContainer>
        </>
    );
};

export default CardItems;
