import productsArr from "../assets/data/olivers_data";
import Products from "../assets/data/products";
import { not } from "../components/modals/helper";

const catagories = () => {
  return Products?.menu_data?.reduce((acc, current) => {
    const { name, image_url } = current;
    if (name) {
      acc.push({ name, image_url });
    }
    return acc;
  }, []);
};

const getCatagories = () => {
  const categoryData = productsArr.reduce((acc, current) => {
    const category = current?.category;
    if (category) {
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(current);
    }
    return acc;
  }, {});
  return categoryData;
};

export const renderSizes = (product) => {
  const { imageSrc, size, sizeSelected = null } = product;
  let defaultSize = null;
  let containsSizes = null;

  const sizes = product?.itemSize?.map((item) => {

    if (sizeSelected && item?.size === size) {

      defaultSize = { ...item, imageSrc, size:(item?.size || 0) };
    } else if (not(defaultSize) && item?.default === "true") {

      defaultSize = { ...item, imageSrc, size: (item?.size || 0) };
    }
    if (
      item?.size === "Small" ||
      item?.size === "Medium" ||
      item?.size === "Large"
    ) {
      containsSizes = true;
    }
    return { ...item, imageSrc, size: item?.size || 0 };
  });

  if (sizes?.length) {
    defaultSize = defaultSize || sizes[0];
  }

  return { sizes, defaultSize, containsSizes };
};
