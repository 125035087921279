// // import io from "socket.io-client";
// //
// // const socket = io(process.env.REACT_APP_SOCKET_URL); // Replace with your server URL
// //
// // export default socket;
//
// import {v4 as uuidv4} from 'uuid';
//
//
// import {io} from "socket.io-client";
//
// let myCustomID = localStorage.getItem("mySocketId");
//
// if (!myCustomID) {
//     myCustomID = uuidv4();
//     localStorage.setItem("mySocketId", myCustomID);
// }
//
// const socket = io(process.env.REACT_APP_SOCKET_URL, {query: {id: myCustomID}});
//
// export default socket;


import { v4 as uuidv4 } from 'uuid';
import { io } from "socket.io-client";

// Generate a new UUID every time a connection is established
const myCustomID = uuidv4();

// Connects to the Socket.io server, setting a custom query parameter `id` with the custom ID value
const socket = io(process.env.REACT_APP_SOCKET_URL, { query: { id: myCustomID } });
// const socket = io('http://localhost:8080', { query: { id: myCustomID } });
export default socket;