import MemberBarcode from "components/modals/memberBarcode";
import {useZxing} from "react-zxing";
import beepSound from "assets/barcode-scanner/beep-08b.mp3";

export const BarcodeScanner = ({isScaning, setIsScaning, setScannedCode, popup = true}) => {
    const handleCancelMember = () => {
        setIsScaning(false);
    };

    const beep = document.getElementById("beep");

    const {ref} = useZxing({
        onDecodeResult(result) {
            playBeep();
            setTimeout(() => {
                setScannedCode(result.getText());
                setIsScaning(false);
                beep.pause();
            }, 500);
        },
    });

    const playBeep = () => {
        beep.play();
    };


    return (
        <>
            {isScaning && <video ref={ref} style={{display: "none"}}/>}
            <audio id="beep" src={beepSound}></audio>

            {/* Barcode scanner Modal */}

            {popup &&
                <MemberBarcode visible={isScaning} setScannedCode={setScannedCode} setVisible={handleCancelMember}/>
            }
        </>
    );
};
